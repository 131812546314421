import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

export default function Testimonial(){
    
     return (

      <section className="main-testi">
        <div className="container">
          <div className="heading">
            <h2>Our Client </h2>
            <h3>Testimonials</h3>
            <p>We value your project needs and time limits! That is why we always assign your website project to our in-house team of professionals with rich experience in working with the similar projects. You can always expect seamless cooperation and timely releases from us.</p>
          </div>
          <div className="testi-wrap">
            <div className="row justify-content-between align-items-center">
              <div className="col-lg-5 col-md-12">
                <div className="testi-text">
                  <h2>TESTIMONIALS</h2>
                  <h3>What our valuable <br />clients say</h3>
                  <p>We have proudly served an awesome client base by delivering comprehensive design solutions. Our clients' feedback and appreciation will explain it all.</p>
                  <button className="btn btn-round btn-white btn-tup chatt"><strong>CHAT NOW </strong> TO AVAIL THIS OFFER </button>
                </div>
              </div>
              <div className="col-lg-6 col-md-12 p-lg-0">
                <OwlCarousel className="testi-slider owl-carousel owl-theme" items={2} loop margin={10} nav={false} dots={false}>
                  <div className="item">
                    <div className="testi-box-wrap">
                      <p>We are grateful for your competence and help through the chat line. I highly recommend you for all design solution, website needs, digital marketing and animation needs.</p>
                      <h5><span>SARA KERTZMANN</span>Department Manager</h5>
                    </div>
                    <img src="images/testi-icon-1.webp" alt="" className="img-fluid" />
                  </div>
                  <div className="item">
                    <div className="testi-box-wrap">
                      <p>I was so pleased to find a great designer who actually followed my design brief and provide the perfect web design and a great looking Product catalog.  I am so thankful I chose to use work with Design Canvas Pro</p>
                      <h5><span>Mark Shaw</span>Brand owner</h5>
                    </div>
                    <img src="images/testi-icon-2.webp" alt="" className="img-fluid" />
                  </div>
                  <div className="item">
                    <div className="testi-box-wrap">
                      <p>They answer queries almost immediately and are so very helpful and patient. I really have full confidence in having them look after my web site - it has taken a huge weight off my shoulders.</p>
                      <h5><span>Julie Levin</span>CEO</h5>
                    </div>
                    <img src="images/testi-icon-3.webp" alt="" className="img-fluid" />
                  </div>
                  </OwlCarousel>
              </div>
            </div>
          </div>
        </div>
      </section>


      
    );
}