import Header from "../Components/Header";
import PackagesCom from "../Components/Packages";
import Cta from "../Components/Cta";
import Footer from "../Components/Footer";


export default function PackagesMain() {

    return (
        <>
            <Header />
            <section className="main-banner inner-banner" style={{ backgroundImage: 'url(images/package.webp)', height: '560px' }}>
                <div className="container">
                    <div className="row ">
                        <div className="col-lg-6 col-sm-12 pr-0 d-flex justify-content-center">
                            <div className="banner-text">
                                <h4>Cost-friendly Packages For All</h4>
                                <h2><span>Business Owners</span></h2>
                                <p>Design Canvas Pro has kept all its packages at<br />the least prices possible to serve all kinds
                                    of businesses.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-12 banner-img jvi">
                            <img src="images/package1.webp" alt="" className="img-fluid" />
                        </div>
                    </div>
                </div>
            </section>


            <PackagesCom />

            <Cta />

            <section className="inner-process">
                <div className="container">
                    <div className="heading">
                        <h3>Our Flawless <span>Web Design</span> Process </h3>
                        <p>Our process is known to be smooth, transparent and always in the benefit for our customers</p>
                    </div>
                    <div className="inner-process-wrap">
                        <div className="row">
                            <div className="col-lg-4 col-md-12">
                                <div className="inner-process-box" data-aos="fade-right" data-aos-duration={1500} data-aos-delay={400}>
                                    <div className="process-img">
                                        <img src="images/process-1.webp" alt="" className="img-fluid" />
                                    </div>
                                    <h3>Research</h3>
                                    <p>We initiate our work process with solid research, which clears our vision about customers to
                                        need and requirements. We plan how your website will look and what it offers to the
                                        audience.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-12">
                                <div className="inner-process-box" data-aos="fade-right" data-aos-duration={1500} data-aos-delay={600}>
                                    <div className="process-img">
                                        <img src="images/process-2.webp" alt="" className="img-fluid" />
                                    </div>
                                    <h3>Idea</h3>
                                    <p>Before moving further, we prefer to polish initial concepts; we create plenty of rough ideas
                                        and enhance them through practical brainstorming tools.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-12">
                                <div className="inner-process-box" data-aos="fade-right" data-aos-duration={1500} data-aos-delay={800}>
                                    <div className="process-img">
                                        <img src="images/process-3.webp" alt="" className="img-fluid" />
                                    </div>
                                    <h3>Execution</h3>
                                    <p>We execute your idea accordingly and uniquely turn them into a perfect web design for your
                                        brand. We have specialists that upkeep the most about what you think.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />

        </>
    );

}