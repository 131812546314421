

export default function Resources() {
  return (

    <section className="main-resource">
      <div className="container">
        <div className="heading" data-aos="fade-down" data-aos-duration={1500}>
          <h2>How We </h2>
          <h3>Allocate Our Resources </h3>
          <p>We value your project needs and time limits! That is why we always assign your website project to our
            in-house team of professionals with rich experience in working with the similar projects. You can always
            expect seamless cooperation and timely releases from us.</p>
        </div>
        <div className="row no-gutters resource-responsive-slider" data-aos="zoom-out" data-aos-duration={1500}>
          <div className="col-lg-3 col-md-12">
            <div className="resource-box">
              <img src="images/resource-icon-1.webp" alt="" className="img-fluid" />
              <h3>Project <br />Manager</h3>
              <p>Web development's project management is the heart of every project. The PM sets the rhythm for
                all team members.</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-12">
            <div className="resource-box">
              <img src="images/resource-icon-2.webp" alt="" className="img-fluid" />
              <h3>Architect</h3>
              <p>The software architect is the brain. He/she estimates the project and sets a roadmap for
                development. The architect keeps in mind all components and their interconnection within an
                project</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-12">
            <div className="resource-box">
              <img src="images/resource-icon-3.webp" alt="" className="img-fluid" />
              <h3>Designer</h3>
              <p>The designer keeps future users in the back of his/her mind. Knows how to catch their attention
                with a stunning web design and motivates them to spend hours on your web.</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-12">
            <div className="resource-box">
              <img src="images/resource-icon-4.webp" alt="" className="img-fluid" />
              <h3>HTML/CSS <br />Coder</h3>
              <p>The coder brings the designer’s concept to life and determines the final appearance of the web
                application or website.</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-12">
            <div className="resource-box">
              <img src="images/resource-icon-5.webp" alt="" className="img-fluid" />
              <h3>Backend <br />Developer</h3>
              <p>This web developer stays behind the scenes and makes all the magic happen.</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-12">
            <div className="resource-box">
              <img src="images/resource-icon-6.webp" alt="" className="img-fluid" />
              <h3>Frontend <br />Developer</h3>
              <p>The front-end developer adds effects and ensures that all UI elements work properly so that a
                user can see and interact with them.</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-12">
            <div className="resource-box">
              <img src="images/resource-icon-7.webp" alt="" className="img-fluid" />
              <h3>Quality <br />Control Expert</h3>
              <p>The QA expert tracks down crashes and hunts for bugs and other malicious data that may appear in
                the code during web development.</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-12">
            <div className="resource-box">
              <img src="images/resource-icon-8.webp" alt="" className="img-fluid" />
              <h3>Sys Engineer</h3>
              <p>The Sys Engineer provides a comfortable environment for your web during web development and takes
                care of website infrastructure after the project is delivered</p>
            </div>
          </div>
        </div>
      </div>
    </section>

  );
}