
export default function CustomProcess() {
    return (

        <section className="services-scnd-fold">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 side-img-length" style={{ backgroundImage: 'url(images/custom-website-design-serv.png)' }}>
                        <div className="box-wrap">
                            <h2 style={{ color: '#000' }}>Custom Website Development</h2>
                            <p style={{ color: '#000' }}>Whether you are a startup or an enterprise business, our expertise in 360
                                web and web solutions and digital branding will turn your ideas into online success.</p>
                        </div>
                    </div>
                    <div className="col-lg-9">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="wrap">
                                    <div className="lft">
                                        <img src="images/2.png" />
                                    </div>
                                    <div className="rht">
                                        <h4>PHP Development</h4>
                                        <p>Our Team of highly skilled PHP developers enables us to deliver creative and
                                            result-oriented PHP web development services to help your ventures.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="wrap">
                                    <div className="lft">
                                        <img src="images/1.png" />
                                    </div>
                                    <div className="rht">
                                        <h4>HTML</h4>
                                        <p>We deliver responsive and tailored websites and other web development projects using
                                            the HTML5 framework.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="wrap">
                                    <div className="lft">
                                        <img src="images/3.png" />
                                    </div>
                                    <div className="rht">
                                        <h4>CSS</h4>
                                        <p>CSS3 is the latest design language following CSS. It has several new features which
                                            may not seem much to the layman at first.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="wrap">
                                    <div className="lft">
                                        <img src="images/4.png" />
                                    </div>
                                    <div className="rht">
                                        <h4>MySQL</h4>
                                        <p>Design Canvas Pro MySQL experts have access to two millennia of combined experience
                                            that helps them address most problems promptly and efficiently.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    );
}