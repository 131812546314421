import Header from 
'../Components/Header';
import Footer from 
'../Components/Footer';
import Packages from 
'../Components/Packages';
import Cta from 
'../Components/Cta';
// import { useEffect } from 'react';



export default function ContentWritting(){

//   useEffect(() => {

//     window.$(".tablist ul li a").click((function() {
//     var t = window.$(this).attr("data-target");
//     return window.$(".tablist ul li a").removeClass("active"),
//      window.$(this).addClass("active"),
//       window.$("#" + t).fadeIn("slow").siblings(".tabbox").hide(), !1
// }))

// },[]);

	return(
    <> 
    <Header />


        <section className="main-banner inner-banner" style={{backgroundImage: 'url(images/3d-animation.webp)', height: '560px'}}>
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-sm-12 pr-0">
                <div className="banner-text" data-aos="fade-right" data-aos-duration={1500}>
                  <h4>Striking Logo Designs That Shine</h4>
                  <h2><span>Crafting Distinct <br />Visual Identities </span></h2>
                  <p>Our Logo Design Maestros at Design Canvas Pro specialize in creating memorable logos that
                    encapsulate your brand's essence. We transform your vision into a captivating emblem.</p>
                  <img src="images/bark-logo.webp" alt="" className="img-fluid" />
                </div>
              </div>
              <div className="col-lg-6 col-sm-12 banner-img d-flex justify-content-center jvi">
                <img src="images/ICON-38.webp" alt="" className="img-fluid" style={{maxWidth: '58%'}} />
              </div>
            </div>
          </div>
        </section>

        <section className="main-award">
          <div className="container">
            <h3>Let’s Envision and Build together</h3>
            <div className="row justify-content-center">
              <div className="col-lg-2 col-md-2 col-sm-2">
                <div className="award-box">
                  <img src="images/seo-award-1.webp" alt="" className="img-fluid" />
                </div>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-2">
                <div className="award-box">
                  <img src="images/seo-award-2.webp" alt="" className="img-fluid" />
                </div>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-2">
                <div className="award-box">
                  <img src="images/seo-award-3.webp" alt="" className="img-fluid" />
                </div>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-2">
                <div className="award-box">
                  <img src="images/seo-award-4.webp" alt="" className="img-fluid" />
                </div>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-2">
                <div className="award-box">
                  <img src="images/seo-award-5.webp" alt="" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="best-static-wrap">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <div className="best-static-img" data-aos="fade-right" data-aos-duration={1500}>
                  <img src="images/iconi.webp" alt="" className="img-fluid" style={{width: '75%', borderRadius: '50%'}} />
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="best-static-text" data-aos="fade-left" data-aos-duration={1500}>
                  <h3>Premier Content Writing Services <br />in San Diego</h3>
                  <p>
                    With a rich history of excellence, Design Canvas Pro has been a trusted partner for clients,
                    offering content solutions that captivate audiences and ignite brand stories. We have
                    consistently empowered businesses to realize their full potential and elevate their online
                    presence. As a leading Marketing and Design agency headquartered in San Diego, our satisfied
                    client base is a testament to our expertise.</p>
                  <h2>Discover the benefits of selecting us for your content needs:</h2>
                  <ul>
                    <li><i className="fas fa-caret-right" /> Experienced Content Craftsmen</li>
                    <li><i className="fas fa-caret-right" /> Proven Results</li>
                    <li><i className="fas fa-caret-right" /> Thorough Competitor Analysis</li>
                    <li><i className="fas fa-caret-right" /> Compelling Written Impact</li>
                  </ul>
                  <div className="best-static-btn">
                    <button className="btn btn-round btn-white btn-tup chatt">Sign Up Now To <strong>Avail 30%
                        Discount</strong></button>
                    <a href="javascript:;" data-toggle="modal" data-target=".bd-example-modal-lg" className="discount-btn">AVAIL DISCOUNT</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
    

     <Cta/>
     <Packages/>


     <section className="main-resource">
        <div className="container">
          <div className="heading">
            <h2>How We </h2>
            <h3>Allocate Our Resources </h3>
            <p>We value your project needs and time limits! That is why we always assign your website project to our
              in-house team of professionals with rich experience in working with the similar projects. You can always
              expect seamless cooperation and timely releases from us.</p>
          </div>
          <div className="row no-gutters resource-responsive-slider" data-aos="zoom-out" data-aos-duration={1500}>
            <div className="col-lg-3 col-md-12">
              <div className="resource-box">
                <h3> Research and Discovery</h3>
                <p>In-depth research to understand your topic, audience, and competition, providing insights for
                  content creation.</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-12">
              <div className="resource-box">
                <h3>Idea Generation and Development</h3>
                <p>Brainstorming and refining content ideas to create engaging narratives that resonate with your
                  audience.</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-12">
              <div className="resource-box">
                <h3>Writing and Editing</h3>
                <p>Skilled writing with a focus on grammar, structure, and clarity to align with your brand's voice.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-12">
              <div className="resource-box">
                <h3> Proofreading and Delivery</h3>
                <p>Thorough proofreading to ensure error-free, high-quality content before delivery or publication.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
     

    <Footer />
    </>

		)
}