import React from 'react';
// import ReactDOM from 'react-dom';
// import $ from 'jquery';
// import { useEffect } from 'react';
export default function PortfolioCom() {
  // useEffect(() => {
  //   window.$(".dataTarget").on("click", (function () {
  //     window.$(this).siblings().removeClass("current");
  //     window.$(this).addClass("current");
  //     var t = window.$(this).data("targetit");
  //     window.$("." + t).siblings('[class^="tabs"]').removeClass("current");
  //     window.$("." + t).addClass("current");
  //     window.$(".slick-slider").slick("setPosition", 0)
  //   }));
  // }, []);
  return (
    <section
      className="main-portfolio aos-init aos-animate"
      data-aos="fade-down"
      data-aos-duration={1500}>
      <div className="heading">
        <h2>Lead Focused Web Designs</h2>
        <h3>
          Along With <span>Targeted Marketing Techniques</span>{" "}
        </h3>
        <p>
          We build brands by impecular design sense combined with words that pull
          the interest of your target audience.
        </p>
      </div>
      <div className="container">
        <ul
          className="nav nav-tabs cust-nav-tabs justify-content-center border-0"
          id="myTab"
          role="tablist"
        >
          <li className="nav-item">
            <a
              className="nav-link active cust-nav-link"
              id="b2b-tab"
              data-toggle="tab"
              href="#b2b"
              role="tab"
              aria-controls="b2b"
              aria-selected="true"
            >
              WEBSITE DESIGN
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link cust-nav-link"
              id="b2b-tab123"
              data-toggle="tab"
              href="#b2b123"
              role="tab"
              aria-controls="b2b123"
              aria-selected="true"
            >
              LOGO DESIGN
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link cust-nav-link"
              id="ecommerce-tab"
              data-toggle="tab"
              href="#ecommerce"
              role="tab"
              aria-controls="ecommerce"
              aria-selected="false"
            >
              DIGITAL MARKETING
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link cust-nav-link"
              id="animation-tab"
              data-toggle="tab"
              href="#animation"
              role="tab"
              aria-controls="animation"
              aria-selected="false"
            >
              VIDEO ANIMATIONS
            </a>
          </li>
        </ul>
      </div>
      <div className="client-reviews-wrap">
        <div className="tab-content" id="myTabContent">
          {/* here is a tab 1 */}
          <div
            className="tab-pane fade show active"
            id="b2b"
            role="tabpanel"
            aria-labelledby="b2b-tab"
          >
            <div className="row portfolio-slider">
              <div className="col-lg-2 col-md-12 mb-3 pl-lg-0">
                <div className="portfolio-box">
                  <img
                    className="lazy img-fluid loaded"
                    src="images/1.webp"
                    alt="*"
                    data-was-processed="true"
                  />
                  <div className="hovereffects-det">
                    <div className="btnstyles-nn">
                      <a
                        data-fancybox="allportfolio"
                        href="images/1-lg.webp"
                        title=""
                      >
                        <i className="fa fa-search-plus" aria-hidden="true" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-12 mb-3 pl-lg-0">
                <div className="portfolio-box">
                  <img
                    className="lazy img-fluid loaded"
                    src="images/2.webp"
                    alt="*"
                    data-was-processed="true"
                  />
                  <div className="hovereffects-det">
                    <div className="btnstyles-nn">
                      <a
                        data-fancybox="allportfolio"
                        href="images/2-lg.webp"
                        title=""
                      >
                        <i className="fa fa-search-plus" aria-hidden="true" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-12  mb-3 pl-lg-0">
                <div className="portfolio-box">
                  <img
                    className="lazy img-fluid loaded"
                    src="images/3.webp"
                    alt="*"
                    data-was-processed="true"
                  />
                  <div className="hovereffects-det">
                    <div className="btnstyles-nn">
                      <a
                        data-fancybox="allportfolio"
                        href="images/3-lg.webp"
                        title=""
                      >
                        <i className="fa fa-search-plus" aria-hidden="true" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-12  mb-3 pl-lg-0">
                <div className="portfolio-box">
                  <img
                    className="lazy img-fluid loaded"
                    src="images/4.webp"

                    alt="*"
                    data-was-processed="true"
                  />
                  <div className="hovereffects-det">
                    <div className="btnstyles-nn">
                      <a
                        data-fancybox="allportfolio"
                        href="images/4-lg.webp"
                        title=""
                      >
                        <i className="fa fa-search-plus" aria-hidden="true" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-12 mb-3 pl-lg-0">
                <div className="portfolio-box">
                  <img
                    className="lazy img-fluid loaded"
                    src="images/5.webp"
                    alt="*"
                    data-was-processed="true"
                  />
                  <div className="hovereffects-det">
                    <div className="btnstyles-nn">
                      <a
                        data-fancybox="allportfolio"
                        href="images/5-lg.webp"
                        title=""
                      >
                        <i className="fa fa-search-plus" aria-hidden="true" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-12 mb-3 pl-lg-0">
                <div className="portfolio-box">
                  <img
                    className="lazy img-fluid loaded"
                    src="images/6.webp"
                    alt="*"
                    data-was-processed="true"
                  />
                  <div className="hovereffects-det">
                    <div className="btnstyles-nn">
                      <a
                        data-fancybox="allportfolio"
                        href="images/6-lg.webp"
                        title=""
                      >
                        <i className="fa fa-search-plus" aria-hidden="true" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-12  mb-3 pl-lg-0">
                <div className="portfolio-box">
                  <img
                    className="lazy img-fluid loaded"
                    src="images/7.webp"
                    alt="*"
                    data-was-processed="true"
                  />
                  <div className="hovereffects-det">
                    <div className="btnstyles-nn">
                      <a
                        data-fancybox="allportfolio"
                        href="images/7-lg.webp"
                        title=""
                      >
                        <i className="fa fa-search-plus" aria-hidden="true" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-12  mb-3 pl-lg-0">
                <div className="portfolio-box">
                  <img
                    className="lazy img-fluid loaded"
                    src="images/8.webp"
                    alt="*"
                    data-was-processed="true"
                  />
                  <div className="hovereffects-det">
                    <div className="btnstyles-nn">
                      <a
                        data-fancybox="allportfolio"
                        href="images/8-lg.webp"
                        title=""
                      >
                        <i className="fa fa-search-plus" aria-hidden="true" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-12  mb-3 pl-lg-0">
                <div className="portfolio-box">
                  <img
                    className="lazy img-fluid loaded"
                    src="images/9.webp"
                    alt="*"
                    data-was-processed="true"
                  />
                  <div className="hovereffects-det">
                    <div className="btnstyles-nn">
                      <a
                        data-fancybox="allportfolio"
                        href="images/9-lg.webp"
                        title=""
                      >
                        <i className="fa fa-search-plus" aria-hidden="true" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-12  mb-3 pl-lg-0">
                <div className="portfolio-box">
                  <img
                    className="lazy img-fluid loaded"
                    src="images/10.webp"
                    alt="*"
                    data-was-processed="true"
                  />
                  <div className="hovereffects-det">
                    <div className="btnstyles-nn">
                      <a
                        data-fancybox="allportfolio"
                        href="images/10-lg.webp"
                        title=""
                      >
                        <i className="fa fa-search-plus" aria-hidden="true" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-12 mb-3 pl-lg-0 ">
                <div className="portfolio-box">
                  <img
                    className="lazy img-fluid loaded"
                    src="images/11.webp"
                    alt="*"
           
                    data-was-processed="true"
                  />
                  <div className="hovereffects-det">
                    <div className="btnstyles-nn">
                      <a
                        data-fancybox="allportfolio"
                        href="images/11-lg.webp"
                        title=""
                      >
                        <i className="fa fa-search-plus" aria-hidden="true" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-12 mb-3 pl-lg-0">
                <div className="portfolio-box">
                  <img
                    className="lazy img-fluid loaded"
                    src="images/12.webp"
                    alt="*"
      
                    data-was-processed="true"
                  />
                  <div className="hovereffects-det">
                    <div className="btnstyles-nn">
                      <a
                        data-fancybox="allportfolio"
                        href="images/12-lg.webp"
                        title=""
                      >
                        <i className="fa fa-search-plus" aria-hidden="true" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* here is a tab 2 */}
          <div
            className="tab-pane fade"
            id="ecommerce"
            role="tabpanel"
            aria-labelledby="ecommerce-tab"
          >
            <div className="row portfolio-slider justify-content-center">
              <div className="col-lg-2 col-md-12 mb-3 pl-lg-0">
                <div className="portfolio-box">
                  <img
                    className="lazy img-fluid"
                    src="images/port/01.webp"
                    alt="*"
                  />
                  <div className="hovereffects-det">
                    <div className="btnstyles-nn">
                      <a
                        data-fancybox="allportfolio"
                        href="images/01-lg.webp"
                        title=""
                      >
                        <i className="fa fa-search-plus" aria-hidden="true" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-12 mb-3 pl-lg-0">
                <div className="portfolio-box">
                  <img
                    className="lazy img-fluid"
                    src="images/port/02.webp"
                    alt="*"
                  />
                  <div className="hovereffects-det">
                    <div className="btnstyles-nn">
                      <a
                        data-fancybox="allportfolio"
                        href="images/02-lg.webp"
                        title=""
                      >
                        <i className="fa fa-search-plus" aria-hidden="true" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-12  mb-3 pl-lg-0">
                <div className="portfolio-box">
                  <img
                    className="lazy img-fluid"
                    src="images/port/03.webp"
                    alt="*"
                  />
                  <div className="hovereffects-det">
                    <div className="btnstyles-nn">
                      <a
                        data-fancybox="allportfolio"
                        href="images/03-lg.webp"
                        title=""
                      >
                        <i className="fa fa-search-plus" aria-hidden="true" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-12  mb-3 pl-lg-0">
                <div className="portfolio-box">
                  <img
                    className="lazy img-fluid"
                    src="images/port/04.webp"
                    alt="*"
                  />
                  <div className="hovereffects-det">
                    <div className="btnstyles-nn">
                      <a
                        data-fancybox="allportfolio"
                        href="images/04-lg.webp"
                        title=""
                      >
                        <i className="fa fa-search-plus" aria-hidden="true" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-12 mb-3 pl-lg-0">
                <div className="portfolio-box">
                  <img
                    className="lazy img-fluid"
                    src="images/port/05.webp"
                    alt="*"
                  />
                  <div className="hovereffects-det">
                    <div className="btnstyles-nn">
                      <a
                        data-fancybox="allportfolio"
                        href="images/05-lg.webp"
                        title=""
                      >
                        <i className="fa fa-search-plus" aria-hidden="true" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-12 mb-3 pl-lg-0">
                <div className="portfolio-box">
                  <img
                    className="lazy img-fluid"
                    src="images/port/06.webp"
                    alt="*"
                  />
                  <div className="hovereffects-det">
                    <div className="btnstyles-nn">
                      <a
                        data-fancybox="allportfolio"
                        href="images/06-lg.webp"
                        title=""
                      >
                        <i className="fa fa-search-plus" aria-hidden="true" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-12  mb-3 pl-lg-0">
                <div className="portfolio-box">
                  <img
                    className="lazy img-fluid"
                    src="images/port/07.webp"
                    alt="*"
                  />
                  <div className="hovereffects-det">
                    <div className="btnstyles-nn">
                      <a
                        data-fancybox="allportfolio"
                        href="images/07-lg.webp"
                        title=""
                      >
                        <i className="fa fa-search-plus" aria-hidden="true" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-12  mb-3 pl-lg-0">
                <div className="portfolio-box">
                  <img
                    className="lazy img-fluid"
                    src="images/port/08.webp"
                    alt="*"
                  />
                  <div className="hovereffects-det">
                    <div className="btnstyles-nn">
                      <a
                        data-fancybox="allportfolio"
                        href="images/08-lg.webp"
                        title=""
                      >
                        <i className="fa fa-search-plus" aria-hidden="true" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* here is a tab 3 */}
          <div
            className="tab-pane fade"
            id="animation"
            role="tabpanel"
            aria-labelledby="animation-tab"
          >
            <div className="row portfolio-slider justify-content-center">
              <div className="col-lg-2 col-md-12 mb-3 pl-lg-0">
                <div className="portfolio-box">
                  <img
                    className="lazy img-fluid"
                    src="images/01.webp"
                    alt="*"
                  />
                  <div className="hovereffects-det">
                    <div className="btnstyles-nn">
                      <a
                        data-fancybox="allportfolio"
                        href="images/01.webp"
                        title=""
                      >
                        <i className="fas fa-play-circle" aria-hidden="true" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-12 mb-3 pl-lg-0">
                <div className="portfolio-box">
                  <img
                    className="lazy img-fluid"
                    src="images/02.webp"
                    alt="*"
                  />
                  <div className="hovereffects-det">
                    <div className="btnstyles-nn">
                      <a
                        data-fancybox="allportfolio"
                        href="images/02.webp"
                        title=""
                      >
                        <i className="fas fa-play-circle" aria-hidden="true" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-12  mb-3 pl-lg-0">
                <div className="portfolio-box">
                  <img
                    className="lazy img-fluid"
                    src="images/03.webp"
                    alt="*"
                  />
                  <div className="hovereffects-det">
                    <div className="btnstyles-nn">
                      <a
                        data-fancybox="allportfolio"
                        href="images/03.webp"
                        title=""
                      >
                        <i className="fas fa-play-circle" aria-hidden="true" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-12  mb-3 pl-lg-0">
                <div className="portfolio-box">
                  <img
                    className="lazy img-fluid"
                    src="images/04.webp"
                    alt="*"
                  />
                  <div className="hovereffects-det">
                    <div className="btnstyles-nn">
                      <a
                        data-fancybox="allportfolio"
                        href="images/04.webp"
                        title=""
                      >
                        <i className="fas fa-play-circle" aria-hidden="true" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-12 mb-3 pl-lg-0">
                <div className="portfolio-box">
                  <img
                    className="lazy img-fluid"
                    src="images/05.webp"
                    alt="*"
                  />
                  <div className="hovereffects-det">
                    <div className="btnstyles-nn">
                      <a
                        data-fancybox="allportfolio"
                        href="images/05.webp"
                        title=""
                      >
                        <i className="fas fa-play-circle" aria-hidden="true" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-12 mb-3 pl-lg-0">
                <div className="portfolio-box">
                  <img
                    className="lazy img-fluid"
                    src="images/06.webp"
                    alt="*"
                  />
                  <div className="hovereffects-det">
                    <div className="btnstyles-nn">
                      <a
                        data-fancybox="allportfolio"
                        href="images/06.webp"
                        title=""
                      >
                        <i className="fas fa-play-circle" aria-hidden="true" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-12  mb-3 pl-lg-0">
                <div className="portfolio-box">
                  <img
                    className="lazy img-fluid"
                    src="images/07.webp"
                    alt="*"
                  />
                  <div className="hovereffects-det">
                    <div className="btnstyles-nn">
                      <a
                        data-fancybox="allportfolio"
                        href="images/07.webp"
                        title=""
                      >
                        <i className="fas fa-play-circle" aria-hidden="true" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-12  mb-3 pl-lg-0">
                <div className="portfolio-box">
                  <img
                    className="lazy img-fluid"
                    src="images/08.webp"
                    alt="*"
                  />
                  <div className="hovereffects-det">
                    <div className="btnstyles-nn">
                      <a
                        data-fancybox="allportfolio"
                        href="images/08.webp"
                        title=""
                      >
                        <i className="fas fa-play-circle" aria-hidden="true" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-12  mb-3 pl-lg-0">
                <div className="portfolio-box">
                  <img
                    className="lazy img-fluid"
                    src="images/09.webp"
                    alt="*"
                  />
                  <div className="hovereffects-det">
                    <div className="btnstyles-nn">
                      <a
                        data-fancybox="allportfolio"
                        href="images/09.webp"
                        title=""
                      >
                        <i className="fas fa-play-circle" aria-hidden="true" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-12  mb-3 pl-lg-0">
                <div className="portfolio-box">
                  <img
                    className="lazy img-fluid"
                    src="images/10.webp"
                    alt="*"
                  />
                  <div className="hovereffects-det">
                    <div className="btnstyles-nn">
                      <a
                        data-fancybox="allportfolio"
                        href="images/10.webp"
                        title=""
                      >
                        <i className="fas fa-play-circle" aria-hidden="true" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* here is a tab 1 */}
          <div
            className="tab-pane fade"
            id="b2b123"
            role="tabpanel"
            aria-labelledby="b2b-tab123"
          >
            <div className="row portfolio-slider">
              <div className="col-lg-2 col-md-12 mb-3 pl-lg-0">
                <div className="portfolio-box">
                  <img
                    className="lazy img-fluid"
                    src="images/logo/1.webp"
                    alt="*"
                  />
                  <div className="hovereffects-det">
                    <div className="btnstyles-nn">
                      <a
                        data-fancybox="allportfolio"
                        href="images/logo/1.webp"
                        title=""
                      >
                        <i className="fa fa-search-plus" aria-hidden="true" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-12 mb-3 pl-lg-0">
                <div className="portfolio-box">
                  <img
                    className="lazy img-fluid"
                    src="images/logo/2.webp"
                    alt="*"
                  />
                  <div className="hovereffects-det">
                    <div className="btnstyles-nn">
                      <a
                        data-fancybox="allportfolio"
                        href="images/logo/2.webp"
                        title=""
                      >
                        <i className="fa fa-search-plus" aria-hidden="true" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-12  mb-3 pl-lg-0">
                <div className="portfolio-box">
                  <img
                    className="lazy img-fluid"
                    src="images/logo/3.webp"
                    alt="*"
                  />
                  <div className="hovereffects-det">
                    <div className="btnstyles-nn">
                      <a
                        data-fancybox="allportfolio"
                        href="images/logo/3.webp"
                        title=""
                      >
                        <i className="fa fa-search-plus" aria-hidden="true" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-12  mb-3 pl-lg-0">
                <div className="portfolio-box">
                  <img
                    className="lazy img-fluid"
                    src="images/logo/4.webp"
                    alt="*"
                  />
                  <div className="hovereffects-det">
                    <div className="btnstyles-nn">
                      <a
                        data-fancybox="allportfolio"
                        href="images/logo/4.webp"
                        title=""
                      >
                        <i className="fa fa-search-plus" aria-hidden="true" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-12 mb-3 pl-lg-0">
                <div className="portfolio-box">
                  <img
                    className="lazy img-fluid"
                    src="images/logo/5.webp"
                    alt="*"
                  />
                  <div className="hovereffects-det">
                    <div className="btnstyles-nn">
                      <a
                        data-fancybox="allportfolio"
                        href="images/logo/5.webp"
                        title=""
                      >
                        <i className="fa fa-search-plus" aria-hidden="true" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-12 mb-3 pl-lg-0">
                <div className="portfolio-box">
                  <img
                    className="lazy img-fluid"
                    src="images/logo/6.webp"
                    alt="*"
                  />
                  <div className="hovereffects-det">
                    <div className="btnstyles-nn">
                      <a
                        data-fancybox="allportfolio"
                        href="images/logo/6.webp"
                        title=""
                      >
                        <i className="fa fa-search-plus" aria-hidden="true" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-12  mb-3 pl-lg-0">
                <div className="portfolio-box">
                  <img
                    className="lazy img-fluid"
                    src="images/logo/7.webp"
                    alt="*"
                  />
                  <div className="hovereffects-det">
                    <div className="btnstyles-nn">
                      <a
                        data-fancybox="allportfolio"
                        href="images/logo/7.webp"
                        title=""
                      >
                        <i className="fa fa-search-plus" aria-hidden="true" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-12  mb-3 pl-lg-0">
                <div className="portfolio-box">
                  <img
                    className="lazy img-fluid"
                    src="images/logo/8.webp"
                    alt="*"
                  />
                  <div className="hovereffects-det">
                    <div className="btnstyles-nn">
                      <a
                        data-fancybox="allportfolio"
                        href="images/logo/8.webp"
                        title=""
                      >
                        <i className="fa fa-search-plus" aria-hidden="true" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-12  mb-3 pl-lg-0">
                <div className="portfolio-box">
                  <img
                    className="lazy img-fluid"
                    src="images/logo/11.webp"
                    alt="*"
                  />
                  <div className="hovereffects-det">
                    <div className="btnstyles-nn">
                      <a
                        data-fancybox="allportfolio"
                        href="images/logo/11.webp"
                        title=""
                      >
                        <i className="fa fa-search-plus" aria-hidden="true" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-12  mb-3 pl-lg-0">
                <div className="portfolio-box">
                  <img
                    className="lazy img-fluid"
                    src="images/logo/12.webp"
                    alt="*"
                  />
                  <div className="hovereffects-det">
                    <div className="btnstyles-nn">
                      <a
                        data-fancybox="allportfolio"
                        href="images/logo/12.webp"
                        title=""
                      >
                        <i className="fa fa-search-plus" aria-hidden="true" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-12 mb-3 pl-lg-0 ">
                <div className="portfolio-box">
                  <img
                    className="lazy img-fluid"
                    src="images/logo/13.webp"
                    alt="*"
                  />
                  <div className="hovereffects-det">
                    <div className="btnstyles-nn">
                      <a
                        data-fancybox="allportfolio"
                        href="images/logo/13.webp"
                        title=""
                      >
                        <i className="fa fa-search-plus" aria-hidden="true" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-12 mb-3 pl-lg-0">
                <div className="portfolio-box">
                  <img
                    className="lazy img-fluid"
                    src="images/logo/14.webp"
                    alt="*"
                  />
                  <div className="hovereffects-det">
                    <div className="btnstyles-nn">
                      <a
                        data-fancybox="allportfolio"
                        href="images/logo/14.webp"
                        title=""
                      >
                        <i className="fa fa-search-plus" aria-hidden="true" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}