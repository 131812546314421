export default function Packages() {

    return (
      <>
      
      <section className="main-package" data-aos="fade-down" data-aos-duration={1500}>
        <div className="container">
          <div className="heading">
            <h2>Our Affordable</h2>
            <h3>Web Development  <span>Packages</span> </h3>
            <p>Customized web development packages to make website creation and development easier for you</p>
          </div>
          <div className="packages-tab">
            <ul className="nav nav-tabs justify-content-center" role="tablist">
              <li className="nav-item">
                <a className="nav-link active" id="tab_793" data-toggle="tab" href="#tab_content_793" role="tab" aria-controls="Website Design" aria-selected="false">Website Design</a>
              </li>
              <li className="nav-item">
                <a className="nav-link " id="tab_794" data-toggle="tab" href="#tab_content_794" role="tab" aria-controls="Video Animation" aria-selected="false">Video Animation</a>
              </li>
              <li className="nav-item">
                <a className="nav-link " id="tab_795" data-toggle="tab" href="#tab_content_795" role="tab" aria-controls="SEO" aria-selected="false">SEO</a>
              </li>
              <li className="nav-item">
                <a className="nav-link " id="tab_796" data-toggle="tab" href="#tab_content_796" role="tab" aria-controls="E-Commerce" aria-selected="false">E-Commerce</a>
              </li>
              <li className="nav-item">
                <a className="nav-link " id="tab_836" data-toggle="tab" href="#tab_content_836" role="tab" aria-controls="Combo Package" aria-selected="false">Combo Package</a>
              </li>
              <li className="nav-item">
                <a className="nav-link " id="tab_1022" data-toggle="tab" href="#tab_content_1022" role="tab" aria-controls="Content Writing" aria-selected="false">Content Writing</a>
              </li>
              <li className="nav-item">
                <a className="nav-link " id="tab_1656" data-toggle="tab" href="#tab_content_1656" role="tab" aria-controls="SMM" aria-selected="false">SMM</a>
              </li>
            </ul>
          </div>
          {/* Tab panes */}
          <div className="tab-content container">
            <div className="tab-pane fade in show active" id="tab_content_793" role="tabpanel" aria-labelledby="profile-tab">
              <div className="package-carousel">
                <div className="item">
                  <div className="package_box " data-package-box>
                    <div className="cnrflash">
                      <div className="cnrflash-inner">
                        <img src="images/best-seller.png" alt="*" className="img-fluid" />
                      </div>
                    </div> <div className="cnrflash">
                      <div className="cnrflash-inner">
                        <img src="images/best-seller.png" alt="*" className="img-fluid" />
                      </div>
                    </div>
                    <div className="pkg_top">
                      <div className="pkg_title text-center">
                        <div className="productSku" style={{display: 'none'}}>WEB_DESIGN_STARTUP</div>
                        <h3>Startup Website Package</h3>
                      </div>
                      <div className="pkg_pricing_area d-flex justify-content-between align-items-center">
                        <div className="sale_price">
                          <h3>$299.99</h3>
                        </div>
                        <div className="cut_price">
                          <h4><span>$599.98</span></h4>
                        </div>
                      </div>
                      <div className="short_desc text-center">
                        <p>Suitable for potential super-startups and brand revamps for companies</p>
                      </div>
                    </div>
                    <div className="pkg_details">
                      <div data-package-scroll>
                        <ul>
                          <li>5 Stock Photos</li>
                          <li>5 Page Website</li>
                          <li>3 Banner Design</li>
                          <li>1 jQuery Slider Banner</li>
                          <li>FREE Google Friendly Sitemap</li> 
                          <li>Complete W3C Certified HTML</li>
                          <li>48 to 72 hours TAT</li>
                          <li>Facebook Page Design</li>
                          <li>Twitter Page Design</li>
                          <li>YouTube Page Design</li>
                          <li>100% Satisfaction Guarantee</li>
                          <li>100% Unique Design Guarantee</li>
                          <li>100% Money Back Guarantee *</li>
                          <li>Mobile Responsive will be Additional $99*</li>
                          <li>CMS will be Additional $149*</li>
                        </ul>         </div>
                    </div>
                    <a href="./../view-detail.php?package=Startup Website Package" className="view_detail_btn">View Details</a>
                    <div className="pkg_bottom d-flex justify-content-between align-items-center">
                      <a href="javascript:void(0)" className="order_btn order-package btn-10" data-sku="WEB_DESIGN_STARTUP" data-promotion-id={0} data-price="299.99" data-price-text="$299.99" data-title="Startup Website Package" data-package-id={2963}>Buy Now</a>
                      <a href="javascript:;" className="chat pkg_chat_btn chatt"><span>Click here to</span><br />Live Chat</a>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="package_box " data-package-box>
                    <div className="cnrflash">
                      <div className="cnrflash-inner">
                        <img src="images/best-seller.png" alt="*" className="img-fluid" />
                      </div>
                    </div> <div className="cnrflash">
                      <div className="cnrflash-inner">
                        <img src="images/best-seller.png" alt="*" className="img-fluid" />
                      </div>
                    </div>
                    <div className="pkg_top">
                      <div className="pkg_title text-center">
                        <div className="productSku" style={{display: 'none'}}>WEB_DESIGN_PROFESSIONAL</div>
                        <h3>Professional Website  Package</h3>
                      </div>
                      <div className="pkg_pricing_area d-flex justify-content-between align-items-center">
                        <div className="sale_price">
                          <h3>$599.99</h3>
                        </div>
                        <div className="cut_price">
                          <h4><span>$1199.98</span></h4>
                        </div>
                      </div>
                      <div className="short_desc text-center">
                        <p>Suitable for potential super-startups and brand revamps for companies.</p>
                      </div>
                    </div>
                    <div className="pkg_details">
                      <div data-package-scroll>
                        <ul>
                          <li>10 Unique Pages Website</li>
                          <li>CMS / Admin Panel Support</li>
                          <li>8 Stock images</li>
                          <li>5 Banner Designs</li>
                          <li>1 jQuery Slider Banner</li>
                          <li>FREE Google Friendly Sitemap</li>
                          <li>Complete W3C Certified HTML</li>
                          <li>48 to 72 hours TAT</li>
                          <li>Facebook Page Design</li>
                          <li>Twitter Page Design</li>
                          <li>YouTube Page Design</li>
                          <li>Complete Deployment</li>
                          <li>100% Satisfaction Guarantee</li>
                          <li>100% Unique Design Guarantee</li>
                          <li>100% Money Back Guarantee *</li>
                          <li>Mobile Responsive will be Additional $99*</li>
                        </ul>         </div>
                    </div>
                    <a href="./../view-detail.php?package=Professional Website  Package" className="view_detail_btn">View Details</a>
                    <div className="pkg_bottom d-flex justify-content-between align-items-center">
                      <a href="javascript:void(0)" className="order_btn order-package btn-10" data-sku="WEB_DESIGN_PROFESSIONAL" data-promotion-id={0} data-price="599.99" data-price-text="$599.99" data-title="Professional Website  Package" data-package-id={2964}>Buy Now</a>
                      <a href="javascript:;" className="chat pkg_chat_btn chatt"><span>Click here to</span><br />Live Chat</a>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="package_box  active " data-package-box>
                    <div className="cnrflash">
                      <div className="cnrflash-inner">
                        <img src="images/best-seller.png" alt="*" className="img-fluid" />
                      </div>
                    </div> <div className="cnrflash">
                      <div className="cnrflash-inner">
                        <img src="images/best-seller.png" alt="*" className="img-fluid" />
                      </div>
                    </div>
                    <div className="pkg_top">
                      <div className="pkg_title text-center">
                        <div className="productSku" style={{display: 'none'}}>WEBSITE_DESIGN_ELITE</div>
                        <h3>Elite Website Package</h3>
                      </div>
                      <div className="pkg_pricing_area d-flex justify-content-between align-items-center">
                        <div className="sale_price">
                          <h3>$999.99</h3>
                        </div>
                        <div className="cut_price">
                          <h4><span>$1999.98</span></h4>
                        </div>
                      </div>
                      <div className="short_desc text-center">
                        <p>Suitable for potential super-startups and brand revamps for companies.</p>
                      </div>
                    </div>
                    <div className="pkg_details">
                      <div data-package-scroll>
                        <ul>
                          <li>Upto 15 Unique Pages Website</li>
                          <li>Conceptual and Dynamic Website</li>
                          <li>Mobile Responsive</li>
                          <li>Online Reservation/Appointment Tool (Optional)</li>
                          <li>Online Payment Integration (Optional)</li>
                          <li>Custom Forms</li>
                          <li>Lead Capturing Forms (Optional)</li>
                          <li>Striking Hover Effects</li>
                          <li>Newsletter Subscription (Optional)</li>
                          <li>Newsfeed Integration</li>
                          <li>Social Media Integration</li>
                          <li>Search Engine Submission</li>
                          <li>5 Stock Photos</li>
                          <li>3 Unique Banner Design</li>
                          <li>1 jQuery Slider Banner</li>
                          <li> Complete W3C Certified HTML</li>
                          <li>48 to 72 hours TAT</li>
                          <li>Facebook Page Design</li>
                          <li>Twitter Page Design</li>
                          <li>YouTube Page Design</li>
                          <li>Complete Deployment</li>
                          <li>100% Satisfaction Guarantee</li>
                          <li>100% Unique Design Guarantee</li>
                          <li>100% Money Back Guarantee *</li>
                        </ul>         </div>
                    </div>
                    <a href="./../view-detail.php?package=Elite Website Package" className="view_detail_btn">View Details</a>
                    <div className="pkg_bottom d-flex justify-content-between align-items-center">
                      <a href="javascript:void(0)" className="order_btn order-package btn-10" data-sku="WEBSITE_DESIGN_ELITE" data-promotion-id={0} data-price="999.99" data-price-text="$999.99" data-title="Elite Website Package" data-package-id={2965}>Buy Now</a>
                      <a href="javascript:;" className="chat pkg_chat_btn chatt"><span>Click here to</span><br />Live Chat</a>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="package_box " data-package-box>
                    <div className="cnrflash">
                      <div className="cnrflash-inner">
                        <img src="images/best-seller.png" alt="*" className="img-fluid" />
                      </div>
                    </div> <div className="cnrflash">
                      <div className="cnrflash-inner">
                        <img src="images/best-seller.png" alt="*" className="img-fluid" />
                      </div>
                    </div>
                    <div className="pkg_top">
                      <div className="pkg_title text-center">
                        <div className="productSku" style={{display: 'none'}}>WEBSITE_DESIGN_SLIVER</div>
                        <h3>Silver Website Package</h3>
                      </div>
                      <div className="pkg_pricing_area d-flex justify-content-between align-items-center">
                        <div className="sale_price">
                          <h3>$1599.99</h3>
                        </div>
                        <div className="cut_price">
                          <h4><span>$3199.98</span></h4>
                        </div>
                      </div>
                      <div className="short_desc text-center">
                        <p>Suitable for potential super-startups and brand revamps for companies.</p>
                      </div>
                    </div>
                    <div className="pkg_details">
                      <div data-package-scroll>
                        <ul>
                          <li>15 to 20 Pages Website</li>
                          <li>Custom Made, Interactive, Dynamic &amp; High End Design</li>
                          <li>Custom WP</li>
                          <li>1 jQuery Slider Banner</li>
                          <li>Up to 10 Custom Made Banner Designs</li>
                          <li>10 Stock Images</li>
                          <li>Unlimited Revisions</li>
                          <li>Special Hoover Effects</li>
                          <li>Content Management System (CMS)</li>
                          <li>Online Appointment/Scheduling/Online Ordering Integration (Optional)</li>
                          <li>Online Payment Integration (Optional)</li>
                          <li>Multi Lingual (Optional)</li>
                          <li>Custom Dynamic Forms (Optional)</li>
                          <li>Signup Area (For Newsletters, Offers etc.)</li>
                          <li>Search Bar</li>
                          <li>Live Feeds of Social Networks integration (Optional)</li>
                          <li>Mobile Responsive</li>
                          <li>FREE 5 Years Domain Name</li>
                          <li>Free Google Friendly Sitemap</li>
                          <li>Search Engine Submission</li>
                          <li>Complete W3C Certified HTML</li>
                          <li>Industry Specified Team of Expert Designers and Developers</li>
                          <li>Complete Deployment</li>
                          <li>Dedicated Accounts Manager</li>
                          <li>Facebook Page Design</li>
                          <li>Twitter Page Design</li>
                          <li>YouTube Page Design</li>
                          <li>100% Ownership Rights</li>
                          <li>100% Satisfaction Guarantee</li>
                          <li>100% Unique Design Guarantee</li>
                          <li>100% Money Back Guarantee *</li>
                        </ul>         </div>
                    </div>
                    <a href="./../view-detail.php?package=Silver Website Package" className="view_detail_btn">View Details</a>
                    <div className="pkg_bottom d-flex justify-content-between align-items-center">
                      <a href="javascript:void(0)" className="order_btn order-package btn-10" data-sku="WEBSITE_DESIGN_SLIVER" data-promotion-id={0} data-price="1599.99" data-price-text="$1599.99" data-title="Silver Website Package" data-package-id={2966}>Buy Now</a>
                      <a href="javascript:;" className="chat pkg_chat_btn chatt"><span>Click here to</span><br />Live Chat</a>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="package_box " data-package-box>
                    <div className="cnrflash">
                      <div className="cnrflash-inner">
                        <img src="images/best-seller.png" alt="*" className="img-fluid" />
                      </div>
                    </div> <div className="cnrflash">
                      <div className="cnrflash-inner">
                        <img src="images/best-seller.png" alt="*" className="img-fluid" />
                      </div>
                    </div>
                    <div className="pkg_top">
                      <div className="pkg_title text-center">
                        <div className="productSku" style={{display: 'none'}}>WEBSITE_DESIGN_BUSINESS</div>
                        <h3>Business Website Package</h3>
                      </div>
                      <div className="pkg_pricing_area d-flex justify-content-between align-items-center">
                        <div className="sale_price">
                          <h3>$2499.99</h3>
                        </div>
                        <div className="cut_price">
                          <h4><span>$4999.98</span></h4>
                        </div>
                      </div>
                      <div className="short_desc text-center">
                        <p>Suitable for potential super-startups and brand revamps for companies.</p>
                      </div>
                    </div>
                    <div className="pkg_details">
                      <div data-package-scroll>
                        <ul>
                          <li>15 to 20 Pages Website</li>
                          <li>Custom Made, Interactive, Dynamic &amp; High End Design</li>
                          <li>Custom WP</li>
                          <li>1 jQuery Slider Banner</li>
                          <li>Up to 10 Custom Made Banner Designs</li>
                          <li>10 Stock Images</li>
                          <li>Unlimited Revisions</li>
                          <li>Special Hoover Effects</li>
                          <li>Content Management System (CMS)</li>
                          <li>Online Appointment/Scheduling/Online Ordering Integration (Optional)</li>
                          <li>Online Payment Integration (Optional)</li>
                          <li>Multi Lingual (Optional)</li>
                          <li>Custom Dynamic Forms (Optional)</li>
                          <li>Signup Area (For Newsletters, Offers etc.)</li>
                          <li>Search Bar</li>
                          <li>Live Feeds of Social Networks integration (Optional)</li>
                          <li>Mobile Responsive</li>
                          <li>15 Seconds 2D Explainer Video</li>
                          <li>Voice - Over &amp; Sound Effects</li>
                          <li>Professional Script Writing</li>
                          <li>Storyboard</li>
                          <li>SEO Meta Tags</li>
                          <li>FREE 5 Years Domain Name</li>
                          <li>Free Google Friendly Sitemap</li>
                          <li>Search Engine Submission</li>
                          <li>Complete W3C Certified HTML</li>
                          <li>Industry Specified Team of Expert Designers and Developers</li>
                          <li>Complete Deployment</li>
                          <li>Dedicated Accounts Manager</li>
                          <li>100% Ownership Rights</li>
                          <li>100% Satisfaction Guarantee</li>
                          <li>100% Unique Design Guarantee</li>
                          <li>100% Money Back Guarantee *</li>
                        </ul>         </div>
                    </div>
                    <a href="./../view-detail.php?package=Business Website Package" className="view_detail_btn">View Details</a>
                    <div className="pkg_bottom d-flex justify-content-between align-items-center">
                      <a href="javascript:void(0)" className="order_btn order-package btn-10" data-sku="WEBSITE_DESIGN_BUSINESS" data-promotion-id={0} data-price="2499.99" data-price-text="$2499.99" data-title="Business Website Package" data-package-id={2967}>Buy Now</a>
                      <a href="javascript:;" className="chat pkg_chat_btn chatt"><span>Click here to</span><br />Live Chat</a>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="package_box " data-package-box>
                    <div className="cnrflash">
                      <div className="cnrflash-inner">
                        <img src="images/best-seller.png" alt="*" className="img-fluid" />
                      </div>
                    </div> <div className="cnrflash">
                      <div className="cnrflash-inner">
                        <img src="images/best-seller.png" alt="*" className="img-fluid" />
                      </div>
                    </div>
                    <div className="pkg_top">
                      <div className="pkg_title text-center">
                        <div className="productSku" style={{display: 'none'}}>WEBSITE_DESIGN_AUTOMATED</div>
                        <h3>Automated/Interactive Conferencing Portal</h3>
                      </div>
                      <div className="pkg_pricing_area d-flex justify-content-between align-items-center">
                        <div className="sale_price">
                          <h3>$4999.99</h3>
                        </div>
                        <div className="cut_price">
                          <h4><span>$9999.98</span></h4>
                        </div>
                      </div>
                      <div className="short_desc text-center">
                        <p>Suitable for potential Mid Sized and Enterprise companies.</p>
                      </div>
                    </div>
                    <div className="pkg_details">
                      <div data-package-scroll>
                        <ul>
                          <li>Unlimited Page Website</li>
                          <li>Custom Content Management System (CMS)</li>
                          <li>Unique Pages and UI Design</li>
                          <li>Complete Custom Development</li>
                          <li>Process Automation Tools</li>
                          <li>Newsfeed Integration</li>
                          <li>Social Media Plugins Integration</li>
                          <li>Upto 40 Stock images</li>
                          <li>10 Unique Banner Designs</li>
                          <li>JQuery Slider</li>
                          <li>Search Engine Submission</li>
                          <li>Free Google Friendly Sitemap</li>
                          <li>FREE 5 Years Hosting</li>
                          <li>Custom Email Addresses</li>
                          <li>Social Media Page Designs (Facebook, Twitter, Instagram)</li>
                          <li>Complete W3C Certified HTML</li>
                          <li>Complete Deployment</li>
                          <li>100% Satisfaction Guarantee</li>
                          <li>100% Unique Design Guarantee</li>
                          <li>Money Back Guarantee</li>
                          <li><strong>Key features</strong></li>
                          <li>Automated Course Creation</li>
                          <li>Video Conferencing</li>
                          <li>Skills/Certification Tracking</li>
                          <li>Mobile Learning</li>
                          <li>Asynchronous Learning</li>
                          <li>CRM Features</li>
                          <li>Gamification</li>
                          <li>Social Learning/Message Boards</li>
                          <li>Motivational Triggers</li>
                          <li>Forums And Webinars</li>
                          <li>E-commerce And Subscriptions</li>
                          <li>Online Course Booking</li>
                          <li>Excellent Reporting</li>
                          <li>Invoicing Integration</li>
                          <li>Financial Integrations</li>
                          <li>Student Information management</li>
                          <li>Automated communications</li>
                          <li>Learning Management System</li>
                          <li>Quick And Easy Course Scheduling</li>
                          <li>Reporting And Data Analysis</li>
                          <li>Assessment Management &amp; Live Feedback</li>
                          <li>Gradebooks</li>
                          <li>Quick User Integration</li>
                          <li>Easy Payment Methods</li>
                          <li>Online Communities &amp; Social Engagement</li>
                          <li>Curation of Resources And Adding Own Resources</li>
                        </ul>         </div>
                    </div>
                    <a href="./../view-detail.php?package=Automated/Interactive Conferencing Portal" className="view_detail_btn">View Details</a>
                    <div className="pkg_bottom d-flex justify-content-between align-items-center">
                      <a href="javascript:void(0)" className="order_btn order-package btn-10" data-sku="WEBSITE_DESIGN_AUTOMATED" data-promotion-id={0} data-price="4999.99" data-price-text="$4999.99" data-title="Automated/Interactive Conferencing Portal" data-package-id={2968}>Buy Now</a>
                      <a href="javascript:;" className="chat pkg_chat_btn chatt"><span>Click here to</span><br />Live Chat</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-pane fade in show " id="tab_content_794" role="tabpanel" aria-labelledby="profile-tab">
              <div className="package-carousel">
                <div className="item">
                  <div className="package_box " data-package-box>
                    <div className="cnrflash">
                      <div className="cnrflash-inner">
                        <img src="images/best-seller.png" alt="*" className="img-fluid" />
                      </div>
                    </div> <div className="cnrflash">
                      <div className="cnrflash-inner">
                        <img src="images/best-seller.png" alt="*" className="img-fluid" />
                      </div>
                    </div>
                    <div className="pkg_top">
                      <div className="pkg_title text-center">
                        <div className="productSku" style={{display: 'none'}}>VIDEO_TEASER</div>
                        <h3>Teaser/Intro Video Package</h3>
                      </div>
                      <div className="pkg_pricing_area d-flex justify-content-between align-items-center">
                        <div className="sale_price">
                          <h3>$149</h3>
                        </div>
                        <div className="cut_price">
                          <h4><span>$298</span></h4>
                        </div>
                      </div>
                      <div className="short_desc text-center">
                        <p>Suitable for potential super-startups and brand revamps for companies.</p>
                      </div>
                    </div>
                    <div className="pkg_details">
                      <div data-package-scroll>
                        <ul>
                          <li>15s Duration - HD 1080</li>
                          <li>Professional Script</li>
                          <li>Storyboard</li>
                          <li>Sample Themes</li>
                          <li>Custom Setting, Characters &amp; Graphics</li>
                          <li>Animation Effects &amp; Visualization</li>
                          <li>Voice - Over &amp; Sound Effects (All accents) (M/F)</li>
                          <li>Unlimited Revisions</li>
                        </ul>         </div>
                    </div>
                    <a href="./../view-detail.php?package=Teaser/Intro Video Package" className="view_detail_btn">View Details</a>
                    <div className="pkg_bottom d-flex justify-content-between align-items-center">
                      <a href="javascript:void(0)" className="order_btn order-package btn-10" data-sku="VIDEO_TEASER" data-promotion-id={0} data-price={149} data-price-text="$149.00" data-title="Teaser/Intro Video Package" data-package-id={2973}>Buy Now</a>
                      <a href="javascript:;" className="chat pkg_chat_btn chatt"><span>Click here to</span><br />Live Chat</a>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="package_box " data-package-box>
                    <div className="cnrflash">
                      <div className="cnrflash-inner">
                        <img src="images/best-seller.png" alt="*" className="img-fluid" />
                      </div>
                    </div> <div className="cnrflash">
                      <div className="cnrflash-inner">
                        <img src="images/best-seller.png" alt="*" className="img-fluid" />
                      </div>
                    </div>
                    <div className="pkg_top">
                      <div className="pkg_title text-center">
                        <div className="productSku" style={{display: 'none'}}>VIDEO_STARTUP</div>
                        <h3>Startup Video Package</h3>
                      </div>
                      <div className="pkg_pricing_area d-flex justify-content-between align-items-center">
                        <div className="sale_price">
                          <h3>$199</h3>
                        </div>
                        <div className="cut_price">
                          <h4><span>$398</span></h4>
                        </div>
                      </div>
                      <div className="short_desc text-center">
                        <p>Suitable for potential super-startups and brand revamps for companies.</p>
                      </div>
                    </div>
                    <div className="pkg_details">
                      <div data-package-scroll>
                        <ul>
                          <li>30s Duration - HD 1080</li>
                          <li>Professional Script</li>
                          <li>Storyboard</li>
                          <li>Sample Themes</li>
                          <li>Custom Setting, Characters &amp; Graphics</li>
                          <li>Animation Effects &amp; Visualization</li>
                          <li>Voice - Over &amp; Sound Effects (All accents) (M/F)</li>
                          <li>Unlimited Revisions</li>
                        </ul>         </div>
                    </div>
                    <a href="./../view-detail.php?package=Startup Video Package" className="view_detail_btn">View Details</a>
                    <div className="pkg_bottom d-flex justify-content-between align-items-center">
                      <a href="javascript:void(0)" className="order_btn order-package btn-10" data-sku="VIDEO_STARTUP" data-promotion-id={0} data-price={199} data-price-text="$199.00" data-title="Startup Video Package" data-package-id={2974}>Buy Now</a>
                      <a href="javascript:;" className="chat pkg_chat_btn chatt"><span>Click here to</span><br />Live Chat</a>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="package_box " data-package-box>
                    <div className="cnrflash">
                      <div className="cnrflash-inner">
                        <img src="images/best-seller.png" alt="*" className="img-fluid" />
                      </div>
                    </div> <div className="cnrflash">
                      <div className="cnrflash-inner">
                        <img src="images/best-seller.png" alt="*" className="img-fluid" />
                      </div>
                    </div>
                    <div className="pkg_top">
                      <div className="pkg_title text-center">
                        <div className="productSku" style={{display: 'none'}}>VIDEO_CLASSIC</div>
                        <h3>Classic Video Package</h3>
                      </div>
                      <div className="pkg_pricing_area d-flex justify-content-between align-items-center">
                        <div className="sale_price">
                          <h3>$399</h3>
                        </div>
                        <div className="cut_price">
                          <h4><span>$798</span></h4>
                        </div>
                      </div>
                      <div className="short_desc text-center">
                        <p>Suitable for potential super-startups and brand revamps for companies.</p>
                      </div>
                    </div>
                    <div className="pkg_details">
                      <div data-package-scroll>
                        <ul>
                          <li>60 Second Video - HD 1080</li>
                          <li>Professional Script</li>
                          <li>Sample Theme</li>
                          <li>Storyboard</li>
                          <li>Animation</li>
                          <li>Voice - Over &amp; Sound Effects</li>
                          <li>5 weeks Delivery</li>
                          <li>Unlimited Revisions</li>
                        </ul>         </div>
                    </div>
                    <a href="./../view-detail.php?package=Classic Video Package" className="view_detail_btn">View Details</a>
                    <div className="pkg_bottom d-flex justify-content-between align-items-center">
                      <a href="javascript:void(0)" className="order_btn order-package btn-10" data-sku="VIDEO_CLASSIC" data-promotion-id={0} data-price={399} data-price-text="$399.00" data-title="Classic Video Package" data-package-id={2975}>Buy Now</a>
                      <a href="javascript:;" className="chat pkg_chat_btn chatt"><span>Click here to</span><br />Live Chat</a>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="package_box " data-package-box>
                    <div className="cnrflash">
                      <div className="cnrflash-inner">
                        <img src="images/best-seller.png" alt="*" className="img-fluid" />
                      </div>
                    </div> <div className="cnrflash">
                      <div className="cnrflash-inner">
                        <img src="images/best-seller.png" alt="*" className="img-fluid" />
                      </div>
                    </div>
                    <div className="pkg_top">
                      <div className="pkg_title text-center">
                        <div className="productSku" style={{display: 'none'}}>VIDEO_PREMIUM</div>
                        <h3>Premium Video Package</h3>
                      </div>
                      <div className="pkg_pricing_area d-flex justify-content-between align-items-center">
                        <div className="sale_price">
                          <h3>$699</h3>
                        </div>
                        <div className="cut_price">
                          <h4><span>$1398</span></h4>
                        </div>
                      </div>
                      <div className="short_desc text-center">
                        <p>Suitable for potential super-startups and brand revamps for companies.</p>
                      </div>
                    </div>
                    <div className="pkg_details">
                      <div data-package-scroll>
                        <ul>
                          <li>90 Second Video - HD 1080</li>
                          <li>Professional Script</li>
                          <li>Sample Theme</li>
                          <li>Storyboard</li>
                          <li>Animation</li>
                          <li>Voice - Over &amp; Sound Effects</li>
                          <li>6 Weeks Delivery</li>
                          <li>Unlimited Revisions</li>
                        </ul>         </div>
                    </div>
                    <a href="./../view-detail.php?package=Premium Video Package" className="view_detail_btn">View Details</a>
                    <div className="pkg_bottom d-flex justify-content-between align-items-center">
                      <a href="javascript:void(0)" className="order_btn order-package btn-10" data-sku="VIDEO_PREMIUM" data-promotion-id={0} data-price={699} data-price-text="$699.00" data-title="Premium Video Package" data-package-id={2976}>Buy Now</a>
                      <a href="javascript:;" className="chat pkg_chat_btn chatt"><span>Click here to</span><br />Live Chat</a>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="package_box " data-package-box>
                    <div className="cnrflash">
                      <div className="cnrflash-inner">
                        <img src="images/best-seller.png" alt="*" className="img-fluid" />
                      </div>
                    </div> <div className="cnrflash">
                      <div className="cnrflash-inner">
                        <img src="images/best-seller.png" alt="*" className="img-fluid" />
                      </div>
                    </div>
                    <div className="pkg_top">
                      <div className="pkg_title text-center">
                        <div className="productSku" style={{display: 'none'}}>WEB_DESIGN_DELUXE</div>
                        <h3>Deluxe Video Package</h3>
                      </div>
                      <div className="pkg_pricing_area d-flex justify-content-between align-items-center">
                        <div className="sale_price">
                          <h3>$899</h3>
                        </div>
                        <div className="cut_price">
                          <h4><span>$1798</span></h4>
                        </div>
                      </div>
                      <div className="short_desc text-center">
                        <p>Suitable for potential super-startups and brand revamps for companies.</p>
                      </div>
                    </div>
                    <div className="pkg_details">
                      <div data-package-scroll>
                        <ul>
                          <li>120 Second Video - HD 1080</li>
                          <li>Professional Script</li>
                          <li>Sample Theme</li>
                          <li>Storyboard</li>
                          <li>Animation</li>
                          <li>Voice - Over &amp; Sound Effects</li>
                          <li>Unlimited Revisions</li>
                        </ul>         </div>
                    </div>
                    <a href="./../view-detail.php?package=Deluxe Video Package" className="view_detail_btn">View Details</a>
                    <div className="pkg_bottom d-flex justify-content-between align-items-center">
                      <a href="javascript:void(0)" className="order_btn order-package btn-10" data-sku="WEB_DESIGN_DELUXE" data-promotion-id={0} data-price={899} data-price-text="$899.00" data-title="Deluxe Video Package" data-package-id={2977}>Buy Now</a>
                      <a href="javascript:;" className="chat pkg_chat_btn chatt"><span>Click here to</span><br />Live Chat</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-pane fade in show " id="tab_content_795" role="tabpanel" aria-labelledby="profile-tab">
              <div className="package-carousel">
                <div className="item">
                  <div className="package_box " data-package-box>
                    <div className="cnrflash">
                      <div className="cnrflash-inner">
                        <img src="images/best-seller.png" alt="*" className="img-fluid" />
                      </div>
                    </div> <div className="cnrflash">
                      <div className="cnrflash-inner">
                        <img src="images/best-seller.png" alt="*" className="img-fluid" />
                      </div>
                    </div>
                    <div className="pkg_top">
                      <div className="pkg_title text-center">
                        <div className="productSku" style={{display: 'none'}}>SEO_IDENTITY</div>
                        <h3>Identity SEO Package</h3>
                      </div>
                      <div className="pkg_pricing_area d-flex justify-content-between align-items-center">
                        <div className="sale_price">
                          <h3>$849</h3>
                        </div>
                        <div className="cut_price">
                          <h4><span>$1698</span></h4>
                        </div>
                      </div>
                      <div className="short_desc text-center">
                        <p>Suitable for potential super-startups and brand revamps for companies.</p>
                      </div>
                    </div>
                    <div className="pkg_details">
                      <div data-package-scroll>
                        <ul>
                          <li>10 Keywords</li>
                          <li>Guaranteed Ranking on Google</li>
                          <li>Off-site Optimization</li>
                          <li>On-site Optimization</li>
                          <li>Link Building</li>
                          <li>Social Bookmarking</li>
                          <li>In-depth Site Analysis</li>
                          <li>Content Duplicacy Check</li>
                          <li>Initial Backlinks analysis</li>
                          <li>Google Penalty Check</li>
                          <li>Mobile Usability Check</li>
                          <li>Competition Analysis</li>
                          <li>Keyword Research</li>
                          <li>Title &amp; Meta Tags Optimization</li>
                          <li>Content Optimization</li>
                          <li>Page Speed Analysis &amp; Optimization</li>
                          <li>HTML Code Cleanup &amp; Optimization</li>
                          <li>Internal Link Structuring &amp; Optimization</li>
                          <li>Pages H tags Optimization</li>
                          <li>Canonicalization/301 Redirect</li>
                          <li>Website Page Load Optimization</li>
                          <li>Schema Markup Implementation</li>
                          <li>Image &amp; Hyperlink Optimization</li>
                          <li>Robots.txt Creation/Analysis</li>
                          <li>Blog Writing (2 - Per Month)</li>
                          <li>Informational Content Writing &amp; Sharing (1 Per Month)</li>
                          <li>Press Release Writing &amp; Distribution</li>
                          <li>Press Release Social Bookmarking</li>
                          <li>Google Webmaster Tools Setup</li>
                          <li>Google Analytics Setup &amp; Integration</li>
                        </ul>         </div>
                    </div>
                    <a href="./../view-detail.php?package=Identity SEO Package" className="view_detail_btn">View Details</a>
                    <div className="pkg_bottom d-flex justify-content-between align-items-center">
                      <a href="javascript:void(0)" className="order_btn order-package btn-10" data-sku="SEO_IDENTITY" data-promotion-id={0} data-price={849} data-price-text="$849.00" data-title="Identity SEO Package" data-package-id={2978}>Buy Now</a>
                      <a href="javascript:;" className="chat pkg_chat_btn chatt"><span>Click here to</span><br />Live Chat</a>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="package_box " data-package-box>
                    <div className="cnrflash">
                      <div className="cnrflash-inner">
                        <img src="images/best-seller.png" alt="*" className="img-fluid" />
                      </div>
                    </div> <div className="cnrflash">
                      <div className="cnrflash-inner">
                        <img src="images/best-seller.png" alt="*" className="img-fluid" />
                      </div>
                    </div>
                    <div className="pkg_top">
                      <div className="pkg_title text-center">
                        <div className="productSku" style={{display: 'none'}}>SEO_ELITE</div>
                        <h3>Elite SEO Packages</h3>
                      </div>
                      <div className="pkg_pricing_area d-flex justify-content-between align-items-center">
                        <div className="sale_price">
                          <h3>$1549</h3>
                        </div>
                        <div className="cut_price">
                          <h4><span>$3098</span></h4>
                        </div>
                      </div>
                      <div className="short_desc text-center">
                        <p>Suitable for potential super-startups and brand revamps for companies.</p>
                      </div>
                    </div>
                    <div className="pkg_details">
                      <div data-package-scroll>
                        <ul>
                          <li>20 Keywords</li>
                          <li>Guaranteed Ranking on Google</li>
                          <li>Off-site Optimization</li>
                          <li>On-site Optimization</li>
                          <li>Link Building</li>
                          <li>Social Bookmarking</li>
                          <li>In-depth Site Analysis</li>
                          <li>Content Duplicacy Check</li>
                          <li>Initial Backlinks analysis</li>
                          <li>Google Penalty Check</li>
                          <li>Mobile Usability Check</li>
                          <li>Competition Analysis</li>
                          <li>Keyword Research</li>
                          <li>Page Speed Analysis &amp; Optimization</li>
                          <li>Title &amp; Meta Tags Optimization</li>
                          <li>Content Optimization</li>
                          <li>HTML Code Cleanup &amp; Optimization</li>
                          <li>Internal Link Structuring &amp; Optimization</li>
                          <li>Pages H tags Optimization</li>
                          <li>Canonicalization/301 Redirect</li>
                          <li>Website Page Load Optimization</li>
                          <li>Robots.txt Creation/Analysis</li>
                          <li>Press Release Writing &amp; Distribution</li>
                          <li>Press Release Social Bookmarking</li>
                          <li>Schema Markup Implementation</li>
                          <li>Image &amp; Hyperlink Optimization</li>
                          <li>Google Webmaster Tools Setup</li>
                          <li>Google Analytics Setup &amp; Integration</li>
                          <li>Blog Writing (2 - Per Month)</li>
                          <li>Informational Content Writing &amp; Sharing (1 Per Month)</li>
                        </ul>         </div>
                    </div>
                    <a href="./../view-detail.php?package=Elite SEO Packages" className="view_detail_btn">View Details</a>
                    <div className="pkg_bottom d-flex justify-content-between align-items-center">
                      <a href="javascript:void(0)" className="order_btn order-package btn-10" data-sku="SEO_ELITE" data-promotion-id={0} data-price={1549} data-price-text="$1549.00" data-title="Elite SEO Packages" data-package-id={2979}>Buy Now</a>
                      <a href="javascript:;" className="chat pkg_chat_btn chatt"><span>Click here to</span><br />Live Chat</a>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="package_box " data-package-box>
                    <div className="cnrflash">
                      <div className="cnrflash-inner">
                        <img src="images/best-seller.png" alt="*" className="img-fluid" />
                      </div>
                    </div> <div className="cnrflash">
                      <div className="cnrflash-inner">
                        <img src="images/best-seller.png" alt="*" className="img-fluid" />
                      </div>
                    </div>
                    <div className="pkg_top">
                      <div className="pkg_title text-center">
                        <div className="productSku" style={{display: 'none'}}>SEO_PROFESSIONAL</div>
                        <h3>Professional SEO Packages</h3>
                      </div>
                      <div className="pkg_pricing_area d-flex justify-content-between align-items-center">
                        <div className="sale_price">
                          <h3>$2149</h3>
                        </div>
                        <div className="cut_price">
                          <h4><span>$4298</span></h4>
                        </div>
                      </div>
                      <div className="short_desc text-center">
                        <p>Suitable for potential super-startups and brand revamps for companies.</p>
                      </div>
                    </div>
                    <div className="pkg_details">
                      <div data-package-scroll>
                        <ul>
                          <li>30 Keywords</li>
                          <li>Guaranteed Ranking on Google</li>
                          <li>Off-site Optimization</li>
                          <li>On-site Optimization</li>
                          <li>Link Building</li>
                          <li>Social Bookmarking</li>
                          <li>In-depth Site Analysis</li>
                          <li>Content Duplicacy Check</li>
                          <li>Initial Backlinks analysis</li>
                          <li>Google Penalty Check</li>
                          <li>Mobile Usability Check</li>
                          <li>Competition Analysis</li>
                          <li>Keyword Research</li>
                          <li>Page Speed Analysis &amp; Optimization</li>
                          <li>Title &amp; Meta Tags Optimization</li>
                          <li>Content Optimization</li>
                          <li>HTML Code Cleanup &amp; Optimization</li>
                          <li>Internal Link Structuring &amp; Optimization</li>
                          <li>Pages H tags Optimization</li>
                          <li>Canonicalization/301 Redirect</li>
                          <li>Website Page Load Optimization</li>
                          <li>Robots.txt Creation/Analysis</li>
                          <li>Press Release Writing &amp; Distribution</li>
                          <li>Press Release Social Bookmarking</li>
                          <li>Schema Markup Implementation</li>
                          <li>Image &amp; Hyperlink Optimization</li>
                          <li>Google Webmaster Tools Setup</li>
                          <li>Google Analytics Setup &amp; Integration</li>
                          <li>Blog Writing (2 - Per Month)</li>
                          <li>Informational Content Writing &amp; Sharing (1 Per Month)</li>
                        </ul>         </div>
                    </div>
                    <a href="./../view-detail.php?package=Professional SEO Packages" className="view_detail_btn">View Details</a>
                    <div className="pkg_bottom d-flex justify-content-between align-items-center">
                      <a href="javascript:void(0)" className="order_btn order-package btn-10" data-sku="SEO_PROFESSIONAL" data-promotion-id={0} data-price={2149} data-price-text="$2149.00" data-title="Professional SEO Packages" data-package-id={2980}>Buy Now</a>
                      <a href="javascript:;" className="chat pkg_chat_btn chatt"><span>Click here to</span><br />Live Chat</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-pane fade in show " id="tab_content_796" role="tabpanel" aria-labelledby="profile-tab">
              <div className="package-carousel">
                <div className="item">
                  <div className="package_box " data-package-box>
                    <div className="cnrflash">
                      <div className="cnrflash-inner">
                        <img src="images/best-seller.png" alt="*" className="img-fluid" />
                      </div>
                    </div> <div className="cnrflash">
                      <div className="cnrflash-inner">
                        <img src="images/best-seller.png" alt="*" className="img-fluid" />
                      </div>
                    </div>
                    <div className="pkg_top">
                      <div className="pkg_title text-center">
                        <div className="productSku" style={{display: 'none'}}>ECOMMERCE_BEGINNERS</div>
                        <h3>Beginners E-Commerce Package</h3>
                      </div>
                      <div className="pkg_pricing_area d-flex justify-content-between align-items-center">
                        <div className="sale_price">
                          <h3>$999.99</h3>
                        </div>
                        <div className="cut_price">
                          <h4><span>$1999.99</span></h4>
                        </div>
                      </div>
                      <div className="short_desc text-center">
                        <p>Suitable for potential super-startups and brand revamps for companies.</p>
                      </div>
                    </div>
                    <div className="pkg_details">
                      <div data-package-scroll>
                        <ul>
                          <li>Upto 15 Unique Pages Website</li>
                          <li>Conceptual and Dynamic Website</li>
                          <li>Content Management System (CMS)</li>
                          <li>Mobile Responsive</li>
                          <li>Easy Product Search</li>
                          <li>Product Reviews</li>
                          <li>Up To 100 Products</li>
                          <li>Up To 7 Categories</li>
                          <li>Full Shopping Cart Integration</li>
                          <li>Payment Module Integration</li>
                          <li>Sales &amp; Inventory Management</li>
                          <li>Jquery Slider</li>
                          <li>Free Google Friendly Sitemap</li>
                          <li>FREE 1 Years Hosting</li>
                          <li>Custom Email Addresses</li>
                          <li>Complete W3C Certified HTML</li>
                          <li>Facebook Page Design</li>
                          <li>Twitter Page Design</li>
                          <li>YouTube Page Design</li>
                          <li>Complete Deployment</li>
                          <li>Dedicated Accounts Manager</li>
                          <li>100% Ownership Rights</li>
                          <li>100% Satisfaction Guarantee</li>
                          <li>100% Unique Design Guarantee</li>
                          <li>100% Money Back Guarantee</li>
                        </ul>         </div>
                    </div>
                    <a href="./../view-detail.php?package=Beginners E-Commerce Package" className="view_detail_btn">View Details</a>
                    <div className="pkg_bottom d-flex justify-content-between align-items-center">
                      <a href="javascript:void(0)" className="order_btn order-package btn-10" data-sku="ECOMMERCE_BEGINNERS" data-promotion-id={0} data-price="999.99" data-price-text="$999.99" data-title="Beginners E-Commerce Package" data-package-id={2969}>Buy Now</a>
                      <a href="javascript:;" className="chat pkg_chat_btn chatt"><span>Click here to</span><br />Live Chat</a>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="package_box  active " data-package-box>
                    <div className="cnrflash">
                      <div className="cnrflash-inner">
                        <img src="images/best-seller.png" alt="*" className="img-fluid" />
                      </div>
                    </div> <div className="cnrflash">
                      <div className="cnrflash-inner">
                        <img src="images/best-seller.png" alt="*" className="img-fluid" />
                      </div>
                    </div>
                    <div className="pkg_top">
                      <div className="pkg_title text-center">
                        <div className="productSku" style={{display: 'none'}}>ECOMMERCE_CORPORATE</div>
                        <h3>Corporate E-Commerce Package</h3>
                      </div>
                      <div className="pkg_pricing_area d-flex justify-content-between align-items-center">
                        <div className="sale_price">
                          <h3>$1799.99</h3>
                        </div>
                        <div className="cut_price">
                          <h4><span>$3598.99</span></h4>
                        </div>
                      </div>
                      <div className="short_desc text-center">
                        <p>Suitable for potential super-startups and brand revamps for companies.</p>
                      </div>
                    </div>
                    <div className="pkg_details">
                      <div data-package-scroll>
                        <ul> 
                          <li>Unlimited Unique Pages Website</li>
                          <li>Conceptual and Dynamic Website</li>
                          <li>Content Management System (CMS)</li>
                          <li>Mobile Responsive</li>
                          <li>Easy Product Search</li>
                          <li>Product Reviews</li>
                          <li>Unlimited Products</li>
                          <li>Unlimited Categories</li>
                          <li>Full Shopping Cart Integration</li>
                          <li>Payment Module Integration</li>
                          <li>Sales &amp; Inventory Management</li>
                          <li>Jquery Slider</li>
                          <li>Free Google Friendly Sitemap</li>
                          <li>Custom Email Addresses</li>
                          <li>Complete W3C Certified HTML</li>
                          <li>Facebook Page Design</li>
                          <li>Twitter Page Design</li>
                          <li>YouTube Page Design</li>
                          <li>Instagram Page Design</li>
                          <li>Complete Deployment</li>
                          <li>Dedicated Accounts Manager</li>
                          <li>100% Ownership Rights</li>
                          <li>100% Satisfaction Guarantee</li>
                          <li>100% Unique Design Guarantee</li>
                          <li>100% Money Back Guarantee</li>
                        </ul>         </div>
                    </div>
                    <a href="./../view-detail.php?package=Corporate E-Commerce Package" className="view_detail_btn">View Details</a>
                    <div className="pkg_bottom d-flex justify-content-between align-items-center">
                      <a href="javascript:void(0)" className="order_btn order-package btn-10" data-sku="ECOMMERCE_CORPORATE" data-promotion-id={0} data-price="1799.99" data-price-text="$1799.99" data-title="Corporate E-Commerce Package" data-package-id={2970}>Buy Now</a>
                      <a href="javascript:;" className="chat pkg_chat_btn chatt"><span>Click here to</span><br />Live Chat</a>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="package_box " data-package-box>
                    <div className="cnrflash">
                      <div className="cnrflash-inner">
                        <img src="images/best-seller.png" alt="*" className="img-fluid" />
                      </div>
                    </div> <div className="cnrflash">
                      <div className="cnrflash-inner">
                        <img src="images/best-seller.png" alt="*" className="img-fluid" />
                      </div>
                    </div>
                    <div className="pkg_top">
                      <div className="pkg_title text-center">
                        <div className="productSku" style={{display: 'none'}}>ECOMMERCE_ELITE</div>
                        <h3>Elite E-Commerce Package</h3>
                      </div>
                      <div className="pkg_pricing_area d-flex justify-content-between align-items-center">
                        <div className="sale_price">
                          <h3>$3694</h3>
                        </div>
                        <div className="cut_price">
                          <h4><span>$7388</span></h4>
                        </div>
                      </div>
                      <div className="short_desc text-center">
                        <p>Suitable for potential super-startups and brand revamps for companies.</p>
                      </div>
                    </div>
                    <div className="pkg_details">
                      <div data-package-scroll>
                        <ul>
                          <li>UNLIMITED Logo Design Concepts</li>
                          <li>By 6 Award Winning Designers</li>
                          <li>Icon Design</li>
                          <li>UNLIMITED Revisions</li>
                          <li>Print Media</li>
                          <li>Stationary Design (BusinessCard,Letterhead &amp; Envelope)</li>
                          <li>Invoice Design, Email Signature</li>
                          <li>Bi-Fold Brochure (OR) 2 Sided Flyer Design</li>
                          <li>Product Catalog Design</li>
                          <li>Sign age Design (OR) Label Design</li>
                          <li>T-Shirt Design (OR) Car Wrap Design</li>
                          <li>Website</li>
                          <li>E-Commerce Store Design</li>
                          <li>Product Detail Page Design</li>
                          <li>Unique Banner Slider</li>
                          <li>Featured Products Showcase</li>
                          <li>Full Shopping Cart Integration</li>
                          <li>Unlimited Products</li>
                          <li>Unlimited Categories</li>
                          <li>Product Rating &amp; Reviews</li>
                          <li>Easy Product Search</li>
                          <li>Payment Gateway Integration</li>
                          <li>Multi-currency Support</li>
                          <li>Content Management System</li>
                          <li>Customer Log-in Area</li>
                          <li>Mobile Responsive</li>
                          <li>Social Media Plugins Integration</li>
                          <li>Tell a Friend Feature</li>
                          <li>Social Media Pages</li>
                          <li>Facebook , Twitter, YouTube, Google+ &amp; Pinterest Page Designs</li>
                          <li>Value Added Services</li>
                          <li>Dedicated Account Manager</li>
                          <li>Unlimited Revisions</li>
                          <li>All Final File Formats</li>
                          <li>100% Ownership Rights</li>
                          <li>100% Satisfaction Guarantee</li>
                          <li>100% Unique Design Guarantee</li>
                          <li>100% Money Back Guarantee *</li>
                        </ul>         </div>
                    </div>
                    <a href="./../view-detail.php?package=Elite E-Commerce Package" className="view_detail_btn">View Details</a>
                    <div className="pkg_bottom d-flex justify-content-between align-items-center">
                      <a href="javascript:void(0)" className="order_btn order-package btn-10" data-sku="ECOMMERCE_ELITE" data-promotion-id={0} data-price={3694} data-price-text="$3694.00" data-title="Elite E-Commerce Package" data-package-id={2971}>Buy Now</a>
                      <a href="javascript:;" className="chat pkg_chat_btn chatt"><span>Click here to</span><br />Live Chat</a>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="package_box " data-package-box>
                    <div className="cnrflash">
                      <div className="cnrflash-inner">
                        <img src="images/best-seller.png" alt="*" className="img-fluid" />
                      </div>
                    </div> <div className="cnrflash">
                      <div className="cnrflash-inner">
                        <img src="images/best-seller.png" alt="*" className="img-fluid" />
                      </div>
                    </div>
                    <div className="pkg_top">
                      <div className="pkg_title text-center">
                        <div className="productSku" style={{display: 'none'}}>ECOMMERCE_AUTOMATED</div>
                        <h3>Automated/Interactive E-Commerce Package</h3>
                      </div>
                      <div className="pkg_pricing_area d-flex justify-content-between align-items-center">
                        <div className="sale_price">
                          <h3>$7999.99</h3>
                        </div>
                        <div className="cut_price">
                          <h4><span>$15999</span></h4>
                        </div>
                      </div>
                      <div className="short_desc text-center">
                        <p>Suitable for potential Mid Sized and Enterprise companies.</p>
                      </div>
                    </div>
                    <div className="pkg_details">
                      <div data-package-scroll>
                        <ul>
                          <li>Unlimited Page Website</li>
                          <li>Custom Content Management System (CMS)</li>
                          <li>Unique Pages and UI Design</li>
                          <li>Complete Custom Development</li>
                          <li>Process Automation Tools</li>
                          <li>Newsfeed Integration</li>
                          <li>Social Media Plugins Integration</li>
                          <li>Upto 40 Stock images</li>
                          <li>10 Unique Banner Designs</li>
                          <li>JQuery Slider</li>
                          <li>Search Engine Submission</li>
                          <li>Free Google Friendly Sitemap</li>
                          <li>FREE 5 Years Hosting</li>
                          <li>Custom Email Addresses</li>
                          <li>Social Media Page Designs (Facebook, Twitter, Instagram)</li>
                          <li>Complete W3C Certified HTML</li>
                          <li>Complete Deployment</li>
                          <li>100% Satisfaction Guarantee</li>
                          <li>100% Unique Design Guarantee</li>
                          <li>Money Back Guarantee</li>
                          <li><strong>Automated Inventory/Shipping/Supplier Module:</strong></li>
                          <li>Manage thousands to millions of inventory with ease and check stock levels in real-time. Receive low inventory notifications and generate purchase orders to replenish your stock.</li>
                          <li>Suppliers Integration (API NEEDED)</li>
                          <li>Shipper Integration (API NEEDED)</li>
                          <li>Order management</li>
                          <li>LOT numbers and expire date tracking</li>
                          <li>Transfer stock between warehouses (If Warehouse - API NEEDED)</li>
                          <li>Receive stock into a specific warehouse (If Warehouse - API NEEDED)</li>
                          <li>Fulfill orders from a particular warehouse (If Warehouse - API NEEDED)</li>
                          <li>Stock Management</li>
                          <li>Actionable Insights</li>
                          <li>Real- Time Visibility</li>
                          <li>Inventory Opportunities</li>
                          <li><strong>Advanced Features: (API Needed For Suppliers/Warehouse)</strong></li>
                          <li>Speak to suppliers during trivial conversations.</li>
                          <li>Set and send actions to suppliers regarding governance and compliance materials. Place purchasing requests.</li>
                          <li>Research and answer internal questions regarding procurement functionalities or a supplier/supplier set.</li>
                          <li>Receiving/filing/documentation of invoices and payments/order requests</li>
                          <li>Machine Learning (ML) for Supply Chain Planning (SCP)</li>
                          <li>Machine Learning for Warehouse Management</li>
                          <li>Natural Language Processing (NLP) for Data Cleansing and Building Data Robustness</li>
                          <li><strong>Automated Invoices &amp; Estimates</strong></li> 
                          <li>Create beautiful, professional invoices &amp; estimates in just a few seconds and then instantly email them as PDF's directly to your customers or prospects.</li>
                          <li>Automated Split invoicing</li>
                          <li>Automated Combine invoices</li>
                          <li>Invoice templates</li>
                          <li><strong>Automated Barcode Scanning</strong></li>
                          <li>Scan inventory into your orders, generate barcodes for your documents, and search for inventory or documents by scanning barcodes.</li>
                          <li><strong>Locations and Zones</strong></li>
                          <li>Have multiple warehouses, offices, or retail stores? No problem. Easily track where all your inventory is by organizing everything into locations and zones. Organize inventory items using custom attributes such as size, color, and location. View how many you have globally or at each location.</li>
                          <li><strong>Customer Accounts</strong></li>
                          <li>Performance and analytics</li>
                          <li>Customization of Personal Details</li>
                          <li>Process management</li>
                          <li>Sales Automation</li>
                          <li>Team Collaboration</li>
                          <li>Marketing Automation</li>
                          <li> Security</li>
                          <li>Integrations</li>
                          <li>Mobile Notifications</li>
                          <li>Sales Reports</li>
                          <li>Trend Analytics</li>
                          <li>Forecasting</li>
                          <li>Territory Management</li>
                          <li>Account Management</li>
                          <li>Event Integration</li>
                          <li>Advanced Data Security</li>
                          <li><strong>Purchase Orders</strong></li>
                          <li>With integrated purchase orders, you can easily replenish your inventory levels by ordering more stock and even track when those new items will arrive.</li>
                          <li>Partial orders fulfill</li>
                          <li>Backordering</li>
                          <li><strong>Financial Reports</strong></li>
                          <li>Generate extremely detailed reports for your inventory, sales and services. Filter your reports by date-range and category to see what's making you the most money.</li>
                        </ul>         </div>
                    </div>
                    <a href="./../view-detail.php?package=Automated/Interactive E-Commerce Package" className="view_detail_btn">View Details</a>
                    <div className="pkg_bottom d-flex justify-content-between align-items-center">
                      <a href="javascript:void(0)" className="order_btn order-package btn-10" data-sku="ECOMMERCE_AUTOMATED" data-promotion-id={0} data-price="7999.99" data-price-text="$7999.99" data-title="Automated/Interactive E-Commerce Package" data-package-id={2972}>Buy Now</a>
                      <a href="javascript:;" className="chat pkg_chat_btn chatt"><span>Click here to</span><br />Live Chat</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-pane fade in show " id="tab_content_836" role="tabpanel" aria-labelledby="profile-tab">
              <div className="package-carousel">
                <div className="item">
                  <div className="package_box " data-package-box>
                    <div className="cnrflash">
                      <div className="cnrflash-inner">
                        <img src="images/best-seller.png" alt="*" className="img-fluid" />
                      </div>
                    </div> <div className="cnrflash">
                      <div className="cnrflash-inner">
                        <img src="images/best-seller.png" alt="*" className="img-fluid" />
                      </div>
                    </div>
                    <div className="pkg_top">
                      <div className="pkg_title text-center">
                        <div className="productSku" style={{display: 'none'}}>COMBO_BASIC_COMBO</div>
                        <h3>Basic Combo Package</h3>
                      </div>
                      <div className="pkg_pricing_area d-flex justify-content-between align-items-center">
                        <div className="sale_price">
                          <h3>$449</h3>
                        </div>
                        <div className="cut_price">
                          <h4><span>$898</span></h4>
                        </div>
                      </div>
                      <div className="short_desc text-center">
                        <p>Suitable for potential super-startups and brand revamps for companies.</p>
                      </div>
                    </div>
                    <div className="pkg_details">
                      <div data-package-scroll>
                        <ul>
                          <li>5 Custom Logo Design Concepts</li>
                          <li>By 2 Designers</li>
                          <li> Icon Design</li>
                          <li>Business Card, Letterhead, Envelope, Fax Template</li>
                          <li>MS Word Letterhead</li>
                          <li>5 Page Website</li>
                          <li>Mobile Responsive</li>
                          <li>Team of Expert Designers &amp; Developers</li>
                          <li>8 Stock images</li>
                          <li>5 Banner Designs</li>
                          <li>jQuery Sliders</li>
                          <li>Free Google Friendly Sitemap</li>
                          <li>Complete W3C Certified HTML</li>
                          <li>Complete Deployment</li>
                          <li>Facebook Page Design</li>
                          <li>Twitter Page Design</li>
                          <li>YouTube Page Design</li>
                          <li>All Final File Formats</li>
                          <li>Dedicated Account Manager</li>
                          <li>100% Ownership Rights</li>
                          <li>100% Satisfaction Guarantee</li>
                          <li>100% Unique Design Guarantee</li>
                          <li>100% Money Back Guarantee *</li>
                        </ul>         </div>
                    </div>
                    <a href="./../view-detail.php?package=Basic Combo Package" className="view_detail_btn">View Details</a>
                    <div className="pkg_bottom d-flex justify-content-between align-items-center">
                      <a href="javascript:void(0)" className="order_btn order-package btn-10" data-sku="COMBO_BASIC_COMBO" data-promotion-id={0} data-price={449} data-price-text="$449.00" data-title="Basic Combo Package" data-package-id={3116}>Buy Now</a>
                      <a href="javascript:;" className="chat pkg_chat_btn chatt"><span>Click here to</span><br />Live Chat</a>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="package_box " data-package-box>
                    <div className="cnrflash">
                      <div className="cnrflash-inner">
                        <img src="images/best-seller.png" alt="*" className="img-fluid" />
                      </div>
                    </div> <div className="cnrflash">
                      <div className="cnrflash-inner">
                        <img src="images/best-seller.png" alt="*" className="img-fluid" />
                      </div>
                    </div>
                    <div className="pkg_top">
                      <div className="pkg_title text-center">
                        <div className="productSku" style={{display: 'none'}}>COMBO_STARTUP_COMBO</div>
                        <h3>Startup Combo Package</h3>
                      </div>
                      <div className="pkg_pricing_area d-flex justify-content-between align-items-center">
                        <div className="sale_price">
                          <h3>$999</h3>
                        </div>
                        <div className="cut_price">
                          <h4><span>$1998</span></h4>
                        </div>
                      </div>
                      <div className="short_desc text-center">
                        <p>Suitable for potential super-startups and brand revamps for companies.</p>
                      </div>
                    </div>
                    <div className="pkg_details">
                      <div data-package-scroll>
                        <ul>
                          <li>Unlimited Logo Design Concepts</li>
                          <li>+ Social Media Design</li>
                          <li>Mobile Responsive</li>
                          <li>3 Dedicated Designers</li>
                          <li>Icon Design</li>
                          <li>Business Card, Letterhead, Envelope</li>
                          <li>MS Word Letterhead</li>
                          <li>UNLIMITED Pages Website</li>
                          <li>Content Management System (CMS)</li>
                          <li>5 Stock Photos + 3 Banner Designs</li>
                          <li>Complete W3C Certified HTML</li>
                          <li>Complete Deployment</li>
                          <li>Facebook Page Design</li>
                          <li>Twitter Page Design</li>
                          <li>YouTube Page Design</li>
                          <li>All Final File Formats</li>
                          <li>Dedicated Account Manager</li>
                          <li>100% Ownership Rights</li>
                          <li>100% Satisfaction Guarantee</li>
                          <li>100% Unique Design Guarantee</li>
                          <li>100% Money Back Guarantee *</li>
                        </ul>         </div>
                    </div>
                    <a href="./../view-detail.php?package=Startup Combo Package" className="view_detail_btn">View Details</a>
                    <div className="pkg_bottom d-flex justify-content-between align-items-center">
                      <a href="javascript:void(0)" className="order_btn order-package btn-10" data-sku="COMBO_STARTUP_COMBO" data-promotion-id={0} data-price={999} data-price-text="$999.00" data-title="Startup Combo Package" data-package-id={3117}>Buy Now</a>
                      <a href="javascript:;" className="chat pkg_chat_btn chatt"><span>Click here to</span><br />Live Chat</a>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="package_box " data-package-box>
                    <div className="cnrflash">
                      <div className="cnrflash-inner">
                        <img src="images/best-seller.png" alt="*" className="img-fluid" />
                      </div>
                    </div> <div className="cnrflash">
                      <div className="cnrflash-inner">
                        <img src="images/best-seller.png" alt="*" className="img-fluid" />
                      </div>
                    </div>
                    <div className="pkg_top">
                      <div className="pkg_title text-center">
                        <div className="productSku" style={{display: 'none'}}>COMBO_PROFESSIONAL_COMBO</div>
                        <h3>Professional Combo Package</h3>
                      </div>
                      <div className="pkg_pricing_area d-flex justify-content-between align-items-center">
                        <div className="sale_price">
                          <h3>$1399</h3>
                        </div>
                        <div className="cut_price">
                          <h4><span>$2798</span></h4>
                        </div>
                      </div>
                      <div className="short_desc text-center">
                        <p>Suitable for potential super-startups and brand revamps for companies.</p>
                      </div>
                    </div>
                    <div className="pkg_details">
                      <div data-package-scroll>
                        <ul>
                          <li>Unlimited Logo Concepts</li>
                          <li>8 Dedicated Designers</li>
                          <li>Icon Design</li>
                          <li>2 Free Custom Stationary Designs</li>
                          <li>MS Word Letterhead</li>
                          <li>Trifold Brochure Design</li>
                          <li>Presentation Folder Design</li>
                          <li>Conceptual and Dynamic Liquid Website</li>
                          <li>Team of Expert Designers &amp; Developers</li>
                          <li>Mobile Responsive</li>
                          <li>Online Reservation/Appointment Tool (Optional)</li>
                          <li>Custom Forms</li>
                          <li>Lead Capturing Forms (Optional)</li>
                          <li>Newsfeed Integration</li>
                          <li>Social Media Integration</li>
                          <li>Search Engine Submission</li>
                          <li>15 Stock images</li>
                          <li>8 Unique Banner Designs</li>
                          <li>jQuery Sliders</li>
                          <li>Free Google Friendly Sitemap</li>
                          <li>Complete W3C Certified HTML</li>
                          <li>Facebook Page Design</li>
                          <li>Twitter Page Design</li>
                          <li>YouTube Page Design</li>
                          <li>Google+ Page Design</li>
                          <li>All Final File Formats</li>
                          <li>Dedicated Account Manager</li>
                          <li>100% Ownership Rights</li>
                          <li>100% Satisfaction Guarantee</li>
                          <li>100% Unique Design Guarantee</li>
                          <li>100% Money Back Guarantee *</li>
                        </ul>         </div>
                    </div>
                    <a href="./../view-detail.php?package=Professional Combo Package" className="view_detail_btn">View Details</a>
                    <div className="pkg_bottom d-flex justify-content-between align-items-center">
                      <a href="javascript:void(0)" className="order_btn order-package btn-10" data-sku="COMBO_PROFESSIONAL_COMBO" data-promotion-id={0} data-price={1399} data-price-text="$1399.00" data-title="Professional Combo Package" data-package-id={3118}>Buy Now</a>
                      <a href="javascript:;" className="chat pkg_chat_btn chatt"><span>Click here to</span><br />Live Chat</a>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="package_box  active " data-package-box>
                    <div className="cnrflash">
                      <div className="cnrflash-inner">
                        <img src="images/best-seller.png" alt="*" className="img-fluid" />
                      </div>
                    </div> <div className="cnrflash">
                      <div className="cnrflash-inner">
                        <img src="images/best-seller.png" alt="*" className="img-fluid" />
                      </div>
                    </div>
                    <div className="pkg_top">
                      <div className="pkg_title text-center">
                        <div className="productSku" style={{display: 'none'}}>COMBO_CORPORATE_COMBO</div>
                        <h3>Corporate Combo Package</h3>
                      </div>
                      <div className="pkg_pricing_area d-flex justify-content-between align-items-center">
                        <div className="sale_price">
                          <h3>$1999</h3>
                        </div>
                        <div className="cut_price">
                          <h4><span>$3998</span></h4>
                        </div>
                      </div>
                      <div className="short_desc text-center">
                        <p>Suitable for potential super-startups and brand revamps for companies.</p>
                      </div>
                    </div>
                    <div className="pkg_details">
                      <div data-package-scroll>
                        <ul>
                          <li>Unlimited Logo Concepts</li>
                          <li>8 Dedicated Designers</li>
                          <li>Icon Design</li>
                          <li>2 Free Custom Stationary Designs</li>
                          <li>MS Word Letterhead</li>
                          <li>Invoice Design</li>
                          <li>Product Catalog Design</li>
                          <li>Unlimited Pages Website</li>
                          <li>Conceptual and Dynamic Website</li>
                          <li>Content Management System (CMS)</li>
                          <li>Easy Product Search</li>
                          <li>Product Reviews</li>
                          <li>Unlimited Products</li>
                          <li>Unlimited Categories</li>
                          <li>Promotional Product Showcase</li>
                          <li>New Product Showcase</li>
                          <li>Full Shopping Cart Integration</li>
                          <li>Payment Module Integration</li>
                          <li>Sales &amp; Inventory Management</li>
                          <li>Custom Forms</li>
                          <li>Lead Capturing Forms (Optional)</li>
                          <li>Newsfeed Integration</li>
                          <li>Social Media Integration</li>
                          <li>Search Engine Submission</li>
                          <li>Team of Dedicated Designers, Developers and Brand Experts</li>
                          <li>20 Stock images</li>
                          <li>6 Unique Banner Designs</li>
                          <li>jQuery Slider</li>
                          <li>Free Google Friendly Sitemap</li>
                          <li>Complete W3C Certified HTML</li>
                          <li>Facebook Page Design</li>
                          <li>Twitter Page Design</li>
                          <li>YouTube Page Design</li>
                          <li>Google+ Page Design</li>
                          <li>Pinterest Page Design</li>
                          <li>All Final File Formats</li>
                          <li>Dedicated Account Manager</li>
                          <li>100% Ownership Rights</li>
                          <li>100% Satisfaction Guarantee</li>
                          <li>100% Unique Design Guarantee</li>
                          <li>100% Money Back Guarantee *</li>
                        </ul>         </div>
                    </div>
                    <a href="./../view-detail.php?package=Corporate Combo Package" className="view_detail_btn">View Details</a>
                    <div className="pkg_bottom d-flex justify-content-between align-items-center">
                      <a href="javascript:void(0)" className="order_btn order-package btn-10" data-sku="COMBO_CORPORATE_COMBO" data-promotion-id={0} data-price={1999} data-price-text="$1999.00" data-title="Corporate Combo Package" data-package-id={3119}>Buy Now</a>
                      <a href="javascript:;" className="chat pkg_chat_btn chatt"><span>Click here to</span><br />Live Chat</a>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="package_box " data-package-box>
                    <div className="cnrflash">
                      <div className="cnrflash-inner">
                        <img src="images/best-seller.png" alt="*" className="img-fluid" />
                      </div>
                    </div> <div className="cnrflash">
                      <div className="cnrflash-inner">
                        <img src="images/best-seller.png" alt="*" className="img-fluid" />
                      </div>
                    </div>
                    <div className="pkg_top">
                      <div className="pkg_title text-center">
                        <div className="productSku" style={{display: 'none'}}>COMBO_ELITE_COMBO</div>
                        <h3>Elite Combo Package</h3>
                      </div>
                      <div className="pkg_pricing_area d-flex justify-content-between align-items-center">
                        <div className="sale_price">
                          <h3>$2999</h3>
                        </div>
                        <div className="cut_price">
                          <h4><span>$5998</span></h4>
                        </div>
                      </div>
                      <div className="short_desc text-center">
                        <p>Suitable for potential super-startups and brand revamps for companies.</p>
                      </div>
                    </div>
                    <div className="pkg_details">
                      <div data-package-scroll>
                        <ul>
                          <li>Complete Custom Design &amp; Development</li>
                          <li>Client/User Dashboard Area</li>
                          <li>Custom Coding</li>
                          <li>Custom PHP Development</li>
                          <li>Content Management System (CMS)</li>
                          <li>Online Appointment/Scheduling/Online Ordering Integration (Optional)</li>
                          <li>Online Payment Integration (Optional)</li>
                          <li>Multi Lingual (Optional)</li>
                          <li>Custom Dynamic Forms (Optional)</li>
                          <li>Signup Area (For Newsletters, Offers etc.)</li>
                          <li>Search Bar</li>
                          <li>Live Feeds of Social Networks integration (Optional)</li>
                          <li>Mobile Responsive Web</li>
                          <li>Search Engine Submission</li>
                          <li>Module-wise Architecture</li>
                          <li>Extensive Admin Panel</li>
                          <li>Award Winning Team of Expert Designers and Developers</li>
                          <li>Complete Deployment</li>
                          <li>Facebook Page Design</li>
                          <li>Twitter Page Design</li>
                          <li>YouTube Page Design</li>
                          <li>100% Custom Designs - No Templates</li>
                          <li>100% Satisfaction Guarantee</li>
                          <li>100% Unique Design Guarantee</li>
                          <li>100% Money Back Guarantee *</li>
                        </ul>         </div>
                    </div>
                    <a href="./../view-detail.php?package=Elite Combo Package" className="view_detail_btn">View Details</a>
                    <div className="pkg_bottom d-flex justify-content-between align-items-center">
                      <a href="javascript:void(0)" className="order_btn order-package btn-10" data-sku="COMBO_ELITE_COMBO" data-promotion-id={0} data-price={2999} data-price-text="$2999.00" data-title="Elite Combo Package" data-package-id={3120}>Buy Now</a>
                      <a href="javascript:;" className="chat pkg_chat_btn chatt"><span>Click here to</span><br />Live Chat</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-pane fade in show " id="tab_content_1022" role="tabpanel" aria-labelledby="profile-tab">
              <div className="package-carousel">
                <div className="item">
                  <div className="package_box " data-package-box>
                    <div className="cnrflash">
                      <div className="cnrflash-inner">
                        <img src="images/best-seller.png" alt="*" className="img-fluid" />
                      </div>
                    </div> <div className="cnrflash">
                      <div className="cnrflash-inner">
                        <img src="images/best-seller.png" alt="*" className="img-fluid" />
                      </div>
                    </div>
                    <div className="pkg_top">
                      <div className="pkg_title text-center">
                        <div className="productSku" style={{display: 'none'}}>WEB_CONTENT</div>
                        <h3>Web Content</h3>
                      </div>
                      <div className="pkg_pricing_area d-flex justify-content-between align-items-center">
                        <div className="sale_price">
                          <h3>$200</h3>
                        </div>
                        <div className="cut_price">
                          <h4><span>$400</span></h4>
                        </div>
                      </div>
                      <div className="short_desc text-center">
                        <p>Suitable for potential super-startups and brand revamps for companies</p>
                      </div>
                    </div>
                    <div className="pkg_details">
                      <div data-package-scroll>
                        <ul>
                          <li className>Suitable for Personal and Professional Web Projects</li>
                          <li>250 Words per Page</li>
                          <li>Timely Delivery</li>
                          <li>Superior Standard Content</li>
                          <li className>Professional Industry Specific Writers</li>
                          <li>100% Satisfaction Guarantee</li>
                          <li>100% Money Back Guarantee*</li>
                        </ul>         </div>
                    </div>
                    <a href="./../view-detail.php?package=Web Content" className="view_detail_btn">View Details</a>
                    <div className="pkg_bottom d-flex justify-content-between align-items-center">
                      <a href="javascript:void(0)" className="order_btn order-package btn-10" data-sku="WEB_CONTENT" data-promotion-id={0} data-price={200} data-price-text="$200.00" data-title="Web Content" data-package-id={3862}>Buy Now</a>
                      <a href="javascript:;" className="chat pkg_chat_btn chatt"><span>Click here to</span><br />Live Chat</a>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="package_box " data-package-box>
                    <div className="cnrflash">
                      <div className="cnrflash-inner">
                        <img src="images/best-seller.png" alt="*" className="img-fluid" />
                      </div>
                    </div> <div className="cnrflash">
                      <div className="cnrflash-inner">
                        <img src="images/best-seller.png" alt="*" className="img-fluid" />
                      </div>
                    </div>
                    <div className="pkg_top">
                      <div className="pkg_title text-center">
                        <div className="productSku" style={{display: 'none'}}>Article_Writing</div>
                        <h3>Article Writing</h3>
                      </div>
                      <div className="pkg_pricing_area d-flex justify-content-between align-items-center">
                        <div className="sale_price">
                          <h3>$250</h3>
                        </div>
                        <div className="cut_price">
                          <h4><span>$500</span></h4>
                        </div>
                      </div>
                      <div className="short_desc text-center">
                        <p>Suitable for potential super-startups and brand revamps for companies</p>
                      </div>
                    </div>
                    <div className="pkg_details">
                      <div data-package-scroll>
                        <ul>
                          <li className>Suitable for Off-Site and On-Site article posting</li>
                          <li>300 Words per Page</li>
                          <li>Timely Delivery</li>
                          <li>Superior Standard Content</li>
                          <li className>Professional Writers</li>
                          <li>100% Satisfaction Guarantee</li>
                          <li>100% Money Back Guarantee*</li>
                        </ul>         </div>
                    </div>
                    <a href="./../view-detail.php?package=Article Writing" className="view_detail_btn">View Details</a>
                    <div className="pkg_bottom d-flex justify-content-between align-items-center">
                      <a href="javascript:void(0)" className="order_btn order-package btn-10" data-sku="Article_Writing" data-promotion-id={0} data-price={250} data-price-text="$250.00" data-title="Article Writing" data-package-id={3863}>Buy Now</a>
                      <a href="javascript:;" className="chat pkg_chat_btn chatt"><span>Click here to</span><br />Live Chat</a>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="package_box " data-package-box>
                    <div className="cnrflash">
                      <div className="cnrflash-inner">
                        <img src="images/best-seller.png" alt="*" className="img-fluid" />
                      </div>
                    </div> <div className="cnrflash">
                      <div className="cnrflash-inner">
                        <img src="images/best-seller.png" alt="*" className="img-fluid" />
                      </div>
                    </div>
                    <div className="pkg_top">
                      <div className="pkg_title text-center">
                        <div className="productSku" style={{display: 'none'}}>Creative_Writing</div>
                        <h3>Creative Writing</h3>
                      </div>
                      <div className="pkg_pricing_area d-flex justify-content-between align-items-center">
                        <div className="sale_price">
                          <h3>$300</h3>
                        </div>
                        <div className="cut_price">
                          <h4><span>$600</span></h4>
                        </div>
                      </div>
                      <div className="short_desc text-center">
                        <p>Suitable for potential super-startups and brand revamps for companies</p>
                      </div>
                    </div>
                    <div className="pkg_details">
                      <div data-package-scroll>
                        <ul>
                          <li className>Suitable for clients seeking Innovative Content</li>
                          <li>250 Words per Page</li>
                          <li>Timely Delivery</li>
                          <li>Superior Standard Content</li>
                          <li className>Professional Industry Specific Writers</li>
                          <li>100% Satisfaction Guarantee</li>
                          <li>100% Money Back Guarantee*</li>
                        </ul>         </div>
                    </div>
                    <a href="./../view-detail.php?package=Creative Writing" className="view_detail_btn">View Details</a>
                    <div className="pkg_bottom d-flex justify-content-between align-items-center">
                      <a href="javascript:void(0)" className="order_btn order-package btn-10" data-sku="Creative_Writing" data-promotion-id={0} data-price={300} data-price-text="$300.00" data-title="Creative Writing" data-package-id={3864}>Buy Now</a>
                      <a href="javascript:;" className="chat pkg_chat_btn chatt"><span>Click here to</span><br />Live Chat</a>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="package_box " data-package-box>
                    <div className="cnrflash">
                      <div className="cnrflash-inner">
                        <img src="images/best-seller.png" alt="*" className="img-fluid" />
                      </div>
                    </div> <div className="cnrflash">
                      <div className="cnrflash-inner">
                        <img src="images/best-seller.png" alt="*" className="img-fluid" />
                      </div>
                    </div>
                    <div className="pkg_top">
                      <div className="pkg_title text-center">
                        <div className="productSku" style={{display: 'none'}}>Blog_Writing</div>
                        <h3>Blog Writing</h3>
                      </div>
                      <div className="pkg_pricing_area d-flex justify-content-between align-items-center">
                        <div className="sale_price">
                          <h3>$350</h3>
                        </div>
                        <div className="cut_price">
                          <h4><span>$700</span></h4>
                        </div>
                      </div>
                      <div className="short_desc text-center">
                        <p>Suitable for potential super-startups and brand revamps for companies</p>
                      </div>
                    </div>
                    <div className="pkg_details">
                      <div data-package-scroll>
                        <ul>
                          <li className>Suitable for Personal and Corporate Blog Projects</li>
                          <li>250 Words per Page</li>
                          <li>Timely Delivery</li>
                          <li>Superior Standard Content</li>
                          <li className>Professional Writers</li>
                          <li>100% Satisfaction Guarantee</li>
                          <li>100% Money Back Guarantee*</li>
                        </ul>         </div>
                    </div>
                    <a href="./../view-detail.php?package=Blog Writing" className="view_detail_btn">View Details</a>
                    <div className="pkg_bottom d-flex justify-content-between align-items-center">
                      <a href="javascript:void(0)" className="order_btn order-package btn-10" data-sku="Blog_Writing" data-promotion-id={0} data-price={350} data-price-text="$350.00" data-title="Blog Writing" data-package-id={3865}>Buy Now</a>
                      <a href="javascript:;" className="chat pkg_chat_btn chatt"><span>Click here to</span><br />Live Chat</a>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="package_box " data-package-box>
                    <div className="cnrflash">
                      <div className="cnrflash-inner">
                        <img src="images/best-seller.png" alt="*" className="img-fluid" />
                      </div>
                    </div> <div className="cnrflash">
                      <div className="cnrflash-inner">
                        <img src="images/best-seller.png" alt="*" className="img-fluid" />
                      </div>
                    </div>
                    <div className="pkg_top">
                      <div className="pkg_title text-center">
                        <div className="productSku" style={{display: 'none'}}>Infographics_content</div>
                        <h3>Infographics</h3>
                      </div>
                      <div className="pkg_pricing_area d-flex justify-content-between align-items-center">
                        <div className="sale_price">
                          <h3>$750</h3>
                        </div>
                        <div className="cut_price">
                          <h4><span>$1500</span></h4>
                        </div>
                      </div>
                      <div className="short_desc text-center">
                        <p>Suitable for potential super-startups and brand revamps for companies</p>
                      </div>
                    </div>
                    <div className="pkg_details">
                      <div data-package-scroll>
                        <ul>
                          <li className>Suitable for Personal and Corporate Marketing Projects</li>
                          <li>120 Words per Infographic</li>
                          <li>Timely Delivery</li>
                          <li>Superior Standard Content</li>
                          <li className>Professional Writers</li>
                          <li>100% Satisfaction Guarantee</li>
                          <li>100% Money Back Guarantee*</li>
                        </ul>         </div>
                    </div>
                    <a href="./../view-detail.php?package=Infographics" className="view_detail_btn">View Details</a>
                    <div className="pkg_bottom d-flex justify-content-between align-items-center">
                      <a href="javascript:void(0)" className="order_btn order-package btn-10" data-sku="Infographics_content" data-promotion-id={0} data-price={750} data-price-text="$750.00" data-title="Infographics" data-package-id={3866}>Buy Now</a>
                      <a href="javascript:;" className="chat pkg_chat_btn chatt"><span>Click here to</span><br />Live Chat</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-pane fade in show " id="tab_content_1656" role="tabpanel" aria-labelledby="profile-tab">
              <div className="package-carousel">
                <div className="item">
                  <div className="package_box " data-package-box>
                    <div className="cnrflash">
                      <div className="cnrflash-inner">
                        <img src="images/best-seller.png" alt="*" className="img-fluid" />
                      </div>
                    </div> <div className="cnrflash">
                      <div className="cnrflash-inner">
                        <img src="images/best-seller.png" alt="*" className="img-fluid" />
                      </div>
                    </div>
                    <div className="pkg_top">
                      <div className="pkg_title text-center">
                        <div className="productSku" style={{display: 'none'}}>SMM_BUILD_PLAN</div>
                        <h3>Simple Start</h3>
                      </div>
                      <div className="pkg_pricing_area d-flex justify-content-between align-items-center">
                        <div className="sale_price">
                          <h3>$500</h3>
                        </div>
                        <div className="cut_price">
                          <h4><span>$1000</span></h4>
                        </div>
                      </div>
                      <div className="short_desc text-center">
                        <p>Suitable for newly formed organizations or small incubated startups</p>
                      </div>
                    </div>
                    <div className="pkg_details">
                      <div data-package-scroll>
                        <ul>
                          <li><h3>Social Media Posts</h3></li>
                          <li>Total Post 12/3post a week</li>
                          <li>3 Reels Per Month</li>
                          <li>4 Graphic Posts</li>
                          <li>5 Carousel</li>
                          <li>4 Stories+Highlights (Complimentary)</li>
                          <li className="headings"><h3>Social Platforms Supported</h3></li>
                          <li>Facebook</li>
                          <li>Instagram</li>
                          <li><h3>Social Ads Management</h3></li>
                          <li>Facebook and Instagram Ads</li>
                          <li>Total 1 Campaign Per Month</li>
                          <li>05 Adsets Per Month</li>
                          <li><h3>Social Media Management</h3></li>
                          <li>Account Setup/Optimisation (Cover Photo, About Section, Display Picture and more)</li>
                          <li>Monthly Social Posts Scheduling</li>
                          <li>Meta Business Manager Setup</li>
                          <li>CRM Integration (Database Management)</li>
                          <li>Cross-Platform Integration (Facebook, Whatsapp, Instagram, Pixels)</li>
                          <li className="headings"><h3>Total Designated Team Members</h3></li>
                          <li>01 Dedicated Social Media Copywriter</li>
                          <li>01 Dedicated Graphic Designer</li>
                          <li>01 Dedicated Ads Expert</li>
                          <li>01 Dedicated Account Manager</li>
                        </ul>         </div>
                    </div>
                    <a href="./../view-detail.php?package=Simple Start" className="view_detail_btn">View Details</a>
                    <div className="pkg_bottom d-flex justify-content-between align-items-center">
                      <a href="javascript:void(0)" className="order_btn order-package btn-10" data-sku="SMM_BUILD_PLAN" data-promotion-id={0} data-price={500} data-price-text="$500.00" data-title="Simple Start" data-package-id={6524}>Buy Now</a>
                      <a href="javascript:;" className="chat pkg_chat_btn chatt"><span>Click here to</span><br />Live Chat</a>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="package_box " data-package-box>
                    <div className="cnrflash">
                      <div className="cnrflash-inner">
                        <img src="images/best-seller.png" alt="*" className="img-fluid" />
                      </div>
                    </div> <div className="cnrflash">
                      <div className="cnrflash-inner">
                        <img src="images/best-seller.png" alt="*" className="img-fluid" />
                      </div>
                    </div>
                    <div className="pkg_top">
                      <div className="pkg_title text-center">
                        <div className="productSku" style={{display: 'none'}}>SMM_GROW_PLAN</div>
                        <h3>Essentials</h3>
                      </div>
                      <div className="pkg_pricing_area d-flex justify-content-between align-items-center">
                        <div className="sale_price">
                          <h3>$1000</h3>
                        </div>
                        <div className="cut_price">
                          <h4><span>$2000</span></h4>
                        </div>
                      </div>
                      <div className="short_desc text-center">
                        <p>For medium-sized stable organizations looking to climb up the corporate ladder.</p>
                      </div>
                    </div>
                    <div className="pkg_details">
                      <div data-package-scroll>
                        <ul>
                          <li><h3>Social Media Posts</h3></li>
                          <li>Total Post 16/4post a week</li>
                          <li>4 Reels Per Month </li>
                          <li>6 Graphic Posts</li>
                          <li>6 Carousel </li>
                          <li>6 Stories+Highlights (Complimentary)</li>
                          <li className="headings"><h3>Social Platforms Supported </h3></li>
                          <li>Facebook </li>
                          <li>Instagram</li>
                          <li>Tiktok/Linkedin</li>
                          <li className="headings"><h3>Social Ads Management</h3></li>
                          <li>Campaigns on FB, Insta and TikTok/Linkedin</li>
                          <li>Total 3 Campaign Per Month</li>
                          <li>Unlimited Adsets Per Month</li>
                          <li>Detailed Campaign Monitoring</li>
                          <li>All Ad Objectives Supported</li>
                          <li><h3>Social Media Management</h3></li>
                          <li>Account Setup/Optimisation  (Cover Photo, About Section, Display Picture, and more)</li>
                          <li>Monthly Social Posts Scheduling</li>
                          <li>Meta Business Manager Setup</li>
                          <li>TikTok/Linkedin Ads Account Setup</li>
                          <li>CRM Integration (Database Management)</li>
                          <li>Cross-Platform Integration (Facebook, Whatsapp, Instagram, Pixels)</li>
                          <li><h3>Total Designated Team Members</h3></li>
                          <li>02 Dedicated Social Media Copywriter</li>
                          <li>02 Dedicated Graphic Designer</li>
                          <li>02 Dedicated Ads Expert</li>
                          <li>01 Dedicated Account Manager</li>
                        </ul>         </div>
                    </div>
                    <a href="./../view-detail.php?package=Essentials" className="view_detail_btn">View Details</a>
                    <div className="pkg_bottom d-flex justify-content-between align-items-center">
                      <a href="javascript:void(0)" className="order_btn order-package btn-10" data-sku="SMM_GROW_PLAN" data-promotion-id={0} data-price={1000} data-price-text="$1000.00" data-title="Essentials" data-package-id={6525}>Buy Now</a>
                      <a href="javascript:;" className="chat pkg_chat_btn chatt"><span>Click here to</span><br />Live Chat</a>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="package_box " data-package-box>
                    <div className="cnrflash">
                      <div className="cnrflash-inner">
                        <img src="images/best-seller.png" alt="*" className="img-fluid" />
                      </div>
                    </div> <div className="cnrflash">
                      <div className="cnrflash-inner">
                        <img src="images/best-seller.png" alt="*" className="img-fluid" />
                      </div>
                    </div>
                    <div className="pkg_top">
                      <div className="pkg_title text-center">
                        <div className="productSku" style={{display: 'none'}}>SMM_ACCELERATE_PLANE</div>
                        <h3>Business Plus</h3>
                      </div>
                      <div className="pkg_pricing_area d-flex justify-content-between align-items-center">
                        <div className="sale_price">
                          <h3>$2100</h3>
                        </div>
                        <div className="cut_price">
                          <h4><span>$4200</span></h4>
                        </div>
                      </div>
                      <div className="short_desc text-center">
                        <p>For pre-established businesses that aim to maintain their presence and claim the crown.</p>
                      </div>
                    </div>
                    <div className="pkg_details">
                      <div data-package-scroll>
                        <ul>
                          <li><h3>Social Media Posts</h3></li>
                          <li>Total Post 24/6post a week</li>
                          <li>6 Reels Per Month </li>
                          <li>9 Graphic Posts</li>
                          <li>9 Carousel </li>
                          <li>6 Stories+Highlights (Complimentary)</li>
                          <li><h3>Social Platforms Supported </h3></li>
                          <li>Facebook </li>
                          <li>Instagram</li>
                          <li>Tiktok/Linkedin</li>
                          <li>Twitter</li>
                          <li>Linkedin</li>
                          <li>Pinterest</li>
                          <li>Snapchat</li>
                          <li><h3>Social Ads Management</h3></li>
                          <li>Unlimited Campaigns on FB, Insta, Tiktok, Linkedin, Snapchat, Pinterest and Twitter</li>
                          <li>Unlimited Campaigns Per Month</li>
                          <li>Unlimited Adsets Per Month</li>
                          <li>Detailed Campaign Monitoring</li>
                          <li>All Ad Objectives Supported</li>
                          <li><h3>Social Media Management</h3></li>
                          <li>Account Setup/Optimisation  (Cover Photo, About Section, Display Picture, and more)</li>
                          <li>Monthly Social Posts Scheduling</li>
                          <li>Meta Business Manager Setup</li>
                          <li>All Ads Account Setup (FB, Insta, TikTok, Linkedin, Snapchat, Pinterest, and Twitter</li>
                          <li>CRM Integration (Database Management)</li>
                          <li>Cross-Platform Integration (Facebook, Whatsapp, Instagram, Pixels)</li>
                          <li>Social Community Management (Query + Comment Responses)</li>
                          <li><h3>Total Designated Team Members</h3></li>
                          <li>03 Dedicated Social Media Copywriter</li>
                          <li>03 Dedicated Graphic Designer</li>
                          <li>03 Dedicated Ads Expert</li>
                          <li>01 Dedicated Account Manager</li>
                        </ul>         </div>
                    </div>
                    <a href="./../view-detail.php?package=Business Plus" className="view_detail_btn">View Details</a>
                    <div className="pkg_bottom d-flex justify-content-between align-items-center">
                      <a href="javascript:void(0)" className="order_btn order-package btn-10" data-sku="SMM_ACCELERATE_PLANE" data-promotion-id={0} data-price={2100} data-price-text="$2100.00" data-title="Business Plus" data-package-id={6526}>Buy Now</a>
                      <a href="javascript:;" className="chat pkg_chat_btn chatt"><span>Click here to</span><br />Live Chat</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      </>
    );

}