import Header from
  '../Components/Header';
import Footer from
  '../Components/Footer';
import BannerSlider from
  '../Components/BannerSlider';
import History from
  '../Components/History';
import PortfolioCom from
  '../Components/PortfolioCom';
import Packages from
'../Components/Packages';
import Offer from
  '../Components/Offer';
import Cta from
  '../Components/Cta';
import GetQuoteOne from
  '../Components/GetQuoteOne';
import GetQuoteTwo from
  '../Components/GetQuoteTwo';




export default function Home() {
  return (
    <>
      <Header />

      <section className="main-banner">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-sm-12 pr-0">
              <div className="banner-text">
                <p>Providing Complete Designs Services</p>
                <h2>We Are A <span>Corporate<br /> Web Design Company</span></h2>
                <h3>Dedicated For Your Brand And Identity</h3>
                <ul>
                  <li><i className="fas fa-caret-right" /> Let us aid you in generating more leads</li>
                  <li><i className="fas fa-caret-right" /> Increase your Return on investment by huge margins</li>
                  <li><i className="fas fa-caret-right" /> Get noticed by your audience at the first glance</li>
                </ul>
                <img className="lazy img-fluid" src="images/social-logo.webp" alt="*" />
              </div>
            </div>
            <div className="col-lg-6 col-sm-12 banner-img-side">
              <img className="lazy img-fluid" src="images/banner-side.webp" alt="*" />
            </div>
          </div>
        </div>
      </section>

      <BannerSlider />

      <PortfolioCom />

      <History />

      <Offer />

      <Cta />

      <Packages />

      <GetQuoteOne />

      <GetQuoteTwo />

      <Footer />
    </>

  )
}