import Header from 
'../Components/Header';
import Footer from 
'../Components/Footer';
import Packages from 
'../Components/Packages';
import Portfolio from 
'../Components/PortfolioCom';
import Cta from 
'../Components/Cta';
import Testimonial from '../Components/Testimonial';
import GetQuoteThree from '../Components/GetQuoteThree';

// import { useEffect } from 'react';



export default function Smm(){

//   useEffect(() => {

//     window.$(".tablist ul li a").click((function() {
//     var t = window.$(this).attr("data-target");
//     return window.$(".tablist ul li a").removeClass("active"),
//      window.$(this).addClass("active"),
//       window.$("#" + t).fadeIn("slow").siblings(".tabbox").hide(), !1
// }))

// },[]);

	return(
    <> 
    <Header />

        <section className="main-banner inner-banner" style={{backgroundImage: 'url(images/3d-animation.webp)', height: '560px'}}>
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-sm-12 pr-0">
                <div className="banner-text" data-aos="fade-right" data-aos-duration={1500}>
                  <h4>ROI Driven</h4>
                  <h2><span>SMM Services <br /> To Boost Your Rankings </span></h2>
                  <p>Design Canvas Pro can help you gain large number of visitors and grab the attention of your
                    target audience.</p>
                  <p>Get probable leads and grab attention of your audience that is available on all the major
                    Social Media Platforms.</p>
                  <img src="images/bark-logo.webp" alt="" className="img-fluid" />
                </div>
              </div>
              <div className="col-lg-6 col-sm-12 banner-img jvi">
                <img src="images/smm.webp" alt="" className="img-fluid" />
              </div>
            </div>
          </div>
        </section>

        <section className="main-award">
          <div className="container">
            <h3>Let’s Envision and Build together</h3>
            <div className="row justify-content-center">
              <div className="col-lg-2 col-md-2 col-sm-2">
                <div className="award-box">
                  <img src="images/smm-award-1.webp" alt="" className="img-fluid" />
                </div>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-2">
                <div className="award-box">
                  <img src="images/smm-award-2.webp" alt="" className="img-fluid" />
                </div>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-2">
                <div className="award-box">
                  <img src="images/smm-award-3.webp" alt="" className="img-fluid" />
                </div>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-2">
                <div className="award-box">
                  <img src="images/smm-award-4.webp" alt="" className="img-fluid" />
                </div>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-2">
                <div className="award-box">
                  <img src="images/smm-award-5.webp" alt="" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="best-static-wrap">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <div className="best-static-img" data-aos="fade-right" data-aos-duration={1500}>
                  <img src="images/71.webp" alt="" className="img-fluid" />
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="best-static-text" data-aos="fade-left" data-aos-duration={1500}>
                  <h3>Best Static Web Design &amp; <br />Development Company </h3>
                  <p>We at Design Canvas Pro have been serving people for several years and have been
                    excelling in our field by providing design-driven solutions and innovative brand ideas. We
                    have effectively helped and supported brands in arriving at their most extreme potential and
                    boosting their latent capacity. We are the best website design agency and we are situated in
                    California, USA. Our fulfilled customer base is a living confirmation of that reality.</p>
                  <h2>Following are few of the advantages of having a static website</h2>
                  <ul>
                    <li><i className="fas fa-caret-right" /> Cost savings from utilizing cloud storage</li>
                    <li><i className="fas fa-caret-right" /> Provides improved security</li>
                    <li><i className="fas fa-caret-right" /> Fewer or no dependencies on systems</li>
                    <li><i className="fas fa-caret-right" /> Improved performance for end users</li>
                  </ul>
                  <div className="best-static-btn">
                    <button className="btn btn-round btn-white btn-tup chatt">Sign Up Now To <strong>Avail 30%
                        Discount</strong></button>
                    <a href="javascript:;" data-toggle="modal" data-target=".bd-example-modal-lg" className="discount-btn">AVAIL DISCOUNT</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

     <Cta/>

     <Portfolio/>

     <GetQuoteThree/>

     <Packages/>

      <section className="main-resource">
        <div className="container">
          <div className="heading">
            <h2>How We </h2>
            <h3>Allocate Our Resources </h3>
            <p>We value your project needs and time limits! That is why we always assign your website project to our
              in-house team of professionals with rich experience in working with the similar projects. You can always
              expect seamless cooperation and timely releases from us.</p>
          </div>
          <div className="row no-gutters resource-responsive-slider" data-aos="zoom-out" data-aos-duration={1500}>
            <div className="col-lg-3 col-md-12">
              <div className="resource-box">
                <img src="images/resource-icon-8.webp" alt="" className="img-fluid" />
                <h3>Audience Research and Analysis</h3>
                <p>We dive into comprehensive audience research, analyzing demographics, behaviors, and preferences
                  to tailor your social media strategy.</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-12">
              <div className="resource-box">
                <img src="images/resource-icon-1.webp" alt="" className="img-fluid" />
                <h3>Content Creation and Curation</h3>
                <p> Our creative team generates and curates content that resonates with your audience, including
                  eye-catching visuals and compelling captions.</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-12">
              <div className="resource-box">
                <img src="images/resource-icon-2.webp" alt="" className="img-fluid" />
                <h3> Scheduling and Posting</h3>
                <p> We schedule posts strategically to maximize engagement, ensuring your content reaches your
                  audience at the right moments.</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-12">
              <div className="resource-box">
                <img src="images/resource-icon-3.webp" alt="" className="img-fluid" />
                <h3>Community Engagement</h3>
                <p>We actively engage with your community, responding to comments, messages, and fostering a sense
                  of community around your brand.</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-12">
              <div className="resource-box">
                <img src="images/resource-icon-4.webp" alt="" className="img-fluid" />
                <h3>Analytics and Performance Monitoring</h3>
                <p>We constantly monitor social media performance, using analytics to track key metrics and refine
                  our strategy for better results.</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-12">
              <div className="resource-box">
                <img src="images/resource-icon-5.webp" alt="" className="img-fluid" />
                <h3>Trend Analysis and Content Trends</h3>
                <p>We keep a keen eye on industry trends and adapt your content strategy to stay ahead of the curve.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-12">
              <div className="resource-box">
                <img src="images/resource-icon-7.webp" alt="" className="img-fluid" />
                <h3>Advertising and Promotion</h3>
                <p>Leveraging paid advertising, we amplify your social media presence to reach a wider, targeted
                  audience.</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-12">
              <div className="resource-box">
                <img src="images/resource-icon-6.webp" alt="" className="img-fluid" />
                <h3>Reporting and Strategy Optimization</h3>
                <p>Regular reporting and analysis inform strategy adjustments, helping us optimize your social media
                  approach for continued growth.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

    <Testimonial/>
     

    <Footer />
    </>

		)
}