


export default function WebDesignProcess() {
    return (

      <section className="inner-process">
        <div className="container">
          <div className="heading" data-aos="fade-down" data-aos-duration={1500}>
            <h3>Our Flawless <span>Web Design</span> Process </h3>
            <p>Our process is known to be smooth, transparent and always in the benefit for our customers</p>
          </div>
          <div className="inner-process-wrap">
            <div className="row">
              <div className="col-lg-4 col-md-12">
                <div className="inner-process-box" data-aos="fade-right" data-aos-duration={1500} data-aos-delay={400}>
                  <div className="process-img">
                    <img src="images/process-1.webp" alt="" className="img-fluid" />
                  </div>
                  <h3>Research</h3>
                  <p>We initiate our work process with solid research, which clears our vision about customers to need and requirements. We plan how your website will look and what it offers to the audience.</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-12">
                <div className="inner-process-box" data-aos="fade-right" data-aos-duration={1500} data-aos-delay={600}>
                  <div className="process-img">
                    <img src="images/process-2.webp" alt="" className="img-fluid" />
                  </div>
                  <h3>Idea</h3>
                  <p>Before moving further, we prefer to polish initial concepts; we create plenty of rough ideas and enhance them through practical brainstorming tools.</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-12">
                <div className="inner-process-box" data-aos="fade-right" data-aos-duration={1500} data-aos-delay={800}>
                  <div className="process-img">
                    <img src="images/process-3.webp" alt="" className="img-fluid" />
                  </div>
                  <h3>Execution</h3>
                  <p>We execute your idea accordingly and uniquely turn them into a perfect web design for your brand. We have specialists that upkeep the most about what you think.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
     
   );
}