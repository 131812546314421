

export default function ComboPackages() {
  return (

    <section className="main-award">
      <div className="container">
        <h3>Let’s Envision and Build together</h3>
        <div className="row justify-content-center">
          <div className="col-lg-2 col-md-2 col-sm-2">
            <div className="award-box">
              <img src="images/award-icon-1.webp" alt="" className="img-fluid" />
              <p>Wordpress</p>
            </div>
          </div>
          <div className="col-lg-2 col-md-2 col-sm-2">
            <div className="award-box">
              <img src="images/award-icon-2.webp" alt="" className="img-fluid" />
              <p>Opencart</p>
            </div>
          </div>
          <div className="col-lg-2 col-md-2 col-sm-2">
            <div className="award-box">
              <img src="images/award-icon-3.webp" alt="" className="img-fluid" />
              <p>Woo Commerce</p>
            </div>
          </div>
          <div className="col-lg-2 col-md-2 col-sm-2">
            <div className="award-box">
              <img src="images/award-icon-4.webp" alt="" className="img-fluid" />
              <p>PHP</p>
            </div>
          </div>
          <div className="col-lg-2 col-md-2 col-sm-2">
            <div className="award-box">
              <img src="images/award-icon-5.webp" alt="" className="img-fluid" />
              <p>Custom Solution</p>
            </div>
          </div>
        </div>
      </div>
    </section>

  );
}