import Header from
    '../Components/Header';
import Footer from
    '../Components/Footer';
import Cta from
    '../Components/Cta';
import Packages from '../Components/Packages';
import Testimonial from '../Components/Testimonial';
// import { useEffect } from 'react';



export default function Seo() {

    // useEffect(() => {

    //         window.$(".tablist ul li a").click((function() {
    //         var t = window.$(this).attr("data-target");
    //         return window.$(".tablist ul li a").removeClass("active"),
    //          window.$(this).addClass("active"),
    //           window.$("#" + t).fadeIn("slow").siblings(".tabbox").hide(), !1
    //     }))
     
    //  },[]);

    return (
        <>
            <Header />
           
       
        <section className="main-banner inner-banner" style={{backgroundImage: 'url(images/3d-animation.webp)', height: '560px'}}>
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-sm-12 pr-0">
                <div className="banner-text" data-aos="fade-right" data-aos-duration={1500}>
                  <h4>Results-driven SEO Techniques</h4>
                  <h2><span>Be On Top Of All <br />Search Engines </span></h2>
                  <p>The SEO teams at the Design Canvas Pro are aware of all the ins and outs of the latest and proven
                    SEO Techniques. We makes sure that your website starts getting leads on organic basis by making
                    you reach the top of all major search engines.</p>
                  <img src="images/bark-logo.webp" alt="" className="img-fluid" />
                </div>
              </div>
              <div className="col-lg-6 col-sm-12 banner-img jvi">
                <img src="images/seo.webp" alt="" className="img-fluid" />
              </div>
            </div>
          </div>
        </section>

        <section className="main-award">
          <div className="container">
            <h3>Let’s Envision and Build together</h3>
            <div className="row justify-content-center">
              <div className="col-lg-2 col-md-2 col-sm-2">
                <div className="award-box">
                  <img src="images/seo-award-1.webp" alt="" className="img-fluid" />
                </div>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-2">
                <div className="award-box">
                  <img src="images/seo-award-2.webp" alt="" className="img-fluid" />
                </div>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-2">
                <div className="award-box">
                  <img src="images/seo-award-3.webp" alt="" className="img-fluid" />
                </div>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-2">
                <div className="award-box">
                  <img src="images/seo-award-4.webp" alt="" className="img-fluid" />
                </div>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-2">
                <div className="award-box">
                  <img src="images/seo-award-5.webp" alt="" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="best-static-wrap">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <div className="best-static-img" data-aos="fade-right" data-aos-duration={1500}>
                  <img src="images/61.webp" alt="" className="img-fluid" />
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="best-static-text" data-aos="fade-left" data-aos-duration={1500}>
                  <h3>Best SEO Services Provides <br />in San Diego</h3>
                  <p>We at Design Canvas Pro have been serving clients for several years and have been excelling in
                    our field by providing design-driven solutions and innovative brand ideas. We have effectively
                    helped and supported brands in arriving at their most extreme potential and boosting their
                    latent capacity. We are the Best Marketing and Design agency and we are situated in San Diego.
                    Our fulfilled customer base is a living confirmation of that reality.</p>
                  <h2>Following are few of the advantages of hiring us for your SEO needs</h2>
                  <ul>
                    <li><i className="fas fa-caret-right" /> Industry experienced SEO teams</li>
                    <li><i className="fas fa-caret-right" /> 100% Results Guaranteed</li>
                    <li><i className="fas fa-caret-right" /> Competitor Analysis</li>
                    <li><i className="fas fa-caret-right" /> Improved Organic Traffic</li>
                  </ul>
                  <div className="best-static-btn">
                    <button className="btn btn-round btn-white btn-tup chatt">Sign Up Now To <strong>Avail 30%
                        Discount</strong></button>
                    <a href="javascript:;" data-toggle="modal" data-target=".bd-example-modal-lg" className="discount-btn">AVAIL DISCOUNT</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
 
     <Cta/>
     <Packages/>


     <section className="main-resource">
        <div className="container">
          <div className="heading">
            <h2>How We </h2>
            <h3>Allocate Our Resources </h3>
            <p>We value your project needs and time limits! That is why we always assign your website project to our
              in-house team of professionals with rich experience in working with the similar projects. You can always
              expect seamless cooperation and timely releases from us.</p>
          </div>
          <div className="row no-gutters resource-responsive-slider" data-aos="zoom-out" data-aos-duration={1500}>
            <div className="col-lg-3 col-md-12">
              <div className="resource-box">
                <h3>RESEARCH</h3>
                <p>Once we start off your SEO, your dedicated SEO expert starts by taking a deep dive into your
                  industry, your company, your rivals, and your website.</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-12">
              <div className="resource-box">
                <h3>OPTIMIZE</h3>
                <p>Our team then starts doing all the minor and major changes to your website to help the search
                  engines (Google, Yahoo, etc.) who you are and what you do.</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-12">
              <div className="resource-box">
                <h3>OFF PAGE SEO</h3>
                <p>Our SEO experts then start with the Off Page SEO by Building links to your website.</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-12">
              <div className="resource-box">
                <h3>RESULTS</h3>
                <p>Getting qualified and relevant traffic for your website is just the beginning. </p>
              </div>
            </div>
          </div>
        </div>
      </section>


     <Testimonial/>

    <Footer />
    </>

		)
}