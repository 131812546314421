export default function GetQuoteThree() {
    return (

        <section className="main-work-detail">
        <div className="container">
          <div className="work-detail" data-aos="zoom-in" data-aos-duration={1500}>
            <h2>We Are The Hybrid of Creativity, Innovation,</h2>
            <h3><span>Hard Work</span> And Dedication</h3>
            <p>Contact Us Now To Get The Best Services At The Best Prices.</p>
            <div className="package-btn-wrap">
              <a className="home-btn left-right-mov" href="javascript:;" data-toggle="modal" data-target=".bd-example-modal-lg">Get a Quote</a>
            </div>
          </div>
        </div>
      </section>
    );
}