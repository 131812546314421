import PortfolioCom from
  '../Components/PortfolioCom';
  import Header from
  '../Components/Header';
import Footer from
  '../Components/Footer';

export default function Portfolio(){
	return(
    <> 

    <Header />
    
    <section className="main-banner inner-banner" style={{backgroundImage: 'url(images/portfolio.webp)', height: '560px'}}>
        <div className="container">
          <div className="row ">
            <div className="col-lg-12 col-sm-12 pr-0">
              <div className="banner-text">
                <h4>Quality Enriched Portfolio</h4>
                <h2><span>The Best</span> Of Our <br />Work</h2>
                <p>Our aim is to make the first impression a great one in<br /> order to get on top of the Best Quality Designs spot</p>
              </div>
            </div>
          </div>
        </div>
    </section>

    <PortfolioCom/>

    <Footer />
    </>
		)
}