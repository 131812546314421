

export default function Contact() {
  return (

    <section className="main-cta">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-4">
            <div className="cta-text">
              <h3>AVAIL WEB DESIGN </h3>
              <h2 className="left-right-mov">PACKAGES AT </h2>
            </div>
          </div>
          <div className="col-lg-4 col-md-2">
            <div className="cta-img">
              <img className="lazy img-fluid" src="images/discount50.png" alt="*" />
            </div>
          </div>
          <div className="col-lg-4 col-md-5 text-right">
            <div className="cta-contact">
              <h3><span>Get In Touch</span> With Our Support Agent or</h3>
              <div className="cta-btn">
                <a className="home-btn left-right-mov" href="about-us">About Us</a>
                <a href="javascript:;" className="chatt">
                  <h4><span>Click here to</span><br />Live Chat</h4>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  );
}