import Header from 
'../Components/Header';
import Footer from 
'../Components/Footer';
import Packages from 
'../Components/Packages';
import Cta from 
'../Components/Cta';
import Testimonial from '../Components/Testimonial';
import GetQuoteThree from '../Components/GetQuoteThree';
// import { useEffect } from 'react';



export default function Animation(){

//   useEffect(() => {

//     window.$(".tablist ul li a").click((function() {
//     var t = window.$(this).attr("data-target");
//     return window.$(".tablist ul li a").removeClass("active"),
//      window.$(this).addClass("active"),
//       window.$("#" + t).fadeIn("slow").siblings(".tabbox").hide(), !1
// }))

// },[]);

	return(
    <> 
    <Header />


        <section className="main-banner inner-banner" style={{backgroundImage: 'url(images/2d-animation.webp)', height: '560px'}}>
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-sm-12 pr-0">
                <div className="banner-text" data-aos="fade-right" data-aos-duration={1500}>
                  <h4>We design and</h4>
                  <h2><span>Animate Videos</span>  That<br /> Attracts Millions </h2>
                  <p>For over a decade we have been serving our clients from all over the world, delivering them with nothing but the best animation services there is requires by their business. </p>
                  <p> Our professionals deliver 2D animations that are of the best quality whether they are business or marketing related, for college or for other purposes.</p>
                  <img src="images/bark-logo.webp" alt="" className="img-fluid" />
                </div>
              </div>
              <div className="col-lg-6 col-sm-12 banner-img jvi">
                <img src="images/2d-animation1.webp" alt="" className="img-fluid" />
              </div>
            </div>
          </div>
        </section>
        
        <section className="main-award">
          <div className="container">
            <h3>Let’s Envision and Build together</h3>
            <div className="row justify-content-center">
              <div className="col-lg-3 col-md-3 col-sm-4">
                <div className="award-box">
                  <img src="images/award-1.webp" alt="" className="img-fluid" />
                  <p>Fastcompany 50 - <br />Most Innovative Companies</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4">
                <div className="award-box">
                  <img src="images/award-2.webp" alt="" className="img-fluid" />
                  <p>2011 OnMobile 100 <br />Top Private Companies</p>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-4">
                <div className="award-box">
                  <img src="images/award-3.webp" alt="" className="img-fluid" />
                  <p>Always On Global <br />Top 250 Company</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="best-static-wrap">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <div className="best-static-img" data-aos="fade-right" data-aos-duration={1500}>
                  <img src="images/1stat.webp" alt="" className="img-fluid" />
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="best-static-text" data-aos="fade-left" data-aos-duration={1500}>
                  <h3>2D Animations That Are <br />Reality Based &amp; Engaging </h3>
                  <p>We’re a full service Web design, Animation and Marketing agency. We work with companies while turning their vision into a reality in the most fun and engaging way. We have effectively helped and supported brands in arriving at their most extreme potential and boosting their latent capacity. We are the best website design agency situated in Austin, Texas. Our fulfilled customer base is a living confirmation of that reality.</p>
                  <h2>Following are few of the advantages of hiring us for your 2D animation</h2>
                  <ul>
                    <li><i className="fas fa-caret-right" />	Self-explanatory Animations</li>
                    <li><i className="fas fa-caret-right" />	High-End Graphics</li>
                    <li><i className="fas fa-caret-right" />	Engaging and interactive features</li>
                    <li><i className="fas fa-caret-right" />	Cost-friendly animation packages</li>
                  </ul>
                  <div className="best-static-btn">
                    <button className="btn btn-round btn-white btn-tup chat">Sign Up Now To <strong>Avail 30% Discount</strong></button>
                    <a href="javascript:;" data-toggle="modal" data-target=".bd-example-modal-lg" className="discount-btn">AVAIL DISCOUNT</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
    

     <Cta/>

     <GetQuoteThree/>

     <Packages/>

     <section className="main-resource">
        <div className="container">
          <div className="heading">
            <h2>How We </h2>
            <h3>Allocate Our Resources</h3>
            <p>We value your project needs and time limits! That is why we always assign your website project to our in-house team of professionals with rich experience in working with the similar projects. You can always expect seamless cooperation and timely releases from us.</p>
          </div>
          <div className="row no-gutters resource-responsive-slider" data-aos="zoom-out" data-aos-duration={1500}>
            <div className="col-lg-3 col-md-12">
              <div className="resource-box">
                <img src="images/resource-icon-1.webp" alt="" className="img-fluid" />
                <h3>Concept Development</h3>
                <p>We start by crafting the concept, laying the foundation for your video. This stage involves brainstorming and outlining the narrative, style, and visual elements.</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-12">
              <div className="resource-box">
                <img src="images/resource-icon-2.webp" alt="" className="img-fluid" />
                <h3>Animation Production</h3>
                <p>Skilled animators bring your concept to life through animation production. They create engaging visuals, transitions, and effects, ensuring your message is conveyed effectively.</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-12">
              <div className="resource-box">
                <img src="images/resource-icon-3.webp" alt="" className="img-fluid" />
                <h3> Editing and Post-Production</h3>
                <p>Post-production involves meticulous editing, adding sound effects, music, and refining animations for a seamless and captivating video experience.</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-12">
              <div className="resource-box">
                <img src="images/resource-icon-5.webp" alt="" className="img-fluid" />
                <h3>Review and Final Delivery</h3>
                <p>TWe conduct thorough reviews to ensure the video meets your expectations and quality standards. Once approved, your video is ready for delivery, whether it's for online sharing or broadcast.</p>
              </div>
            </div>
          </div>
        </div>
      </section>




    <Testimonial/>
     

    <Footer />
    </>

		)
}