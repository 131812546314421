

export default function GetQuoteTwo(){
    return (

      <section className="main-industry">
      <div className="container">
        <div className="row justify-content-end">
          <div className="col-lg-6 col-md-12 industry-img difclas">
            <img className="lazy img-fluid" src="images/industry-img.webp" alt="*" />
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="industry-text">
              <h2>Wondering…<br /> <span>Design Canvas Pro</span><br />Agency Serve your Specific Industry?</h2>
              <p>Well, most certainly YES. Our designers and developers can help you create web designs, SEO, social media
                and digital media marketing campaigns, illustrations, motion graphics, and many more that are aligned with
                your industry trends and modalities.</p>
              <div className="industry-btn-wrap">
                <a className="home-btn left-right-mov" href="javascript:;" data-toggle="modal" data-target=".bd-example-modal-lg">Get a Quote</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
      
    );
}