import Header from
    '../Components/Header';
import Footer from
    '../Components/Footer';



export default function Privacy() {

    return (
        <>
            <Header />

            <section className="main-privacy">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12 privacy">
                            <div className="term-text">
                                <h3><br />Our Privacy Policy <br />
                                    <br />
                                    Objective test
                                </h3>
                                <p>
                                    designcanvaspro.com is committed to protecting your privacy when you visit our Site(s). We
                                    want you to know what Information we collect, how we use it, and how you can request that
                                    this Information be updated or corrected.&nbsp;
                                    <br />
                                    <br /> The organization reserves the right to amend or otherwise modify this Privacy Policy at
                                    any time, so please re-visit this page periodically to check for updates. The date of last
                                    revision is shown at the top of this document.
                                </p>
                                <h3>Revision Policy</h3>
                                <p>Revisions are contingent on the package selected. Clients can approach us for unlimited free
                                    amendments and we will revise their design with no extra charges, given that the design and
                                    concept remain intact. Revision turnaround time will be 48 hours.</p>
                                <h3>Refund Policy</h3>
                                <p><strong>designcanvaspro.com refund policy is not applicable when:</strong></p>
                                <ul>
                                    <li>
                                        <p>You choose a discounted coupon</p>
                                    </li>
                                    <li>
                                        <p>You choose a promo/special service package</p>
                                    </li>
                                    <li>
                                        <p>You have approved the initial draft</p>
                                    </li>
                                    <li>
                                        <p>You have requested revisions</p>
                                    </li>
                                    <li>
                                        <p>You have canceled the order due to non-related reasons in regard to the
                                            designcanvaspro.com and its policies</p>
                                    </li>
                                    <li>
                                        <p>You have not contacted designcanvaspro.com for more than 2 weeks since the project
                                            initiated</p>
                                    </li>
                                    <li>
                                        <p>You have violated designcanvaspro.com terms &amp; conditions and privacy policy</p>
                                    </li>
                                    <li>
                                        <p>You have approached another service provider or freelancer for the same project
                                            assigned to designcanvaspro.com</p>
                                    </li>
                                    <li>
                                        <p>You have provided an incomplete project brief</p>
                                    </li>
                                    <li>
                                        <p>You have demanded a change in the any draft</p>
                                    </li>
                                    <li>
                                        <p>You have exceeded from the maximum time allowed to ‘request for refund’ and make
                                            claims</p>
                                    </li>
                                    <li>
                                        <p>You have other reasons such as change of mind, dispute with your partners, or other
                                            similar reasons</p>
                                    </li>
                                    <li>
                                        <p>You have approved the deliverables</p>
                                    </li>
                                    <li>
                                        <p>You have accepted multiple revisions for any service</p>
                                    </li>
                                    <li>
                                        <p>You have ordered a bundle service and are dissatisfied with any specific service,
                                            refund can only be claimed against that specific service and ‘not’ the complete set
                                            of bundle services</p>
                                    </li>
                                    <li>
                                        <p>You have failed to provide the initial project brief within 10 days of order
                                            placement</p>
                                    </li>
                                </ul>
                                <h3>How to Claim Your Refund</h3>
                                <p>To ensure that your refund request is processed effectively and is approved, please make sure
                                    that you meet the following requirements.</p>
                                <p>Specify your concern and claim your refund through any of the following three modes:-</p>
                                <p>Toll-free # <a href="tel:+1-(234) 123-4567">(+1-(234) 123-4567)</a></p>
                                <p><a href="javascript:;" className="chat">Live Chat</a></p>
                                <p>Email <a href="mailto:info@designcanvaspro.com">(info@designcanvaspro.com)</a></p>
                                <p>We will try to resolve your query and concern in light of our revision policy immediately or
                                    else will email you a refund request approval from our refund department. After the refund,
                                    the rights to your draft would be transferred to Design Canvas Pro and you would not
                                    legally be allowed to use any version of the draft sent by the company.</p>
                                <p> Since the rights to the draft would now be transferred to Design Canvas Pro, you concur
                                    that you will have no right (immediate or circuitous) to use any reaction or other
                                    substance, work item or media, nor will you have any ownership interest for or to the same.
                                </p>
                                <p>Working in a joint effort with the Government Copyright Agencies Design Canvas Pro would
                                    share Copyright Acquisition data for the refunded outlines that would confine the
                                    re-utilization of the drafts as original manuscripts in the future.</p>
                                <p>If you have any questions or concerns about our Refund Policy, please get in touch with us by
                                    clicking here (info@designcanvaspro.com).</p>
                                <p><strong>All refund requests will be fulfilled as per the following arrangement:</strong></p>
                                <p>If request for refund is made before the Initial draft delivery, you would be eligible for
                                    Full Refund (less 10% service &amp; processing fee).</p>
                                <p>If request for refund is made between 48- 120 hours of the initial draft delivery, you would
                                    be eligible for a 33% refund (less 10% service &amp; processing fee).</p>
                                <p>No refund request will be entertained after 120 hours of your initial draft delivery, however
                                    since we believe in 100% customer satisfaction you`re encouraged to contact us in case of
                                    any concern.</p>
                                <p>All refund requests should be communicated to the support department. Design Canvas Pro,
                                    based on the violation of your user agreement reserves the right to approve/disapprove your
                                    request on an individual case to case basis.</p>
                                <h3>Scope</h3>
                                <p>Our Privacy Policy covers any visitor to our Site(s). This policy applies to both online and
                                    offline collection, storage, processing, and transfer of Personal Information.</p>
                                <h3>Definitions</h3>
                                <ul>
                                    <li className="first">
                                        <p>As used in this Privacy Policy, the following terms have the following meanings:
                                            <br /> "Business Associates" means third party service providers which the
                                            Organization may contract with to provide services on their behalf including those
                                            related to the Site(s) or for transactions occurring on the Site(s) including, but
                                            not limited to Social Networking, hosting, web analytics, lead generation, academic,
                                            business or financial services.
                                            <br />
                                            <br /> "Financial Information" means specific Personal Information of a financial
                                            nature such as your credit card number.
                                            <br /> "Information" means any Information collected and includes: "Personal
                                            Information", "Financial Information", "Web Analytical Information" or any other
                                            Information collected from you through our Site(s).
                                        </p>
                                    </li>
                                    <li>
                                        <p>"Marketing Information" means your Web Analytical Information and your Personal
                                            Information such as your name, address, e-mail address and telephone number that the
                                            Organization collects uses and discloses for the marketing and promotional purposes
                                            as disclosed in this Privacy Policy.
                                            <br /> "Personal Information" means Information that may be used, alone or in
                                            combination, to identify a specific individual and includes Financial Information.
                                            <br />
                                            <br /> "Site" or "Sites", means the Organization's website located at&nbsp;
                                            <a href="../index-2">
                                                https://designcanvaspro.com/ </a> &nbsp; as any other Organization-controlled
                                            or authorized Sites (including, without limitation, any Business Associate sites),
                                            regardless of domain name or IP address.
                                        </p>
                                    </li>
                                    <li className="last">
                                        <p>"Social Network", means various Internet communication technologies provided on Sites
                                            that facilitate conversation and interaction between people online and includes, but
                                            is not limited to, blogs, discussion forums, wikis, chat sessions, news groups, etc.
                                            <br />
                                            <br /> "Social Network Content" (or "SNC") means user-generated content you consent to
                                            share via a Social Network and includes, but is not limited to, Personal
                                            Information, including digital sounds and pictures that you upload to a Social
                                            Network. It also includes your Personal Information that may be displayed on other
                                            users' Social Network pages.
                                            <br /> "Organization", "we", "us", and "our" refer to the
                                            designcanvaspro.com&nbsp;
                                            <br />
                                            <br /> "Unrelated Entities" means third parties that are not Educational Partners and
                                            who are not providing services to
                                            designcanvaspro.com as Business Associates.
                                            <br />
                                            <br /> "Web Analytical Information" means internet generated Information we collect
                                            when you visit our Sites. This Information may be linked with marketing and Personal
                                            Information. When linked with other Information identifying you personally, either
                                            alone or in combination with other Information, then such Information also will be
                                            considered Personal Information.
                                        </p>
                                    </li>
                                </ul>
                                <h3>Information We Collect</h3>
                                <p>We collect various types of Information from you through our Sites, when we talk to you on
                                    the phone. Some of this Information is collected automatically through various web and
                                    internet technologies including Social Networking tools used by the Organization. Other
                                    Information is collected when you provide it in response to an advertisement, or a request
                                    for information; or if you register or order educational or other products and services, set
                                    up a Social Network or other Site profile, or if you choose to use one of our learning
                                    assessments or other interactive tools.
                                    <br /> Our collection of Web Analytical Information lets us see how users are finding, and
                                    navigating our Sites, user preferences regarding Site or Social Networking Content, and it
                                    tells us which pages users visited most often so we can make our Sites more useful and
                                    relevant. This Information may be linked with personal Information to accomplish the
                                    purposes described in this Privacy Policy.
                                </p>
                                <h3>Information Collected Automatically</h3>
                                <p>Each time you visit one of our Sites, Web Analytical Information is automatically gathered.
                                    In general, this Information does not identify you personally. Examples of Web Analytical
                                    Information include, but are not limited to:</p>
                                <ul>
                                    <li className="first">IP address</li>
                                    <li>Collection Date</li>
                                    <li>Publisher Name</li>
                                    <li>Connection Speed</li>
                                    <li>Day of Week Time of Day (hour)</li>
                                    <li>Language settings</li>
                                    <li>Country, State, DMA, City (relating to IP address, if available)</li>
                                    <li className="last">Domain (.com, .net, .mil, .org, .edu, etc.)</li>
                                </ul>
                                <h3>How We Use This Information</h3>
                                <p>Marketing Information We use marketing Information to help us better plan our Sites and
                                    services to meet your needs; to measure and improve our Site(s)' services and features, to
                                    communicate with you by e-mail, postal mail, telephone, cellular/mobile phone, PDA devices,
                                    and/or on applications for mobile phones such as iPhone or Blackberry about products or
                                    services that may be of interest to you, to provide you with customer support, to prevent
                                    potentially illegal activities (including illegal downloading of copyrighted materials in
                                    accordance with our Copyright Infringement policy), and to enforce our Terms of Use. We also
                                    use a variety of technological systems to detect and address anomalous activity and to
                                    screen content to prevent certain abuses such as spam. These efforts may on occasion result
                                    in permanent suspension or termination of Site functionality for some users.</p>
                                <h3>Personal Information</h3>
                                <p>The Organization recognizes that by choosing to provide us with your Personal Information,
                                    you are demonstrating your trust in us and we take that trust seriously. We will not sell,
                                    rent or lease your Personal Information to others. We may collect, use and disclose Personal
                                    Information for the following purposes:</p>
                                <ul>
                                    <li className="first">to provide requested products and services</li>
                                    <li>to respond to your inquiries</li>
                                    <li>to administer promotions to which you have indicated an interest</li>
                                    <li>for our internal marketing purposes, which includes, but is not limited to, sending you
                                        material about products, services, updates, etc. that we think may be of interest to you
                                    </li>
                                    <li>for fostering communication and collaboration among members of the Organization
                                        community through Social Networks</li>
                                    <li>for sharing with our Partners who may contact you with respect to their services;</li>
                                    <li>for sharing with our Partners or Business Associates who are performing services on our
                                        behalf</li>
                                    <li>to analyze how Sites and services are being accessed and used;</li>
                                    <li>to improve Site and service performance and delivery</li>
                                    <li>to analyze academic and learning outcomes and preferences</li>
                                    <li>to analyze risk and business results</li>
                                    <li>to obtain payment for services that we provide to you</li>
                                    <li className="last">to maintain business records for reasonable periods</li>
                                </ul>
                                <p>In connection with the uses set forth above, we may use an automatic telephone dialing
                                    system, artificial or prerecorded voice messages, or text messages to provide you with
                                    various communications. Further, when you provide us with a cellular, wireless, or mobile
                                    telephone number as your contact number, you agree that you are providing us with your
                                    express consent to receive calls using an automated dialing system, prerecorded voice
                                    messages or text messages related to your enrollment. If you do not wish to receive these
                                    updates on your cellular or mobile phone, please provide us with a LAN-based telephone
                                    number.
                                    <br />
                                    <strong>Financial Information</strong>
                                    <br /> Your Financial Information will be used to provide requested products and services, to
                                    analyze operational and business results, to analyze risk, and for concluding your
                                    registration or other transaction you have initiated with us. It will not be sold, rented,
                                    or otherwise transferred to an Educational Partner, Business Associate or Unrelated Entity
                                    outside of this purpose, except in the case of a reorganization, merger or acquisition of
                                    our shares or assets.
                                    <br /> We use Secure Socket Layer (SSL) 128 bit encryption to protect your credit card details
                                    submitted to us over the Internet. No one can view your credit card information, not even
                                    our staff members.
                                </p>
                                <h3>Opt-Out</h3>
                                <p>You may opt-out of our collection, use and disclosure of your Personal Information at any
                                    time, subject to contractual, academic, legal or technical restrictions and reasonable
                                    notice. Note that if you opt-out of certain uses of your Personal Information, we may no
                                    longer be able to provide certain products or services. You may not be permitted to opt-out
                                    to certain necessary collection, uses and disclosures, for example, but not limited to,
                                    educational announcements, maintaining reasonable academic, business and transaction records
                                    and disclosures to government entities as required for us to comply with applicable laws</p>
                                <p>You can choose to no longer receive marketing communications from us and you may express your
                                    choice where indicated on the applicable email or other communication. Please note, however,
                                    that such a request will only remove you from our list and the list of any Business
                                    Associate performing services on our behalf. We are not responsible for the use any other
                                    party may make of the Information once it has been transferred in accordance with this
                                    Policy and you will need to contact such entity to have your Information removed from their
                                    database.
                                    <br />
                                    <br /> You may change various user settings related to sharing your Personal Information
                                    contained in any user profile you set up as part of Social Network activities furnished by
                                    us, subject to contractual, academic, legal or technical restrictions and reasonable notice.
                                    Note that your withdrawal from Social Network activities might prevent us from providing you
                                    with certain products or services.
                                </p>
                                <h3>Our Security Measures</h3>
                                <p>We will take commercially reasonable measures to secure and store your Information to protect
                                    against the loss, misuse, and alteration of the Information under our control. We utilize
                                    industry standard security measures when accepting your credit card Information during the
                                    online ordering process, as well as whenever we ask you to login to any of our Site(s).
                                    <br /> Unfortunately, no data transmission over the internet can be guaranteed to be
                                    completely secure. As a result, although we will utilize such measures, we do not guarantee
                                    you against the loss, misuse, or alteration of Information under our control, and you
                                    provide Information to us at your own risk. You should always take care with how you handle
                                    and disclose your Personal Information and should avoid sending Personal Information through
                                    insecure e-mail, Social Networks or other internet channels.
                                </p>
                                <h3>Monitoring of Communications</h3>
                                <p>We may monitor and retain all incoming and outgoing communications for training of our
                                    representatives and for quality assurance purposes. To the extent such communications are
                                    selected, they are only retained as long as necessary to adequately discharge these
                                    purposes, unless a specific communication needs to be retained for legal reasons. If you
                                    prefer that your communications not be retained for any reason, please advise your
                                    representative.</p>
                                <h3>Internet Technologies Used</h3>
                                <p>Cookies, web beacons and other relevant internet technologies are used on our Sites to allow
                                    us to accomplish the uses set out above as well as to deliver Sites messaging and to keep
                                    track of your Information. These small pieces of program code reside in your computer and
                                    browser and can be removed. If you desire to remove such technologies you may do so, but
                                    this may render our Sites unusable to you. You can opt out of cookies or the other internet
                                    technologies used by disabling these features in your browser program. Consult your
                                    browser's "Help" menu for assistance in changing cookie settings or removing cookie files.
                                </p>
                                <h3>Children's Privacy</h3>
                                <p>We are very sensitive to the issue of children's privacy. Our Sites are neither developed
                                    for, nor directed at children under 13. If you believe your child has provided us with
                                    Personal Information, or registered at one of our Sites and would like to have this
                                    Information removed</p>
                                <h3>Site Hosting and Links from Our Sites to Other Websites</h3>
                                <p>Some of our Sites may be hosted by our Business Associates or contain links to external
                                    websites hosted by Partners and Unrelated Entities. When you access these Sites or external
                                    websites, the providers of the websites may have access to your Personal Information and may
                                    apply their own policies on how your Personal Information is used. Please make sure to read
                                    the policies of any Sites you visit on the internet carefully. Please be aware that we are
                                    not responsible for the privacy practices or the content of any sites or external websites
                                    that we do not control directly and the collection, use and disclosure of Information about
                                    you by Partners, Business Associates and Unrelated Entities will be subject to the policies
                                    applicable on those other Sites or external websites.</p>
                                <h3>Privacy Policy Changes</h3>
                                <p>
                                    designcanvaspro.com may update this Privacy Policy or revise it from time to time. If you
                                    are concerned about how your Personal Information is used or disclosed you should contact us
                                    as described below or check back at this Site periodically to obtain a current copy of this
                                    Privacy Policy. We urge you to review this Privacy Policy frequently to obtain the current
                                    version. Your continued provision of Personal Information or use of our services following
                                    any changes to this Privacy Policy constitutes your acceptance of such changes. If we intend
                                    to use Information that personally identifies you in a manner materially different from what
                                    we stated at the time it was collected, we will attempt to notify you at least 30 days in
                                    advance. You will be given a choice as to whether or not previously provided Information may
                                    be used in a new way.
                                </p>
                                <h3>How to Access Your Information and Contact Us</h3>
                                <p>If you want access to or wish to update any of your Personal Information or have any
                                    questions about our privacy practices, please contact at&nbsp;
                                    <br />
                                    <br /> Call Toll Free:&nbsp;<strong>+1-(234) 123-4567</strong>
                                    <br />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>

    )
}