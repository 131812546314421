import Header from
    '../Components/Header';
import Footer from
    '../Components/Footer';



export default function Terms() {

    return (
        <>
            <Header />

            <section className="main-privacy">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12 privacy">
                            <div className="term-text">
                                <h3>Terms of use</h3>
                                <p>Please read these Conditions of Use ("terms") carefully before using this website. Accessing,
                                    browsing or using this website is a privilege subject to the following terms and indicates
                                    your agreement and acceptance of these terms. By using this website you acknowledge that you
                                    have read, understood and agree to the terms. </p>
                                <h3> Site Contents Usage</h3>
                                <p>The choice of visiting our homepage is solely the customer's decision. Any dispute over
                                    privacy is subject to this notice and our Privacy Policy. This includes limitations on
                                    damages, arbitration of disputes, and the applicable law of state. If the customers have any
                                    issues regarding the Privacy Policy, they should contact us at their best convenience. Our
                                    customer services shall be most happy to serve them.</p>
                                <p>As our Policies are liable to change at any time, it is recommended that the customers go
                                    through the policy each time they enter the site to purchase.</p>
                                <p>The company gathers navigational information about where visitors go on our website and
                                    information about the technical efficiencies of our website and service (i.e. time to
                                    connect and time to download pages). This information allows us to see which areas of our
                                    Company's Website are most visited and helps us understand our customers better than before.
                                    This also helps us improve the quality of those sites which are lacking in some way or the
                                    other. Unless otherwise noted, all materials, including images, illustrations, designs,
                                    icons, photographs, written and other materials that are part of this site (collectively,
                                    the "Contents") are copyrights, trademarks, trade dress and/or other intellectual property
                                    owned, controlled or licensed by the Company.</p>
                                <p>This site and all its contents are intended solely for personal and non-commercial use. The
                                    customers may download or copy the Contents and other downloadable materials displayed on
                                    the Site for their personal use only. No right, title or interest in any downloaded
                                    materials or software is transferred to the customer as a result of any such downloading or
                                    copying. The customer may not reproduce (except as noted above), publish, transmit,
                                    distribute, display, modify, create derivatives, sell or participate in any sale or
                                    exploitation of the site, its content, or any related software.</p>
                                <h3>Electronic Communications</h3>
                                <p>The moment the customer visits our site or sends e-mails to our contacts, the customer is
                                    communicating with our company electronically. It's involuntary for the customers to receive
                                    our communications electronically. Our Company will communicate with the customer by e-mail
                                    or by posting notices on this site. Thus the customer agrees that all agreements, notices,
                                    disclosures and relevant communication satisfy and fulfill all legal requirements and are
                                    equivalent to any legal statement in writing.</p>
                                <p>YOU agree that
                                    designcanvaspro.com is not liable for any correspondence from email address (es) other
                                    than the ones followed by our own domain or/and any Toll-free number that is not mentioned
                                    on our website.
                                    designcanvaspro.com should not be held responsible for any damage(s) caused by such
                                    correspondence. We only take responsibility of any communication through email address (es)
                                    under our own domain name or/and via Toll-free number i.e. already mentioned on
                                    designcanvaspro.com's Website.</p>
                                <h3>Copyright/Trademarks</h3>
                                <p>All content that is included on this site, such as text, graphics, logos, button icons,
                                    images, digital downloads, data compilations, and software, is the property of or its
                                    content suppliers and protected by and international copyright laws. The compilation of all
                                    content at this site is the exclusive property of this company and protected by and
                                    international copyright laws. All software used at this site is the property of this company
                                    or its software suppliers and protected by and international copyright laws. The trademark
                                    names used within our sites are the property of their respective company or its subsidiaries
                                    and cannot be used in connection with any product or service that is not part of that
                                    company. </p>
                                <h3>Site Policies, Modification and Severability </h3>
                                <p>We suggest all customers to our site review all other policies posted here. These policies
                                    also govern visits to our site. The Company reserves the right to make changes to our site,
                                    to our policies, and to these Conditions of Use at any time. If any of these conditions
                                    shall be deemed invalid, void, or for any reason unenforceable, that condition shall be
                                    deemed severable and shall not affect the validity and enforceability of any remaining
                                    condition. </p>
                                <h3>Revision Policy</h3>
                                <p>Revisions are contingent on the package selected. Clients can approach us for unlimited free
                                    amendments and we will revise their design with no extra charges, given that the design and
                                    concept remain intact. Revision turnaround time will be 48 hours.</p>
                                <h3>Refund Policy</h3>
                                <p><strong>Design Canvas Pro refund policy is not applicable when:</strong></p>
                                <ul>
                                    <li>You choose a discounted coupon</li>
                                    <li>You choose a promo/special service package</li>
                                    <li>You have approved the initial design concept</li>
                                    <li>You have requested revisions</li>
                                    <li>You have canceled the order due to non-related reasons in regard to the Web Design
                                        Creators and its policies</li>
                                    <li>You have not contacted Design Canvas Pro for more than 2 weeks since the project
                                        initiated</li>
                                    <li>You have violated Design Canvas Pro terms &amp; conditions and privacy policy</li>
                                    <li>You have approached another service provider or freelancer for the same project assigned
                                        to Design Canvas Pro</li>
                                    <li>You have provided an incomplete project brief</li>
                                    <li>You have demanded a change in the any concept</li>
                                    <li>You have exceeded from the maximum time allowed to ‘request for refund’ and make claims
                                    </li>
                                    <li>You have other reasons such as change of mind, dispute with your partners, or other
                                        similar reasons</li>
                                    <li>You have approved the deliverables</li>
                                    <li>You have accepted multiple revisions for any service</li>
                                    <li>You have ordered a bundle service and dissatisfied with any specific service, refund can
                                        only be claimed against that specific service and ‘not’ the complete set of bundle
                                        services</li>
                                    <li>You have failed to provide the initial design brief within 10 days of order placement
                                    </li>
                                </ul>
                                <h3>How to Claim Your Refund</h3>
                                <p>To ensure that your refund request is processed effectively and is approved, please make sure
                                    that you meet the following requirements.</p>
                                <p>1. Specify your concern and claim your refund through any of the following three modes:-</p>
                                <p>i. Toll-free # (Number to be Entered)</p>
                                <p>ii. Live Chat.</p>
                                <p>iii. Email. (info@designcanvaspro.com)</p>
                                <p>We will try to resolve your query and concern in light of our revision policy immediately or
                                    else will email you a refund request approval from our refund department. After the refund,
                                    the rights to your design would be transferred to Design Canvas Pro and you would not
                                    legally be allowed to display any version of the design sent by the company.</p>
                                <p>1. Since the rights to design would now be transferred to Design Canvas Pro, you concur
                                    that you will have no right (immediate or circuitous) to use any reaction or other
                                    substance, work item or media, nor will you have any ownership interest for or to the same.
                                </p>
                                <p>2. Working in a joint effort with the Government Copyright Agencies Design Canvas Pro would
                                    share Copyright Acquisition data for the refunded outlines that would confine the
                                    re-utilization of the designs as original designs in the future.</p>
                                <p>If you have any questions or concerns about our Refund Policy, please get in touch with us by
                                    clicking here (info@designcanvaspro.com).</p>
                                <h3>All refund requests will be fulfilled as per the following arrangement:</h3>
                                <p>If request for refund is made before the Initial concept delivery, you would be eligible for
                                    Full Refund (less 10% service &amp; processing fee).<br />
                                    If request for refund is made before the Initial concept delivery, you would be eligible for
                                    Full Refund (less 10% service &amp; processing fee).<br />
                                    If request for refund is made between 48- 120 hours of the initial design delivery, you
                                    would be eligible 33% refund (less 10% service &amp; processing fee).<br />
                                    No refund request will be entertained after 120 hours of your initial design delivery,
                                    however since we believe in 100% customer satisfaction you`re encouraged to contact us in
                                    case of any concern.<br />
                                    All refund requests should be communicated to the support department. Design Canvas Pro
                                    based on the violation of your user agreement reserves the right to approve/disapprove your
                                    request on an individual case to case basis.<br />
                                    If request for refund is made before the Initial concept delivery, you would be eligible for
                                    Full Refund (less 10% service &amp; processing fee).<br />
                                    If request for refund is made within 48 hours, you would be eligible for 66% refund (less
                                    10% service &amp; processing fee).<br />
                                    If request for refund is made between 48- 120 hours of the initial design delivery, you
                                    would be eligible 33% refund (less 10% service &amp; processing fee).<br />
                                    No refund request will be entertained after 120 hours of your initial design delivery,
                                    however since we believe in 100% customer satisfaction you`re encouraged to contact us in
                                    case of any concern.<br />
                                    All refund requests should be communicated to the support department.<br />
                                    Design Canvas Pro based on the violation of your user agreement reserves the right to
                                    approve/disapprove your request on an individual case to case basis.</p>
                                <h3>Amendment Policy</h3>
                                <p>
                                    We provide an unlimited amendments guarantee. Through this guarantee customers can ask us
                                    for unlimited amendments and we will entertain the amendment request.
                                </p>
                                <p>In order to receive free amendment, customers are required to provide request for a revision
                                    within 20 days of the order delivery date. However in case of reasonably large order, the
                                    timeline exceeds up to 45 days. If the amendment request falls within these guidelines we
                                    will revise the order to meet the customer's initial requirements free of charge. </p>
                                <p>Once the above-mentioned timeline has passed it shall be assumed that the customer is
                                    satisfied.</p>
                                <p>All amendment requests pertaining to customers order will be completed as per the urgency of
                                    the order with 24 hours as standard minimum turnaround time.</p>
                                <p>Amendment turnaround time as per urgency is as follows: </p>
                                <p> For orders up to 24 hours of urgency; Amendment turnaround time would be 24 hours. </p>
                                <p> For orders between 24-48 hours of urgency; Amendment turnaround time would be 48 hours. </p>
                                <p> For orders of 48 hours and above; Amendment turnaround time would be 72 hours. </p>
                                <h3>Our Programs</h3>
                                <p>The amount credited to your account through the Referral Discount offer is non-refundable and
                                    non-cashable. For programs and offers where additional amount is credited to the user
                                    account, e.g. Affiliate Program, the extra amount credited is non-refundable and
                                    non-cashable. </p>
                                <h3>Quality Assurance Policy</h3>
                                <p>
                                    In order to provide the desired results, our consultants do not deviate from the
                                    specifications provided by the customer in the order form. All work is proofread prior to
                                    final delivery. It is to be noted that we guarantee the standard &amp; quality of the order.
                                </p>
                                <p>Our quality control department double-checks each content for plagiarism before sending it to
                                    the customer. First every content undergoes our authenticity test through various advanced
                                    plagiarism detection software's. Finally it goes to our specially employed board of editors
                                    that eliminates any possibility of plagiarism. All work submitted upon project completion
                                    becomes your property, if it is paid for, and your responsibility.</p>
                                <h3>Your Account </h3>
                                <p>The use of individual customer accounts is solely the responsibility of the user in terms of
                                    maintaining the Confidentiality of their own account and password and for restricting access
                                    to their computer. As such, the customer agrees to accept responsibility for all activities
                                    that occur under "your account" or "password." </p>
                                <h3> Best Price </h3>
                                <p>We offer services at the best price along with exclusive free features. Customers are charged
                                    at special discounted price. A quick read of our exclusive service features will help
                                    customers understand how we stay successful in the business. If customers find any site
                                    offering these EXCLUSIVE features we will give them 10% additional discount. </p>
                                <p>Furthermore, once a customer takes advantage of any promotional deals offering discounts,
                                    other discounts, such as, the Bulk Order Discount and the Membership Discount will not be
                                    applicable on their order.</p>
                                <h3>Disclaimers </h3>
                                <p>1) Customer expressly understand and agree that their use of the services is at their sole
                                    risk and that the services are provided "as is" and "as available." In particular, the
                                    company, its subsidiaries and affiliates, and its licensors do not represent or warrant to
                                    customers that:</p>
                                <p>(a) Customer's use of the services will meet their requirements,
                                    <br /> (b) Customer's use of the services will be uninterrupted, timely, secure or free from
                                    error,
                                    <br /> (c) Any information obtained by customers as a result of their use of the services will
                                    be accurate or reliable
                                </p>
                                <p>3) Any material downloaded or otherwise obtained through the use of the services is done at
                                    the customer's own discretion and risk and that the customer will be solely responsible for
                                    any damage to their computer system or other device or loss of data that results from the
                                    download of any such material. </p>
                                <p>4) No advice or information, whether oral or written, obtained by the customers from the
                                    company or through or from the services shall create any warranty not expressly stated in
                                    the terms.</p>
                                <h3>Limitation of liability</h3>
                                <p>Customers agree that in no event shall the company be liable for any direct, indirect,
                                    incidental, consequential, special and exemplary damages, or any damages whatsoever, arising
                                    from the use, misuse, (or inability to use) or performance of this web site or services
                                    provided or offered by any means, even if the company has been advised of the possibility of
                                    such damages. Customers further agree that in no event, shall any liability to the company
                                    as the result of the agreement, or other circumstances involving them and the company exceed
                                    the fee which has been paid up to the time during which any claim deriving liability may
                                    arise.</p>
                                <p>Means of Reaching the Company
                                    <br /> The pathway to all communications, starting from the purchase to the delivery process
                                    to the completion of order, is done using any of the following methods:
                                </p>
                                <p> Toll Free number
                                    +1-(234) 123-4567</p>
                                <p> E-mail :
                                    <a href="mailto:info@designcanvaspro.com">
                                        info@designcanvaspro.com </a>
                                </p>
                                <p> Address :
                                    402 West Broadway, Dummy,
                                    Suite 400, San Diego, 12345 </p>
                                <p>Note: Use of stolen credit cards is strictly prohibited and is considered a serious crime. We
                                    work in close collaboration to fight cyber crime and make sure that all fraudulent orders
                                    are reported to the Federal and State Agencies.</p>
                                <h3>Reactivation Policy </h3>
                                <p>If the client gets unresponsive for a month or more then the project will be considered
                                    closed. A reactivation fee will be charged for the said project.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>

    )
}