
export default function Header() {

  function openNav() {
     document.querySelector(".detailHeader").classList.toggle('active')
     document.querySelector(".nav-toggle").classList.toggle('active')
     document.querySelector("body").classList.toggle('headerOpen')
  }

  return (

<header>
  <div className="container-fluid">
    <div className="row">
      <div className="col-lg-12 col-md-12 col-sm-12">
        <nav>
          <a href="home" className="brand-logo">
            <img className="lazy" src="images/logo.png" alt="logo" />
          </a>
          <div className="doubleMenu">
            <ul className="navbar">
              <li><a href="home">Home</a></li>
              <li className="nav-item dropdown open show">
                <a className="nav-link dropdown-toggle" href="javascript:;" data-toggle="dropdown" aria-expanded="true">
                  <span className data-hover="Services">Services</span>
                </a>
                <ul className="dropdown-menu aos-init aos-animate" data-aos="fade-down">
                  <li className><a href="logo-design">Logo Design</a></li>
                  <li className><a href="seo">SEO</a></li>
                  <li className><a href="content-writting">Content Writting</a></li>
                  <li className><a href="2d-animation">2D Animation</a></li>
                  <li className><a href="website-design">Website Design</a></li>
                  <li className><a href="ecommerce">Ecommerce</a></li>
                  <li className><a href="smm">Social Media Marketing</a></li>
                </ul>
              </li>
              <li><a href="packages">Packages</a></li>
              <li><a href="portfolio">Portfolio</a></li>
              <li><a href="about-us">About Us</a></li>
              <li><a href="contact">Contact Us</a></li>
            </ul>
          </div>
          <div className="menu-right">
            <a className="header-number" href="tel:+1-(234) 123-4567">
              <i className="sprite sprite-us-flag" />
              <span>+1-(234) 123-4567</span>
            </a>
            <a href="javascript:;" className="btn btn-default hvr-grow-shadow" data-toggle="modal" data-target=".bd-example-modal-lg">
              GET <span>50% DISCOUNT</span>
            </a>
            <div className="navicon">
              <a className="nav-toggle" href="javascript:;" onClick={openNav}><span /></a>
            </div>
          </div>
        </nav>
      </div>
    </div>
  </div>
  <div className="detailHeader">
    <div className="container">
      <div className="row">
        <div className="col-lg-8 col-md-6 col-sm-12">
          <ul className="nav nav-pills innerMenu">
            <li className>
              <a href="home">Home</a>
            </li>
            <li className>
              <a href="static-website">Services</a>
            </li>
            <li className>
              <a href="packages">Packages</a>
            </li>
            <li className>
              <a href="portfolio">Portfolio</a>
            </li>
            <li className>
              <a href="about-us">About Us</a>
            </li>
            <li className>
              <a href="review">Reviews</a>
            </li>
            <li className>
              <a href="contact">Contact Us</a>
            </li>
          </ul>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12">
          <div className="tab-content">
            <div className="main-scroll-overlay tab-pane active" id="service">
              <ul id="parent" className="page-overlay-inner menuBlock">
                <li className="child"><a href="website-design"> Website Design</a></li>
                <li className="child"><a href="ecommerce">Ecommerce</a></li>
                <li className="child"><a href="logo-design">Logo Design</a></li>
                <li className="child"><a href="seo">SEO</a></li>
                <li className="child"><a href="smm">SMM</a></li>
                <li className="child"><a href="content-writting">Content Writting</a></li>
                <li className="child"><a href="2d-animation">2D Animation</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
   
  )
}