import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';


export default function BannerSlider() {
  return (

  <section className="main-feature">
    <div className="container">
      <div className="feature-wrap">
        <div className="row no-gutters">
          <div className="col-lg-4 col-md-6">
            <div className="feature-text">
              <h1>We Excel In Designing</h1>
              <h3>Marketing And Strategic <br />Development of Websites<br /> <span>For All Businesses!</span></h3>
              <a className="home-btn left-right-mov" href="about-us">About Us</a>
            </div>
          </div>
          <div className="col-lg-8 col-md-6">
            
            <OwlCarousel className="feature-carousel owl-carousel owl-theme" loop margin={10} nav dots={false}>
              <div className="item ">
                <div className="feature-box">
                  <i className="sprite sprite-feature-icon-1" />
                  <h3>Website <br /> Design</h3>
                  <a href="website-design">VIEW DETAIL</a>
                </div>
              </div>
              <div className="item ">
                <div className="feature-box">
                  <i className="sprite sprite-feature-icon-2" />
                  <h3>Ecommerce <br />Website</h3>
                  <a href="ecommerce">VIEW DETAIL</a>
                </div>
              </div>
              <div className="item ">
                <div className="feature-box">
                  <i className="sprite sprite-feature-icon-3" />
                  <h3>SEO</h3>
                  <a href="seo">VIEW DETAIL</a>
                </div>
              </div>
              <div className="item ">
                <div className="feature-box">
                  <i className="sprite sprite-feature-icon-4" />
                  <h3>2D <br />Animation</h3>
                  <a href="2d-animation">VIEW DETAIL</a>
                </div>
              </div>
            </OwlCarousel>

          </div>
        </div>
      </div>
      <div className="trust-logo-wrap">
        <div className="row justify-content-between">
          <div className="col-lg-4 col-md-6">
            <div className="trust-logo-text">
              <h3>Elevating businesses through <br />High-quality Digital Branding</h3>
            </div>
          </div>
          <div className="col-lg-7 col-md-6">
            <div className="trust-img">
              <img className="lazy img-fluid" src=" images/trust-logo.webp" alt="*" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  );
}