

export default function RoiDriven() {
     return (

      <section className="best-static-wrap">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <div className="best-static-img" data-aos="fade-right" data-aos-duration={1500}>
                <img src="images/51.webp" alt="" className="img-fluid" />
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="best-static-text" data-aos="fade-left" data-aos-duration={1500}>
                <h3>ROI Driven<br /> Ecommerce Website &amp;Design Company</h3>
                <p>We at Design Canvas Pro have been serving people for several years and have been excelling in our field by providing design-driven solutions and innovative brand ideas. We have effectively helped and supported brands in arriving at their most extreme potential and boosting their latent capacity. We are the best website design agency situated in San Diego, Texas. Our fulfilled customer base is a living confirmation of that reality.</p>
                <h2>Following are few of the advantages of having an ecommerce website:</h2>
                <ul>
                  <li><i className="fas fa-caret-right" />	Global reach for your product</li>
                  <li><i className="fas fa-caret-right" />	Maximize your sales and revenue</li>
                  <li><i className="fas fa-caret-right" />	Easy-to-upload products through dashboard</li>
                  <li><i className="fas fa-caret-right" />	End-to-end Transactions</li>
                </ul>
                <div className="best-static-btn">
                  <button className="btn btn-round btn-white btn-tup chatt">Sign Up Now To <strong>Avail 30% Discount</strong></button>
                  <a href="javascript:;" data-toggle="modal" data-target=".bd-example-modal-lg" className="discount-btn">AVAIL DISCOUNT</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      
    );

}