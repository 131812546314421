
export default function Offer() {
    return (

        <section className="main-service">
            <div className="heading" >
                <h2>Design Canvas Pro Services</h2>
                <h3>A Wide Range Of <span>Premium Services</span> That We Offer</h3>
                <p>We are one of the leading Web Design Companies that is eager to serve its client’s needs. Our website design
                    agency is impeccable and second to none.<br /> We boost your business efficiency and results though our intricate
                    processes and fantastic work ethic.</p>
            </div>
            <div className="main-service-wrap">
                <div className="row service-responsive-slider justify-content-center">
                    <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                        <div className="service-box" >
                            <div className="service-icon">
                                <i className="sprite sprite-service-icon-1" />
                            </div>
                            <h3>Website <br /> Design</h3>
                            <a href="website-design" className="service-btn"><i className="fas fa-angle-right" /></a>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                        <div className="service-box" >
                            <div className="service-icon">
                                <i className="sprite sprite-service-icon-2" />
                            </div>
                            <h3>Ecommerce <br />Websites</h3>
                            <a href="ecommerce" className="service-btn"><i className="fas fa-angle-right" /></a>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                        <div className="service-box">
                            <div className="service-icon">
                                <i className="sprite sprite-service-icon-3" />
                            </div>
                            <h3>Logo <br />Design</h3>
                            <a href="logo-design" className="service-btn"><i className="fas fa-angle-right" /></a>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                        <div className="service-box" >
                            <div className="service-icon">
                                <i className="sprite sprite-service-icon-4" />
                            </div>
                            <h3>Content <br />Writting</h3>
                            <a href="content-writting" className="service-btn"><i className="fas fa-angle-right" /></a>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                        <div className="service-box">
                            <div className="service-icon">
                                <i className="sprite sprite-service-icon-5" />
                            </div>
                            <h3>2D <br />Animation</h3>
                            <a href="2d-animation" className="service-btn"><i className="fas fa-angle-right" /></a>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                        <div className="service-box"  >
                            <div className="service-icon">
                                <i className="sprite sprite-service-icon-6" />
                            </div>
                            <h3>Social Media <br />Marketing</h3>
                            <a href="smm" className="service-btn"><i className="fas fa-angle-right" /></a>
                        </div>
                    </div>
                    <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                        <div className="service-box" >
                            <div className="service-icon">
                                <i className="sprite sprite-service-icon-7" />
                            </div>
                            <h3>Search Engine <br />Optimzation</h3>
                            <a href="seo" className="service-btn"><i className="fas fa-angle-right" /></a>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    );
}