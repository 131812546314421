import React, { Component }  from 'react';
import Home from '../src/Pages/Home';
import About from '../src/Pages/About';
import Portfolio from '../src/Pages/Portfolio';
import PackagesMain from '../src/Pages/PackagesMain';
import ContentWritting from '../src/Pages/ContentWritting';
import WebsiteDesign from '../src/Pages/WebsiteDesign';
import LogoDesign from '../src/Pages/LogoDesign';
import Animation from '../src/Pages/Animation';
import Ecommerce from '../src/Pages/Ecommerce';
import Seo from '../src/Pages/Seo';
import Smm from '../src/Pages/Smm';
import Contact from '../src/Pages/Contact';
import Terms from '../src/Pages/Terms';
import Privacy from '../src/Pages/Privacy';




import { BrowserRouter, Routes, Route } from 'react-router-dom';


function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
           <Route path="/" element={<Home/>}/>
           <Route path="/index" element={<Home/>}/>
           <Route path="/home" element={<Home/>}/>
           <Route path="/logo-design" element={<LogoDesign/>}/>
           <Route path="/seo" element={<Seo/>}/>
           <Route path="/content-writting" element={<ContentWritting/>}/>
           <Route path="/2d-animation" element={<Animation/>}/>
           <Route path="/website-design" element={<WebsiteDesign/>}/>
           <Route path="/ecommerce" element={<Ecommerce/>}/>
           <Route path="/smm" element={<Smm/>}/>
           <Route path="/about-us" element={<About/>}/>
           <Route path="/portfolio" element={<Portfolio/>}/>
           <Route path="/Packages" element={<PackagesMain/>}/>
           <Route path="/contact" element={<Contact/>}/>
           <Route path="/terms" element={<Terms/>}/>
           <Route path="/privacy" element={<Privacy/>}/>
        </Routes>
      </BrowserRouter> 
    </>   
  
  );
}

export default App;


