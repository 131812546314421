import Header from
  '../Components/Header';
import Footer from
  '../Components/Footer';
import Offer from
  '../Components/Offer';
import WebDesignProcess from
  '../Components/WebDesignProcess';
  import Testimonial from
  '../Components/Testimonial';





export default function About() {
  return (
    <>
      <Header />

      <section className="main-banner inner-banner about-banner" style={{backgroundImage: 'url(images/3d-animation.webp)', height: '460px'}}>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-sm-12 pr-0">
              <div className="banner-text">
                <h4>Team of bright creative people</h4>
                <h2><span>We Create Designs <br />With Beautified Pixels </span></h2>
                <ul>
                  <li>
                    Let us aid you in generating more leads
                  </li>
                  <li>
                    Increase your Return on investment by huge margins
                  </li>
                  <li>
                    Get noticed by your audience at the first glance
                  </li>
                </ul>
                <img src="images/bark-logo.webp" alt="" className="img-fluid" />
              </div>
            </div>
            <div className="col-lg-6 col-sm-12 banner-img-side">
              <img src="images/about.webp" alt="" className="img-fluid" />
            </div>
          </div>
        </div>
      </section>

      <section className="inner-about-wrap">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <div className="about-text-right about-text-color d-flex flex-column align-items-end" data-aos="fade-right" data-aos-duration={1500}>
                <h3>Always Up -To -Date</h3>
                <p>Thanks to our digitally led strategic and tactical solutions, Design Canvas Pro harmonizes marketing along with sales processes to drive awareness and increase digital traffic towards your business. Digitally blooming strategies and legitimate solutions, that’s what we need to harmonize marketing programs, for a good conversion rate.</p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="about-text-right d-flex flex-column" data-aos="fade-left" data-aos-duration={1500}>
                <h3>We Love What We Do</h3>
                <p>The approach at Design Canvas Pro is simple, tech-savvy, and prompt. At our web agency, we understand that digital impressions matter more than ever. Our passion is to create an engaging online and web presence, aiming to increased lead generation. We market businesses in a result-oriented manner.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="inner-expertise">
        <div className="container">
          <div className="expertise-top-wrap">
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <div className="expertise-img" data-aos="fade-right" data-aos-duration={1500}>
                  <img src="images/expertise-img-1.webp" alt="" className="img-fluid" />
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="expertise-text" data-aos="fade-left" data-aos-duration={1500}>
                  <h3>The <span>Expertise Is A Treasure</span> That Nobody Can Take Away From You!</h3>
                  <p>Besides our certified designers, digital marketing consultants and developers, we continuously learn new technologies, support colleagues and outperform productivity. All in all, our philosophy cherishes strategic relationship with our clientele, industrious office environment, and data-backed monetary growth for our invaluable clients.</p>
                  <div className="counter-box-wrap">
                    <div className="row">
                      <div className="col">
                        <div className="counter-box">
                          <h2>07</h2>
                          <p>Years in <br />Bussiness</p>
                        </div>
                      </div>
                      <div className="col">
                        <div className="counter-box">
                          <h2>1100<sup>+</sup></h2>
                          <p>Projects <br />Completed</p>
                        </div>
                      </div>
                      <div className="col">
                        <div className="counter-box">
                          <h2>230<sup>+</sup></h2>
                          <p>Clients <br />Served</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="expertise-bottom-wrap">
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <div className="expertise-text" data-aos="fade-right" data-aos-duration={1500}>
                  <p>The very experience with Design Canvas Pro gives us matchless confidence in our designing, digital marketing and animation and web application development. Importantly, we have made a culture of open-room environment in our offices to create a sense of community building and polishing ideas of each and other employee working at Design Canvas Pro. We are not just geeks, we are a team of creative, humorous, active and agile people who love to work in this industry.</p>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="expertise-img" data-aos="fade-left" data-aos-duration={1500}>
                  <img src="images/expertise-img-2.webp" alt="" className="img-fluid" />
                </div>
              </div>				
            </div>
          </div>
        </div>
      </section>

    
<Offer />  

<WebDesignProcess />

<Testimonial />

     

      <Footer />
    </>

  )
}