


export default function History() {
     return (

      <section className="main-renowned">
      <div className="container">
        <div className="row justify-content-end">
          <div className="renowned-img">
            <img className="lazy img-fluid" src="images/renowned-img.webp" alt="*" />
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="renowned-text">
              <h3>Our <span>Renowned History</span></h3>
              <p>We at Design Canvas Pro have been serving people for several years and have been excelling in our field
                by providing design-driven solutions and innovative brand ideas. We have effectively helped and supported
                brands in arriving at their most extreme potential and boosting their latent capacity. We are the best
                website design agency and we are situated in Austin, Texas, USA. Our fulfilled customer base is a living
                confirmation of that reality.</p>
              <div className="contact-info">
                <ul>
                  <li>
                    <a href="javascript:;" data-toggle="modal" data-target=".bd-example-modal-lg">
                      <div className="contact-info-btn" />
                      <h4>Get In Touch</h4>
                    </a>
                  </li>
                  <li>
                    <a href="tel:+1-(234) 123-4567">
                      <i className="sprite sprite-phone" />
                      <h4><span>Call Us At</span><br />+1-(234) 123-4567</h4>
                    </a>
                  </li>
                  <li>
                    <a href="javascript:;" className="chatt">
                      <h4><span>Click here to</span><br />Live Chat</h4>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      </section>
      
    );
}