import Header from 
'../Components/Header';
import Footer from 
'../Components/Footer';
import Packages from 
'../Components/Packages';
import Resources from 
'../Components/Resources';
import Cta from 
'../Components/Cta';
import Testimonial from 
'../Components/Testimonial';

// import { useEffect } from 'react';




export default function LogoDesign(){

  // setTimeout(() => {
  //   document.getElementById('logoDesign').click();
  //   document.getElementById('nav-logo-tab').click()
  // }, 1000);
  
//   useEffect(() => {

//     window.$(".tablist ul li a").click((function() {
//     var t = window.$(this).attr("data-target");
//     return window.$(".tablist ul li a").removeClass("active"),
//      window.$(this).addClass("active"),
//       window.$("#" + t).fadeIn("slow").siblings(".tabbox").hide(), !1
// }))

// },[]);

	return(
    <> 
    <Header />
    
      <div>
      <section className="main-banner inner-banner" style={{backgroundImage: 'url(images/3d-animation.webp)', height: '560px'}}>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-sm-12 pr-0">
              <div className="banner-text" data-aos="fade-right" data-aos-duration={1500}>
                <h4>Striking Logo Designs That Shine</h4>
                <h2><span>Crafting Distinct <br />Visual Identities </span></h2>
                <p>Our Logo Design Maestros at Design Canvas Pro specialize in creating memorable logos that
                  encapsulate your brand's essence. We transform your vision into a captivating emblem.</p>
                <img src="images/bark-logo.html" alt="" className="img-fluid" />
              </div>
            </div>
            <div className="col-lg-6 col-sm-12 banner-img d-flex justify-content-center jvi">
              <img src="images/logi.png" alt="" className="img-fluid" style={{maxWidth: '55%'}} />
            </div>
          </div>
        </div>
      </section>
        
        <section className="main-award">
          <div className="container">
            <h3>Let’s Envision and Build together</h3>
            <div className="row justify-content-center">
              <div className="col-lg-2 col-md-2 col-sm-2">
                <div className="award-box">
                  <img src="images/seo-award-1.webp" alt="" className="img-fluid" />
                </div>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-2">
                <div className="award-box">
                  <img src="images/seo-award-2.webp" alt="" className="img-fluid" />
                </div>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-2">
                <div className="award-box">
                  <img src="images/seo-award-3.webp" alt="" className="img-fluid" />
                </div>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-2">
                <div className="award-box">
                  <img src="images/seo-award-4.webp" alt="" className="img-fluid" />
                </div>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-2">
                <div className="award-box">
                  <img src="images/seo-award-5.webp" alt="" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="best-static-wrap">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <div className="best-static-img" data-aos="fade-right" data-aos-duration={1500}>
                  <img src="images/logoo.png" alt="" className="img-fluid" />
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="best-static-text" data-aos="fade-left" data-aos-duration={1500}>
                  <h3>Leading Logo Design Services <br />in San Diego</h3>
                  <p>
                    Certainly! Here's the same content tailored for logo design:
                    "Leading Logo Design Services in San Diego
                    With a legacy of excellence, Design Canvas Pro has proudly served clients for years,
                    delivering design-driven solutions and groundbreaking brand concepts. We've consistently
                    empowered brands to unlock their full potential and amplify their hidden strengths. As the
                    premier Marketing and Design agency headquartered in San Diego, our satisfied clientele is
                    living testament to our expertise.</p>
                  <h2>Explore the advantages of choosing us for your logo design:</h2>
                  <ul>
                    <li><i className="fas fa-caret-right" /> Experienced Design Artisans</li>
                    <li><i className="fas fa-caret-right" /> Guaranteed Results</li>
                    <li><i className="fas fa-caret-right" /> Competitor Analysis</li>
                    <li><i className="fas fa-caret-right" /> Enhanced Visual Impact</li>
                  </ul>
                  <div className="best-static-btn">
                    {/* <button class="btn btn-round btn-white btn-tup chatt">Sign Up Now To <strong>Avail 30%
                                Discount</strong></button> */}
                    <a href="javascript:;" data-toggle="modal" data-target=".bd-example-modal-lg" className="discount-btn">AVAIL DISCOUNT</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

     <Cta/>
     <Packages/>
     <Resources/>
     <Testimonial/>

    <Footer />
    </>

		)
}