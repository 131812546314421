import Header from
  '../Components/Header';
import Footer from
  '../Components/Footer';


export default function Contact() {
  return (
    <>
      <Header />

      <div>
        <section className="main-banner inner-banner" style={{ backgroundImage: 'url(images/contact.webp)', height: '560px' }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-sm-12 pr-0">
                <div className="banner-text" data-aos="fade-down" data-aos-duration={1000}>
                  <h4>The Best Web Design Agency</h4>
                  <h2><span>Contact </span>Us</h2>
                  <p>Design Canvas Pro helps companies to <br />introduce their products &amp; services to businesses all over the world.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="contact-img-wrap" data-aos="fade-down" data-aos-duration={1000}>
          <div className="container">
            <div className="heading">
              <h3>Find the <span>Perfect Solution</span> for Your Business </h3>
              <p>Our team of customer support are always friendly, active, awake and supportive to guide you to the best possible solutions for your business. Just fill the form below or ping us a message or give us a call and the team will be right there with you in no time.</p>
            </div>
            <div className="contact-img">
              <img src="images/contact-img.webp" alt="" className="img-fluid" />
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </>

  )
}