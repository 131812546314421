
// import $ from 'jquery';

export default function Footer() {

  return (

    <div>
    <section className="footer-form-wrap">
        <span className="form-before" />
        <div className="container">
        <div className="row">
            <div className="col-lg-5 col-md-6">
            <div className="footer-text-sec">
                <div className="quote">
                <p>Let's get the Ball Rolling</p>
                </div>
                <p> Ask us anything, we have the friendliest customer service folks</p>
                <a href="tel:+1-(234) 123-4567"> +1-(234) 123-4567</a>
                <a href="mailto:info@designcanvaspro.com"> info@designcanvaspro.com </a>
                <button className="btn btn-round btn-white btn-tup chatt"><strong>Chat now</strong>to avail this
                offer</button>
            </div>
            </div>
            <div className="col-lg-5 col-md-6">
            <div className="footer-form-sec">
                <h3>Let’s get started!</h3>
                <p>Contact us by using the form below or send us an email.</p>
                <div className="footer-form" data-form-type="footer_form">
                <form className="leadForm" method="post" encType="multipart/form-data" action="javascript:void(0)">
                    <input type="hidden" id="formType" name="formType" />
                    <input type="hidden" id="referer" name="referer" />
                    <div className="form-group">
                    <input type="text" className="form-control" name="name" placeholder="Enter Your Name" data-validation="required" />
                    </div>
                    <div className="form-group">
                    <input type="text" className="form-control" name="email" placeholder="Email Address" data-validation="required" />
                    </div>
                    <div className="form-group">
                    <input type="text" className="form-control" maxLength={10} name="phone" placeholder="Phone Number" data-validation="required" />
                    </div>
                    <div className="form-group">
                    <div id="formResult" />
                    <div className="clearfix" />
                    <button className="home-btn left-right-mov" type="submit" id="signupBtn">Send Now</button>
                    </div>
                </form>
                </div>
            </div>
            </div>
        </div>
        </div>
    </section>
    <footer className="main-footer">
        <div className="container">
        <div className="row justify-content-between">
            <div className="col-lg-3 col-md-12">
            <div className="footer-info footer-contact">
                <h3>Contact Infomation</h3>
                <h2>Get in Touch</h2>
                <ul>
                <li>
                    <a href="tel:+1-(234) 123-4567">+1-(234) 123-4567(USA)</a>
                </li>
                <li>
                    <a href="mailto:info@designcanvaspro.com">Email: info@designcanvaspro.com</a>
                </li>
                <li>
                    <a href="javascript:;">Address: 402 West Broadway, Dummy,
                    Suite 400, San Diego, 12345</a>
                </li>
                </ul>
            </div>
            </div>
            <div className="col-lg-2 col-md-12">
            <div className="footer-links footer-contact">
                <h3>Quick Links</h3>
                <ul>
                <li><a href="home"><i className="fas fa-caret-right" />Home</a></li>
                <li><a href="portfolio"><i className="fas fa-caret-right" />Portfolio</a></li>
                <li><a href="review"><i className="fas fa-caret-right" />Reviews</a></li>
                <li><a href="packages"><i className="fas fa-caret-right" />Packages</a></li>
                </ul>
            </div>
            </div>
            <div className="col-lg-2 col-md-12">
            <div className="footer-links footer-contact">
                <h3>Services</h3>
                <ul>
                <li><a href="logo-design"><i className="fas fa-caret-right" />Logo Design</a></li>
                <li><a href="website-design"><i className="fas fa-caret-right" />Website Design</a></li>
                <li><a href="seo"><i className="fas fa-caret-right" />SEO</a></li>
                <li><a href="content-writting"><i className="fas fa-caret-right" />Content Writting</a></li>
                </ul>
            </div>
            </div>
            <div className="col-lg-2 col-md-12">
            <div className="footer-links footer-contact">
                <h3>Services</h3>
                <ul>
                <li><a href="smm"><i className="fas fa-caret-right" />SMM</a></li>
                <li><a href="2d-animation"><i className="fas fa-caret-right" />2D Animation</a></li>
                <li><a href="ecommerce"><i className="fas fa-caret-right" />Ecommerce</a></li>
                </ul>
            </div>
            </div>
            <div className="col-lg-2 col-md-12">
            <div className="footer-links footer-contact">
                <h3>Quick Links</h3>
                <ul>
                <li><a href="about-us"><i className="fas fa-caret-right" />About Us</a></li>
                <li><a href="privacy"><i className="fas fa-caret-right" />Privacy &amp; Security </a></li>
                <li><a href="terms"><i className="fas fa-caret-right" />Terms &amp; Conditions</a></li>
                </ul>
            </div>
            </div>
        </div>
        </div>
    </footer>
    <section className="copy-right">
        <div className="container">
        <div className="row justify-content-between">
            <div className="col-lg-8 col-md-12">
            <div className="copyright-text">
                <p>Copyright © 2023 Design Canvas Pro All Rights Reserved.</p>
                <ul>
                <li><a href="privacy">Privacy Policy </a></li>
                <li><a href="terms">Terms of Use </a></li>
                </ul>
            </div>
            </div>
            <div className="col-lg-2 col-md-12">
            <div className="footer-social">
                <ul>
                <li>
                    <a href="javascript:;"><i className="fab fa-facebook-f" /></a>
                </li>
                <li>
                    <a href="javascript:;"><i className="fab fa-youtube" /></a>
                </li>
                <li>
                    <a href="javascript:;"><i className="fab fa-linkedin-in" /></a>
                </li>
                </ul>
            </div>
            </div>
        </div>
        </div>
    </section>
    <div className="popup_form">
        <div className="container">
        <div className="modal fade bd-example-modal-lg" tabIndex={-1} role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
                <div className="row no-gutters">
                <div className="col-lg-6">
                    <div className="popup_left">
                    <div className="popup_form_box" data-form-type="popup_form">
                        <form className="leadForm" id="regForm" method="post" encType="multipart/form-data" action="javascript:void(0)">
                        <input type="hidden" id="formType" name="formType" />
                        <input type="hidden" id="referer" name="referer" />
                        <div className="form-group">
                            <input type="text" className="form-control" name="name" placeholder="Enter Your Name" data-validation="required" />
                            <i className="fas fa-user" />
                        </div>
                        <div className="form-group">
                            <input type="text" className="form-control" name="email" placeholder="Enter Your Email" data-validation="required" />
                            <i className="fas fa-envelope" />
                        </div>
                        <div className="form-group">
                            <input type="text" className="form-control" name="phone" maxLength={12} placeholder="Phone Number" data-validation="required" />
                            <i className="fas fa-phone" />
                        </div>
                        <div className="popup_btn">
                            <h3>Seal The Limited Time Offer <br />Before It Expires</h3>
                            <div id="formResult" />
                            <div className="clearfix" />
                            <button type="submit" id="signupBtn">Get a Free Quote</button>
                        </div>
                        </form></div>
                    </div>
                </div>
                <div className="col-lg-6 d-none d-lg-block d-xl-block">
                    <div className="popup_right">
                    </div>
                </div>
                </div>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
                </button>
            </div>
            </div>
        </div>
        </div>
    </div>
    </div>

  );
}